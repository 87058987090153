import {
  season,
  seasonState,
  eopType,
  clusterType,
  clusterStateType,
  fieldType,
  yieldType,
  seasonUpload,
  werehouseUpload,
} from "../../actionTypes";

const initialState = {
  seasons: { data: [], meta: {} },
  seasonState: [],
  seasonUpload: [],
  eop: [],
  clusters: [],
  werehouses: [],
  stateClusters: [],
  fields: [],
  yields: [],
  werehouseUpload: [],
};
type seasonActions = {
  type: string;
  payload: { data: []; meta: {} };
};

type seasonAction = {
  type: string;
  payload: [];
};

export const seasonReducers = (state = initialState, action: seasonActions) => {
  switch (action.type) {
    case season.GET_ALL_SEASON: {
      return {
        ...state,
        seasons: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const seasonStateReducers = (
  state = initialState,
  action: seasonAction
) => {
  switch (action.type) {
    case seasonState.GET_ALL_SEASONSTATE: {
      return {
        ...state,
        seasonState: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const seasonUploadReducers = (
  state = initialState,
  action: seasonAction
) => {
  switch (action.type) {
    case seasonUpload.GET_ALL_SEASONUPLOAD: {
      return {
        ...state,
        seasonUpload: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const werehouseUploadReducers = (
  state = initialState,
  action: seasonAction
) => {
  switch (action.type) {
    case werehouseUpload.GET_ALL_WEREHOUSEUPLOAD: {
      return {
        ...state,
        werehouseUpload: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const eopReducer = (state = initialState, action: seasonAction) => {
  switch (action.type) {
    case eopType.GET_ALL_EOP: {
      return {
        ...state,
        eop: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const ClusterReducer = (state = initialState, action: seasonAction) => {
  switch (action.type) {
    case clusterType.GET_ALL_CLUSTER: {
      return {
        ...state,
        clusters: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const WarehouseReducer = (state = initialState, action: seasonAction) => {
  switch (action.type) {
    case clusterType.GET_ALL_CLUSTER: {
      return {
        ...state,
        werehouses: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const ClusterStateReducer = (
  state = initialState,
  action: seasonAction
) => {
  switch (action.type) {
    case clusterStateType.GET_ALL_STATE_CLUSTER: {
      return {
        ...state,
        stateClusters: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const FieldReducer = (state = initialState, action: seasonAction) => {
  switch (action.type) {
    case fieldType.GET_ALL_FIELD: {
      return {
        ...state,
        fields: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const YieldReducer = (state = initialState, action: seasonAction) => {
  switch (action.type) {
    case yieldType.GET_ALL_YIELD: {
      return {
        ...state,
        yields: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
