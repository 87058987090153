import React, {useEffect} from "react";
import { Button, Col, Container, FormGroup, Row, Label } from "reactstrap";
import FormTextInput from "../../../../components/form/text-input";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/rootReducer";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { PageBody } from "../../../../components/Loader";
import { ResponsecropTypeType } from "../../../../models/config.model";
import { configService } from "../../../../Services";
import { configThunk } from "../../../../redux/action/config/config.thunk";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { FaSpinner } from "react-icons/fa";
import Layout from "../../../../components/Layout";

interface FormValues {
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
}
const Create_Supplier = () => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const { cropType } = useSelector((state: RootState) => state.cropType);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const phoneValidate =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const initialValues: FormValues = {
    name: "",
    address: "",
    email: "",
    phoneNumber: "",
  };
  const getroles = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(configThunk.getAllCropType(cropType));
    updateLoader(false, "");
  }, [cropType]);

  useEffect(() => {
    getroles();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Config">
        <Container>
          <Row>
            <Col>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  name: Yup.string().required("Name is required"),
                  email: Yup.string().required(" Email is required"),
                  address: Yup.string().required(
                    "address required"
                  ),
                })}
                onSubmit={async (values, actions) => {
                  console.log({ values, actions });

                  actions.setSubmitting(true);
                  try {
                    const response = await configService.createSupplier(
                      values
                    );
                    alert.success("successfully created");
                    history.push("/config");
                    actions.setSubmitting(false);
                  } catch (error: any) {
                    alert.error(error.response.data.message);
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({ errors, touched, isSubmitting, handleChange, values }) => (
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Name{" "}
                            <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            label="Name"
                            placeholder="enter name"
                            name="name"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.name && errors.name ? (
                            <div className="text-danger">
                              {errors.name}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Email{" "}
                            <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="email"
                            label="Emai;"
                            placeholder="enter email"
                            name="email"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.email && errors.email ? (
                            <div className="text-danger">
                              {errors.email}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col>
                       <FormGroup className="">
                          <Label className="font-weight-bold">
                            Address{" "}
                            <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            label="Address"
                            placeholder="enter address"
                            name="address"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.address && errors.address ? (
                            <div className="text-danger">
                              {errors.address}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Phone Number{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            type="text"
                            label="President's Phone"
                            placeholder="enter phone number"
                            name="phoneNumber"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.phoneNumber && errors.phoneNumber ? (
                            <div className="text-danger">
                              {errors.phoneNumber}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="p-3 text-center">
                      <Link to="/config">
                        <Button
                          color="success"
                          className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        color="success"
                        className="btn-create px-4 py-2 shadow font-weight-bold"
                        disabled={isSubmitting}
                      >
                        Create
                        {isSubmitting ? (
                          <FaSpinner className="fa-spin" />
                        ) : null}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Create_Supplier;
