export const constant = {
  // API: "http://localhost:7009/api",
  API: "https://abp.remsys.com.ng/api",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const validations = [
  // {
  // label:"IsUnique",
  // value:"IsUnique"
  //   },
  // {
  // label:"Length",
  // value:"Length"
  // },
  // {
  // label:"MinLength",
  // value:"MinLength"
  // },
  // {
  // label:"MaxLength",
  // value:"MaxLength"
  // },
  {
    label: "Email",
    value: "IsEmail",
  },
  {
    label: "Phone Number",
    value: "IsPhoneNumber",
  },
  {
    label: "Required",
    value: "IsRequired",
  },
  {
    label: "Number",
    value: "IsNumber",
  },
  {
    label: "Text",
    value: "IsText",
  },
  // {
  //     label:"IsGreaterThan",
  //     value:"IsGreaterThan"
  // },
];

export const controlType = [
  "TextField",
  "Coordinates",
  "File",
  "DropDown",
  "Picture",
  "ColumnNameDropDown",
];

export const roless = {
  ITAd: "IT Admin",
  BusinessAd: "Business Admin",
  National: "National Coordinator",
  State: "State Cordinator",
  Assistant: "Assistant State Coordinator",
  Field: "Field Officer",
  Crop: "Crop Coordinator",
  Report: "Report Viewer",
  WarehouseManager:"Warehouse Manager",
};
