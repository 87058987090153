import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
// import FormTextInput from "../../../components/form/text-input";
import logo from "../../../assets/img/Needle_logo.png";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { userService } from "../../../Services";
// import { AuthContext } from "../../../context/AuthContext";
import { ApiClient } from "../../../Constants";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa";
import { AuthContext } from "../../../context/authContext";

interface FormValues {
  email: string;
  password: string;
}

const Login = () => {
  const { fetchData, setUser } = useContext(AuthContext);
  const alert = useAlert();
  const history = useHistory();
  const initialValues: FormValues = { email: "", password: "" };

  const [passwordShow, setPasswordShow] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const togglePasswordVisiblity = () => {
    setShowIcon(showIcon ? false : true);
    setPasswordShow(passwordShow ? false : true);
  };

  return (
    <Container fluid>
      <Row className="vh-100 overflow-hidden">
        <Col md="4">
          <Card className=" login-card border-0 shadow p-3">
            <CardBody>
              <div className="text-center">
                <img src={logo} width={220} height={50} />
                <CardText className="mt-3">
                  Please enter your details to login
                </CardText>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  email: Yup.string().required("Required"),
                  password: Yup.string().required("Required"),
                })}
                onSubmit={async (values, actions) => {
                  console.log({ values, actions });
                  actions.setSubmitting(true);

                  try {
                    const response = await userService.login(
                      values.email,
                      values.password
                    );
                    console.log(response.data.token);

                    const token = await ApiClient.setToken(response.data.token);
                    if (token === true) {
                      setUser(response.data);
                      fetchData();
                      alert.success(response.message);
                      // history.push("/dashboard");
                    }
                  } catch (error: any) {
                    alert.error(error);
                  }
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <FormGroup className="my-4">
                      <Label className="font-weight-bold">Email Address</Label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="enter email address"
                        className="form-control form-control-lg"
                      />
                      {touched.email && errors.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Label className="font-weight-bold">Password</Label>

                      <InputGroup>
                        <Field
                          type={passwordShow ? "text" : "password"}
                          name="password"
                          placeholder="enter password"
                          className="form-control form-control-lg"
                        />
                        <InputGroupText onClick={togglePasswordVisiblity}>
                          {" "}
                          {showIcon ? <FaEyeSlash /> : <FaEye />}{" "}
                        </InputGroupText>
                      </InputGroup>
                      {touched.password && errors.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                    </FormGroup>
                    <Row className="my-4">
                      <Col>
                        <FormGroup check>
                          <Input type="checkbox" />{" "}
                          <small>
                            {" "}
                            <Label check>Remember me</Label>
                          </small>
                        </FormGroup>
                      </Col>
                      <Col>
                        <Link to="/forget-password">
                          <small>Forgotten Password?</small>
                        </Link>
                      </Col>
                    </Row>
                    <FormGroup className="mt-3">
                      <Button
                        type="submit"
                        block
                        color="success"
                        className="font-bold p-3"
                        disabled={isSubmitting}
                      >
                        LOGIN{" "}
                        {isSubmitting ? (
                          <FaSpinner className="fa-spin" />
                        ) : null}
                      </Button>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
        <Col md="8" className=" login-side"></Col>
      </Row>
    </Container>
  );
};

export default Login;
