import React from "react";
import { HiChevronDoubleLeft, HiDotsVertical } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import AppBreadcrumb from "../../../../components/layout/breadcrumb/Breadcrumb";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { PageBody } from "../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/rootReducer";
import { useAlert } from "react-alert";
import { seasonThunk } from "../../../../redux/action/season/season.thunk";
import { ResponseEop } from "../../../../models/season.model";
import moment from "moment";
import { AnyStyledComponent } from "styled-components";
import Currency from "../../../../components/Currency";
import Layout from "../../../../components/Layout";
import { roless } from "../../../../Constants";
import { AuthContext } from "../../../../context/authContext";

const EOP = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { role } = React.useContext(AuthContext);
  console.log(role);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const { status, seasonData } = location.state;
  console.log("sr", status);
  const { eop } = useSelector((state: RootState) => state.eop);
  const { yields }: any = useSelector((state: RootState) => state.yields);

  const geteop = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(seasonThunk.getAllEop(match.params.id));
    updateLoader(false, "");
  }, [eop]);

  const getyield = React.useCallback(async () => {
    await dispatch(seasonThunk.getAllYield(match.params.id));
    updateLoader(false, "");
  }, [yields]);

  React.useEffect(() => {
    geteop();
    getyield();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <Row>
            <Container className="mb-4">
              <h5>
                Season:{" "}
                {seasonData.seasonType + " " + seasonData.associationName}
              </h5>
              <Row>
                <Col md={9}>
                  <Link
                    to={{
                      pathname: `/manage-season/${match.params.id}`,
                      state: {
                        seasonData: seasonData,
                        status: status,
                      },
                    }}
                  >
                    <Button className="btn-create px-4 py-2 shadow mr-3" inline>
                      {/* <HiChevronDoubleLeft /> */}
                      Back
                    </Button>
                  </Link>
                  {roless.BusinessAd == role || roless.ITAd == role ? (
                    eop.length < 2 && status === true ? (
                      <Link
                        to={{
                          pathname: `/create-eop/${match.params.id}`,
                          state: {
                            seasonData: seasonData,
                            status: status,
                          },
                        }}
                      >
                        <Button className="btn-create px-4 py-2 shadow" inline>
                          Create EOP
                        </Button>
                      </Link>
                    ) : null
                  ) : null}

                  <Link
                    to={{
                      pathname: `/all-eop/${match.params.id}`,
                      state: {
                        seasonData: seasonData,
                        status: status,
                      },
                    }}
                  >
                    <Button
                      className="btn-download px-4 py-2 shadow ml-4"
                      inline
                    >
                      View EOP
                    </Button>
                  </Link>
                  {roless.BusinessAd == role || roless.ITAd == role ? (
                    yields.length < 1 ||
                    (yields[0] !== "null" && status === true) ? (
                      <Link
                        to={{
                          pathname: `/create-yield/${match.params.id}`,
                          state: {
                            seasonData: seasonData,
                            status: status,
                          },
                        }}
                      >
                        <Button
                          className="btn-create px-4 py-2 shadow ml-4"
                          inline
                        >
                          Create Expected Yield
                        </Button>
                      </Link>
                    ) : null
                  ) : null}
                  {/* <span className="ml-5">
                  <Input
                    placeholder="search"
                    type="search"
                    className="form-search"
                  />
                </span> */}
                </Col>
                <Col md={3} className="text-right">
                  {/* {" "}
                <AppBreadcrumb /> */}
                </Col>
              </Row>
            </Container>
          </Row>
          <Row>
            <Table borderless hover responsive striped>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th> EOP</th>
                  <th> CATEGORY</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                {eop.length < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  eop.map((eo: ResponseEop, i: number) => (
                    <tr>
                      <th scope="row">{i + 1}</th>
                      <td> {eo.eopNumber} </td>
                      <td>{eo.eopCategory}</td>
                      <td> {moment(eo.createdAt).format("ll")} </td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                            <HiDotsVertical />
                          </DropdownToggle>
                          <DropdownMenu container="body">
                            <Link
                              to={{
                                pathname: `/view-eop/${match.params.id}/${i}`,
                                state: {
                                  seasonData: seasonData,
                                  status: status,
                                },
                              }}
                            >
                              <DropdownItem>View More</DropdownItem>
                            </Link>
                            <Link
                              to={{
                                pathname: `/edit-eop/${match.params.id}`,
                                state: {
                                  eopdata: eop,
                                  indexeop: i,
                                  seasonData: seasonData,
                                  status: status,
                                },
                              }}
                            >
                              <DropdownItem>Edit</DropdownItem>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Row>
          <Row>
            <div className="mt-4">
              {" "}
              <h4>Expected Yield</h4>
            </div>

            <Table borderless hover responsive striped>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th> No of Bag</th>
                  <th> Cost of bag</th>
                </tr>
              </thead>
              <tbody>
                {yields[0] == null ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  yields[0].eyResponseDetails.map((eo: any, i: number) => (
                    <tr>
                      <th scope="row">{i + 1}</th>
                      <td> {eo.bags} </td>

                      <td>₦ {Currency(eo.costPerBag)} </td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                            <HiDotsVertical />
                          </DropdownToggle>
                          <DropdownMenu container="body">
                            <Link
                              to={{
                                pathname: `/edit-yield/${match.params.id}`,
                                state: {
                                  yielddata: eo,
                                  seasonData: seasonData,
                                  status: status,
                                },
                              }}
                            >
                              <DropdownItem>Edit</DropdownItem>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default EOP;
function useContext(
  AuthContext: React.Context<
    import("../../../../context/authContext").AuthState
  >
): { role: any } {
  throw new Error("Function not implemented.");
}
