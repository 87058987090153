import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import FormTextInput from "../../../components/form/text-input";
import logo from "../../../assets/img/Needle_logo.png";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { Formik, Form, Field } from "formik";
import { userService } from "../../../Services";
import { useAlert } from "react-alert";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa";
import * as Yup from "yup";

interface FormValues {
  password: string;
  confirmPassword: string;
  activationCode: string;
}
const Reset_password = ({ match }: RouteComponentProps<{ id: string }>) => {
  const alert = useAlert();
  const history = useHistory();
  const initialValues: FormValues = {
    password: "",
    confirmPassword: "",
    activationCode: "",
  };

  const [passwordShow, setPasswordShow] = React.useState(false);
  const [showIcon, setShowIcon] = React.useState(false);
  const togglePasswordVisiblity = () => {
    setShowIcon(showIcon ? false : true);
    setPasswordShow(passwordShow ? false : true);
  };

  return (
    <Container fluid>
      <Row className="vh-100">
        <Col md="4">
          <Card className=" login-card border-0 shadow p-3">
            <CardBody>
              <div className="text-center">
                <img src={logo} width={220} height={50} />
                <CardText className="mt-3">
                  Please enter your New Password to reset password
                </CardText>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  password: Yup.string().required("Password is required"),
                  confirmPassword: Yup.string().oneOf(
                    [Yup.ref("password"), null],
                    "Passwords do not match"
                  ),
                })}
                onSubmit={async (values, actions) => {
                  console.log({ values, actions });
                  actions.setSubmitting(true);
                  values.activationCode = match.params.id;
                  try {
                    const response: any = await userService.ResetPassword(
                      values
                    );
                    alert.success("Password reset Successfully");
                    history.push("/login");
                  } catch (error) {
                    alert.error("Error Sending Request");
                  }
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <FormGroup>
                      <Label className="font-weight-bold">Password</Label>

                      <InputGroup>
                        <Field
                          type={passwordShow ? "text" : "password"}
                          name="password"
                          placeholder="enter password"
                          className="form-control form-control-lg"
                        />
                        <InputGroupText onClick={togglePasswordVisiblity}>
                          {" "}
                          {showIcon ? <FaEyeSlash /> : <FaEye />}{" "}
                        </InputGroupText>
                      </InputGroup>
                      {touched.password && errors.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Label className="font-weight-bold">
                        Confirm Password
                      </Label>

                      <InputGroup>
                        <Field
                          type={passwordShow ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="enter password"
                          className="form-control form-control-lg"
                        />
                        <InputGroupText onClick={togglePasswordVisiblity}>
                          {" "}
                          {showIcon ? <FaEyeSlash /> : <FaEye />}{" "}
                        </InputGroupText>
                      </InputGroup>
                      {touched.confirmPassword && errors.confirmPassword ? (
                        <div className="text-danger">
                          {errors.confirmPassword}
                        </div>
                      ) : null}
                    </FormGroup>
                    <Row className="my-5">
                      <Col>
                        <Link to="/" className="text-dark">
                          <span>
                            {" "}
                            <AiFillHome /> Home
                          </span>
                        </Link>
                      </Col>
                    </Row>

                    <FormGroup className="mt-5">
                      <Button
                        type="submit"
                        block
                        color="success"
                        className="font-bold p-4"
                        disabled={isSubmitting}
                      >
                        Reset Password
                        {isSubmitting ? (
                          <FaSpinner className="fa-spin" />
                        ) : null}
                      </Button>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
        <Col md="8" className=" login-side"></Col>
      </Row>
    </Container>
  );
};

export default Reset_password;
