import React from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";
import { useAlert } from "react-alert";
import {
  Link,
  useHistory,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import ErrorMessage from "../../../../../components/form/error-message";
import Layout from "../../../../../components/Layout";
import { PageBody } from "../../../../../components/Loader";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from   "../../../../../redux/store/rootReducer";
import { configThunk } from "../../../../../redux/action/config/config.thunk";
import { controlType, validations } from "../../../../../Constants";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { seasonService } from "../../../../../Services";


const Duplicate_season_form = ({ match }: RouteComponentProps<{ seasonId: string,farmStageId:string }>) => {
  const alert = useAlert();
  const history = useHistory();
  const [forms,setAllforms] =React.useState<any>({})

  const { isLoading, message, updateLoader } = useLoadingMessage();

  const dispatch = useDispatch();

  const { farmingStage } = useSelector(
      (state: RootState) => state.farmingStage
    );

    

  const getform = async () => {
    updateLoader(true, "Fetching Data");
    const response:any = await (seasonService.getSeasonForm(match.params.seasonId,match.params.farmStageId));
    response.data.response.config.map((stg: any, i: any) => {
      for (let i = 0; i < stg.validations.length; i++) {
        stg.validations[i].label = stg.validations[i].validation;
        stg.validations[i].value = stg.validations[i].validation;
      }
    });
    setAllforms(response.data.response)
    updateLoader(false, "");
  };

  const getSeasonDropDown = React.useCallback(async () => {
    await dispatch(configThunk.getAllFarmingStage(farmingStage));
    updateLoader(false, "");

  }, [farmingStage]);

  React.useEffect(() => {
    getform();
    getSeasonDropDown();

  }, []);

  return (
    // <React.Fragment>
        <PageBody isLoading={isLoading} message={message}>

    <Layout active="Seasons">
      <Container>
        <Formik
          initialValues={{
            farmStageName:"",
            seasonId:forms.seasonId && forms.seasonId,
            farmStageId:forms.farmStageId && forms.farmStageId,
            startDate:forms.startDate && forms.startDate,
            endDate:forms.endDate && forms.endDate,
            config: forms.config && forms.config
          }}
          validationSchema={Yup.object().shape({
            // eopCategory: Yup.string().required("Eop category is required"),
            config: Yup.array().of(
              Yup.object().shape({
                fieldName: Yup.string().required("name is  required"),
                // quantity: Yup.string().required("Quantity is required"),
                // unitPrice: Yup.string().required("Unit price  is required"),
                // quantityType: Yup.string().required(
                //   "Quantity type is required"
                // ),
              })
            ),
          })}
          onSubmit={async (values, actions) => {
            console.log({ values });
            actions.setSubmitting(true);
            values.seasonId = match.params.seasonId
            values.config.map((re:any)=>{
              for (let i = 0; i < re.validations.length; i++) {
                re.validations[i].validation = re.validations[i].value;
                re.validations[i].validationValue = "";

                // delete re.validations[i].label
                // delete re.validations[i].value
              }
            })

            try {
              const response = await seasonService.createSeasonForm(
               
                values
              );
              alert.success(response.data.message);
              history.push({
                pathname: `/season-form/${match.params.seasonId}`
              });
            } catch (error: any) {
              console.log(error.response.data);
              alert.error("error");
            }
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            handleChange,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <Col md="4">
                  <FormGroup className="">
                    <Label className="font-weight-bold">Category</Label>
                    <Field
                      name="farmStageId"
                      as="select"
                      className="form-control form-control-lg"
                    >
                      <option> Select</option>
                      {/* <option value="Input">Input</option>
                      <option value="Others">Others</option> */}
                       {farmingStage.map((ass: any, i: any) => (
                              <option value={ass.id}>
                                {ass.name}
                              </option>
                            ))}
                    </Field>
                    {touched.farmStageId && errors.farmStageId ? (
                      <div className="text-danger">{errors.farmStageId}</div>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="">
                    <Label className="font-weight-bold">Category</Label>
                    <DatePicker
                            selected={new Date(values.startDate)}
                            dateFormat="MMMM d, yyyy"
                            className="form-control form-control-lg"
                            autoComplete="off"
                            minDate={new Date()}
                            name="startDate"
                            onChange={(date) =>
                              setFieldValue("startDate", date)
                            }
                          />
                    {touched.farmStageId && errors.farmStageId ? (
                      <div className="text-danger">{errors.farmStageId}</div>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="4">
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            End Date{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          {/* <Field
                          type="date"
                          label="End Date"
                          className="form-control form-control-lg"
                          name="endDate"
                        /> */}
                          <DatePicker
                            selected={new Date(values.endDate)}
                            dateFormat="MMMM d, yyyy"
                            className="form-control form-control-lg"
                            autoComplete="off"
                            minDate={new Date(values.startDate)}
                            name="endDate"
                            onChange={(date) => setFieldValue("endDate", date)}
                          />
                        </FormGroup>
                      </Col>
              </Row>
              <FieldArray
                name="config"
                render={(arrayHelpers) => (
                  <div>
                    {values.config.map((friend: any, index: number) => (
                      <React.Fragment>
                        <Row className="my-2 bg-green-3 p-3">
                          <Col>
                            <FormGroup>
                              <Label className="font-weight-bold">
                              Enter Question
                              </Label>
                              <Field
                                type="text"
                                placeholder="field name"
                                name={`config[${index}].fieldName`}
                                className="form-control form-control-lg"
                              />
                              <div className="text-danger">
                                <ErrorMessage name="config[0].fieldName" />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="">
                              <Label className="font-weight-bold">
                              Select Validation Type
                              </Label>
                              {/* <Field
                                type="text"
                                placeholder="dropdown value"
                                name={`config[${index}].validations`}
                                className="form-control form-control-lg"
                                valid
                              /> */}

                                <Select
                                  onChange={(th:any) => {
                                    setFieldValue(`config[${index}].validations`, th);
                                  }}
                                  options={validations}
                                  defaultValue={friend.validations}
                                  isMulti={true}
                                  name={`config[${index}].validations`}
                                />
                              <div className="text-danger">
                                <ErrorMessage name="config[0].validations" />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="">
                              <Label className="font-weight-bold">
                              Type of Answer Expected
                              </Label>
                              {/* <Field
                                type="text"
                                placeholder="control type"
                                name={`config[${index}].controlType`}
                                className="form-control form-control-lg"
                                valid
                              /> */}
                              <Field
                                  name={`config[${index}].controlType`}
                                  as="select"
                                  className="form-control form-control-lg"
                                >
                                  <option> Select</option>
                                  {/* <option value="Input">Input</option>
                                  <option value="Others">Others</option> */}
                                  { controlType.map((ass: any, i: any) => (
                                          <option value={ass}>
                                            {ass}
                                          </option>
                                        ))}
                                </Field>
                              <div className="text-danger">
                                <ErrorMessage name="config[0].controlType" />
                              </div>
                            </FormGroup>
                          </Col>
                          {values.config[index].controlType == "DropDown"?
                          <Col>
                          <FormGroup className="">
                            <Label className="font-weight-bold">Dropdown Values</Label>
                            <Field
                              type="text"
                              placeholder="male,female"
                              name={`config[${index}].values`}
                              className="form-control form-control-lg"
                              valid
                            />
                            <div className="text-danger">
                              <ErrorMessage name="config[0].values" />
                            </div>
                          </FormGroup>
                        </Col>
                          :null}
                          <Button
                            close
                            className="text-danger"
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </Row>
                      </React.Fragment>
                    ))}
                    <Button
                      color="success"
                      type="button"
                      className="btn-create px-4 py-2 shadow font-weight-bold bg-or"
                      onClick={() =>
                        arrayHelpers.push({
                          fieldName: "",
                          controlType: "",
                          validations: [],
                          values: "",
                        })
                      }
                    >
                      Add
                    </Button>
                  </div>
                )}
              />

              <div className="p-3 text-center">
                <Link
                  to={{
                    pathname: `/season-form/${match.params.seasonId}`,
                  }}
                >
                  <Button
                    color="success"
                    className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  color="success"
                  type="submit"
                  className="btn-create px-4 py-2 shadow font-weight-bold"
                  disabled={isSubmitting}
                >
                  Create
                  {isSubmitting ? <FaSpinner className="fa-spin" /> : null}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
 </PageBody>

  );
};

export default Duplicate_season_form;
