import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../redux/store/rootReducer";
import React from "react";
import { usersThunk } from "../../../../../redux/action/users/users.thunk";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { seasonService } from "../../../../../Services/seasonService";
import { seasonThunk } from "../../../../../redux/action/season/season.thunk";
import { useAlert } from "react-alert";

interface AssignManagerModalProps {
  isOpen: boolean;
  toggle: () => void;
  uniqueId?: string;
  refresh?: () => void;

}

const AssignManagerModal = ({
  isOpen,
  toggle,
  uniqueId,
  refresh
 
}: AssignManagerModalProps) => {
  const [selectedManager, setSelectedManager] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const dispatch = useDispatch();
  const { updateLoader } = useLoadingMessage();
  const alert = useAlert();

  const { users }: any = useSelector((state: RootState) => state.users);

  const getusers = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(usersThunk.getAllUsers());
    updateLoader(false, "");
  }, [users]);

  useEffect(() => {
    if (isOpen) {
      getusers();
    }
  }, [isOpen]);

  useEffect(() => {
    if (users.data) {
      const managerOptions = users.data
        .filter((user: any) => user.role === "Warehouse Manager")
        .map((user: any) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));

      setOptions(managerOptions);
    }
  }, [users.data]);

  const handleSelectChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setSelectedManager(selectedOption);
  };

  const handleAssignManager = async () => {
    if (!selectedManager) {
      alert.error("Please select a manager before saving.");
      return;
    }
    if (selectedManager && uniqueId) {
      try {
        const response = await seasonService.assignWarehouseManager(
          uniqueId,
          selectedManager.value
        );
        if (response.status === 200) {
          alert.success(response.data.message);
         
        //  seasonService.getAllWerehouse()
        if (refresh) refresh(); // Call refresh function
        } else {
          alert.error("Failed to assign manager");
        }
      } catch (error) {
        alert.error("An error occurred while assigning the manager.");
      }
    }
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      className="modal-right"
      style={{ maxWidth: "600px", width: "90%" }}
    >
      <ModalHeader toggle={toggle} className="w-100 text-center">
        Assign Manager
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row className="d-flex justify-content-center">
            <Col md={6} className="d-block text-center">
              <Select
                placeholder="Select manager"
                value={selectedManager}
                onChange={handleSelectChange}
                options={options}
                className="text-center"
                classNamePrefix="react-select"
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button
          color="success"
          className="btn-create px-4 py-2 shadow font-weight-bold"
          style={{ borderRadius: 0, width: "100%" }}
          onClick={handleAssignManager}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AssignManagerModal;
