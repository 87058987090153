import { usersType } from "../../actionTypes";

const initialState = {
  users: { data: [], meta: {} },
  activity: { data: [], meta: {} },
  // user: {},
  // current_page:"",
  // last_page:"",
};
type seasonAction = {
  type: string;
  payload: [];
};

type seasonActions = {
  type: string;
  payload: { data: []; meta: {} };
};

export const usersReducers = (state = initialState, action: seasonActions) => {
  switch (action.type) {
    case usersType.GET_ALL_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const activityReducers = (
  state = initialState,
  action: seasonAction
) => {
  switch (action.type) {
    case usersType.GET_ALL_ACTIVITY: {
      return {
        ...state,
        activity: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
