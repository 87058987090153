import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import { FaTimesCircle } from "react-icons/fa";
import { seasonService } from "../../../../Services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/rootReducer";
import { configThunk } from "../../../../redux/action/config/config.thunk";
import {
  PurchaseOrder,
  Supplier,
  EOProduct,
  EOPResponseDetail,
} from "../../../../models/config.model";
import { configService } from "../../../../Services/configService";
import { useAlert } from "react-alert";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import Select from "react-select";

interface EditPOFormProps {
  open: boolean;
  toggle: () => void;
  warehouseId?: string;
  supplierId?: number;
  eopResponseDetails: EOPResponseDetail[];
  purchaseOrderId: number;
  refreshData: () => void;
}

const EditPOForm: React.FC<EditPOFormProps> = ({
  open,
  toggle,
  warehouseId,
  supplierId,
  eopResponseDetails,
  purchaseOrderId,
  refreshData,
}) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState<string>(
    warehouseId || ""
  );
  const [selectedSupplier, setSelectedSupplier] = useState<number | null>(
    supplierId || null
  );
  const [eoPs, setEoPs] = useState<EOProduct[]>([]);
  const [currentEOProduct, setCurrentEOProduct] = useState<EOProduct>({
    id: 0,
    name: "",
    quantity: 0,
    maxQuantity: 0,
  });
  const [purchaseOrderData, setPurchaseOrderData] = useState<PurchaseOrder[]>(
    []
  );
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [warehouses, setWarehouses] = useState<
    { label: string; value: string }[]
  >([]);
  const { suppliers } = useSelector((state: RootState) => state.suppliers);

  const dispatch = useDispatch();
  const alert = useAlert();

  const getWarehouses = async () => {
    try {
      const response = await seasonService.getAllWerehouse();
      const warehouseData = response.data.data.warehouseData;
      const formattedWarehouses = warehouseData.map((warehouse: any) => ({
        label: warehouse["werehouse name"],
        value: warehouse["uniqueId"],
      }));
      setWarehouses(formattedWarehouses);
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }
  };

  const loadPurchaseOrder = async () => {
    try {
      const response = await configService.getOnePurchaseOrder(purchaseOrderId);
      const poData = response.data.data;
      setSelectedWarehouse(poData.warehouseUniqueId || warehouseId);
      setSelectedSupplier(poData.supplierId);
      setEoPs(
        poData.purchaseOrderEOPs.map((eop: any) => ({
          id: eop.eopId,
          name: eop.eopName,
          quantity: eop.quantityRequested,
          maxQuantity: eop.maxQuantity,
        }))
      );
    } catch (error) {
      alert.error("Error loading purchase order.");
    }
  };

  const getroles = React.useCallback(async () => {
    await dispatch(configThunk.getAllSuppliers(suppliers));
  }, [suppliers]);

  useEffect(() => {
    if (warehouseId) {
      setSelectedWarehouse(warehouseId);
    }
  }, [warehouseId]);

  useEffect(() => {
    getWarehouses();
    getroles();
    if (purchaseOrderId) {
      loadPurchaseOrder();
    }
  }, [purchaseOrderId]);

  const addEOProduct = () => {
    if (currentEOProduct.id > 0 && currentEOProduct.quantity > 0) {
      if (currentEOProduct.quantity > currentEOProduct.maxQuantity) {
        alert.error("Quantity exceeds the maximum allowed.");
        return;
      }
      if (eoPs.some((product) => product.id === currentEOProduct.id)) {
        alert.info("Product already added.");
        return;
      }
      setEoPs((prevEoPs) => {
        const newEoPs = [...prevEoPs, currentEOProduct];
        console.log("Updated eoPs after adding:", newEoPs);
        return newEoPs;
      });
      setCurrentEOProduct({ id: 0, name: "", quantity: 0, maxQuantity: 0 });
    } else {
      alert.info("Please select a valid product and enter a valid quantity.");
    }
  };

  const removeEOProduct = (index: number) => {
    setEoPs((prevEoPs) => prevEoPs.filter((_, i) => i !== index));
  };

  const handleProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedProduct = eopResponseDetails.find(
      (item) => item.id === parseInt(e.target.value)
    );
    if (selectedProduct) {
      setCurrentEOProduct({
        id: selectedProduct.id,
        name: selectedProduct.name,
        quantity: 0,
        maxQuantity: selectedProduct.quantity,
      });
    }
  };

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputQuantity = parseInt(e.target.value) || 0;
    if (inputQuantity <= currentEOProduct.maxQuantity) {
      setCurrentEOProduct((prev) => ({
        ...prev,
        quantity: inputQuantity,
      }));
    }
  };

  const handleEOProductQuantityChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedQuantity = parseInt(e.target.value) || 0;
    setEoPs((prevEoPs) => {
      return prevEoPs.map((eoProduct, i) => {
        if (i === index) {
          return { ...eoProduct, quantity: updatedQuantity };
        }
        return eoProduct;
      });
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedWarehouse || !selectedSupplier || eoPs.length === 0) {
      alert.info("Please fill out all fields and add at least one product.");
      return;
    }

    const purchaseOrder: PurchaseOrder = {
      warehouseUniqueId: selectedWarehouse,
      supplierId: selectedSupplier,
      eoPs: eoPs.map(({ id, quantity }) => ({ eopId: id, quantity })),
    };

    try {
      const response = await configService.editPurchaseOrder(
        // purchaseOrderId,
        purchaseOrderId.toString(),
        purchaseOrder
      );
      alert.success(response.data.message);
      refreshData();
      toggle();
    } catch (error: any) {
      alert.error(error.response.data.message);
    }
  };

  return (
    <Modal isOpen={open} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Edit Purchase Order</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <FormGroup>
                <Label for="warehouse">Warehouse</Label>

                {warehouses.length > 0 && (
                  <Select
                    options={warehouses}
                    value={
                      warehouses.find((wh) => wh.value === selectedWarehouse) ||
                      null
                    }
                    onChange={(selectedOption) =>
                      setSelectedWarehouse(selectedOption?.value || "")
                    }
                  />
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="supplier">Supplier</Label>
                <Select
                  name="supplier"
                  id="supplier"
                  value={suppliers
                    .map((supplier: Supplier) => ({
                      label: supplier.name,
                      value: supplier.id,
                    }))
                    .find((option) => option.value === selectedSupplier)}
                  onChange={(option) =>
                    setSelectedSupplier(option?.value || null)
                  }
                  options={suppliers.map((supplier: Supplier) => ({
                    label: supplier.name,
                    value: supplier.id,
                  }))}
                />
              </FormGroup>
            </Col>
          </Row>
          {eoPs.map((eoProduct, index) => (
            <Row key={index} className="mb-2 align-items-center">
              <Col md={5}>
                <Input type="text" value={eoProduct.name} />
              </Col>
              <Col md={5}>
                <Input
                  type="number"
                  value={eoProduct.quantity}
                  onChange={(e) => handleEOProductQuantityChange(index, e)}
                />

                {/* <span>Remaining Quantity: {eoProduct.maxQuantity - eoProduct.quantity}</span> */}
              </Col>
              <Col md={2} className="text-center">
                <Button
                  color="link"
                  onClick={() => removeEOProduct(index)}
                  className="p-0"
                >
                  <FaTimesCircle size={24} color="black" />
                </Button>
              </Col>
            </Row>
          ))}
          <Row className="mb-3">
            <Col md={5}>
              <Input
                type="select"
                placeholder="Select Product"
                value={currentEOProduct.id || ""}
                onChange={handleProductChange}
              >
                <option value="">Select Product</option>
                {eopResponseDetails.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md={5} style={{ marginBottom: "2rem" }}>
              <Input
                type="number"
                placeholder="Enter quantity"
                value={currentEOProduct.quantity || ""}
                onChange={handleQuantityChange}
              />
            </Col>
            <Col md={2}>
              <Button color="success" onClick={addEOProduct}>
                Add
              </Button>
            </Col>
          </Row>
          <Button type="submit" color="success" className="w-100">
            Update Purchase Order
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditPOForm;
