import React, { useContext } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { FaUserAlt } from "react-icons/fa";
import AppBreadcrumb from "../../../components/layout/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { usersThunk } from "../../../redux/action/users/users.thunk";
import { PageBody } from "../../../components/Loader";
import { useLoadingMessage } from "../../../components/useLoadingMessage";
import { RootState } from "../../../redux/store/rootReducer";
import { ResponseuserType } from "../../../models/user.model";
import { ConfirmTemplate } from "../../../components/confirm";
import { useAlert } from "react-alert";
import { userService } from "../../../Services";
import { HiDotsVertical } from "react-icons/hi";
import Layout from "../../../components/Layout";
import { AuthContext } from "../../../context/authContext";
import { roless } from "../../../Constants";

const Users = () => {
  const { role } = useContext(AuthContext);
  const { isLoading, message, updateLoader, sideBar } = useLoadingMessage();
  const dispatch = useDispatch();
  const { users }: any = useSelector((state: RootState) => state.users);
  const alert = useAlert();
  const [search, setSearch] = React.useState("");

  const getusers = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(usersThunk.getAllUsers());
    updateLoader(false, "");
  }, [users]);

  const loading = React.useCallback(
    async (page) => {
      updateLoader(true, "Fetching Data");
      await dispatch(usersThunk.getAllUsersQuery(`?PageNumber=${page}`));
      updateLoader(false, "");
    },
    [updateLoader, users]
  );

  const allUsers =
    users.data &&
    users.data.filter((user: any) => {
      return (
        user.firstName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        user.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    });

  const onDisabled = (id: number, status: boolean) => {
    console.log(status);
    const request = async () => {
      updateLoader(true, "Status Changing");
      sideBar(true, "User");
      const data = {
        status: !status,
      };
      if (!status) {
        const response = await userService.enableUser(id);
        if (response.status === 200) {
          alert.success("successfully Changed Status");
        } else {
          alert.error("error");
        }
      } else {
        const response = await userService.disableUser(id);
        if (response.status === 200) {
          alert.success("Changed Status");
        } else {
          alert.error("error");
        }
      }
      getusers();
      updateLoader(false, "");
    };

    ConfirmTemplate(
      {
        title: "Changing Status ",
        message: "Are You Sure You Want To Change the Status",
      },
      request
    );
  };

  React.useEffect(() => {
    getusers();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Users">
        <Row>
          <Col className="mb-4">
            {roless.BusinessAd == role || roless.ITAd == role ? (
              <Link to="/create-user">
                <Button className="btn-create px-4 py-2 shadow" inline>
                  Create User
                </Button>
              </Link>
            ) : null}

            <span className="ml-5">
              <Input
                placeholder="search"
                type="search"
                className="form-search"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </span>
          </Col>
        </Row>
        <Row>
          {allUsers?.length < 1 ? (
            <div className="text-center m-auto">
              <h1>No record found</h1>
            </div>
          ) : (
            allUsers?.map((user: ResponseuserType, i: any) => (
              <Col sm={4} lg={4} className="mb-5">
                <Card className="user-card shadow border-0">
                  <CardBody>
                    {roless.BusinessAd == role || roless.ITAd == role ? (
                      <div className=" float-right bg-gray rounded-circle shadow">
                        <UncontrolledDropdown className="m-drop">
                          <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                            <HiDotsVertical />
                          </DropdownToggle>
                          <DropdownMenu
                            container="body"
                            className="border-0 shadow"
                          >
                            <DropdownItem
                              onClick={() =>
                                onDisabled(user.id, user.accountStatus)
                              }
                            >
                              {user.accountStatus ? "Deactivate" : "Activate"}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    ) : null}
                    <div className="text-center">
                      <div className="user-icon  rounded-circle  text-center my-4">
                        <FaUserAlt className="t-green" />
                      </div>
                      <h6 className="m-2 font-weight-bold">
                        {user.firstName} {user.lastName}
                      </h6>
                      <small className="font-weight-bold ">{user.email}</small>{" "}
                      <br />
                      <i>{user.phoneNumber}</i> <br />
                      <i>{user.role}</i> <br />
                      <i>
                        {" "}
                        {user.accountStatus ? (
                          <small className="text-success"> Active </small>
                        ) : (
                          <small className="text-danger"> Inactive</small>
                        )}{" "}
                      </i>
                    </div>
                    {roless.BusinessAd == role || roless.ITAd == role ? (
                      <div className="text-center">
                        <Link to={`/edit-user/${user.id}`}>
                          <Button
                            size="sm"
                            className="m-3 px-5 py-1  border-0 font-weight-bold shadow"
                          >
                            Edit
                          </Button>
                        </Link>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            ))
          )}
        </Row>

        <div className="bottom-p">
          <Pagination aria-label="Page navigation">
            <PaginationItem
              disabled={
                users?.meta?.pageNumber == users?.meta?.firstPage ? true : false
              }
            >
              <PaginationLink
                first
                onClick={() => loading(users?.meta?.pageNumber - 1)}
              />
            </PaginationItem>
            {Array.from({ length: users?.meta?.lastPage }).map((u, i) => (
              <PaginationItem
                active={users?.meta?.pageNumber == i + 1 ? true : false}
              >
                <PaginationLink onClick={() => loading(i + 1)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem
              disabled={
                users?.meta?.pageNumber == users?.meta?.lastPage ? true : false
              }
            >
              <PaginationLink
                last
                onClick={() => loading(users?.meta?.nextPage)}
              />
            </PaginationItem>
          </Pagination>
        </div>
      </Layout>
    </PageBody>
  );
};

export default Users;
