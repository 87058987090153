import React from "react";
import { Button, Col, Container, FormGroup, Row, Label } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { Link, RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/rootReducer";
import { configThunk } from "../../../../../redux/action/config/config.thunk";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { seasonService } from "../../../../../Services";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { PageBody } from "../../../../../components/Loader";
import { FaSpinner } from "react-icons/fa";
import Layout from "../../../../../components/Layout";
import ErrorMessage from "../../../../../components/form/error-message";
import{ seasonThunk } from "../../../../../redux/action/season/season.thunk"
import Select from "react-select";
import * as signalR from "@microsoft/signalr";

interface FormValues {
  uniqueId: string;
  columns: { label: string; value: string }[];
}

const columns = [
  { label: "Cluster State", value: "ClusterState" },
  { label: "Cluster LGA", value: "clusterlga" },
  { label: "Cluster Farm Location", value: "clusterfarmlocation" },
  { label: "Cluster Name", value: "clustername" },
  { label: "Number of Hectares", value: "numberofhectares" },
  { label: "Cluster Head First Name", value: "clusterheadfname" },
  { label: "Cluster Head Surname", value: "clusterheadsurname" },
  { label: "Cluster Head Middle Name", value: "clusterheadmname" },
  { label: "Cluster Head Contact", value: "clusterheadcontact" },
  { label: "Cluster Head BVN", value: "clusterheadbvn" },
  { label: "Warehouse Location", value: "warehouselocation" },
  { label: "Warehouse Contact Person Full Name", value: "warehousecontactpersonfullname" },
  { label: "Warehouse Contact Person Phone Number", value: "warehousecontactpersonphonenumber" },
 
];

const UpdateCluster = ({
  match,
}: RouteComponentProps<{ id: string; uniqueId: any }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation<{ uniqueId: string; seasonData: any }>();
  const { seasonData } = location.state;
  const uniqueId = location.state?.uniqueId || "";

  const initialValues: FormValues = {
    uniqueId: uniqueId,
    columns: [],
  };

  const [files, setFiles] = React.useState([]);
  
  function fileSizeValidator(file: any) {
    if (file.size > 1024 ** 2 * 2) {
      return {
        code: "size-too-large",
        message: `File is larger than 2mb`,
      };
    }
    return null;
  }

  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv, application/vnd.ms-excel, text/csv",
    maxFiles: 2,
    validator: fileSizeValidator,
  });

  const { states } = useSelector((state: RootState) => state.states);
  const { seasonUpload } = useSelector((state: RootState) => state.seasonUpload);

  // const getStateDropDown = React.useCallback(async () => {
  //   updateLoader(true, "Fetching Data");
  //   await dispatch(configThunk.getAllStates(states));
  //   updateLoader(false, "");
  // }, [states]);

  const getuploads = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(seasonThunk.getAllSeasonsUpload(match.params.id));
    console.log("seasonUpload", seasonUpload);

    updateLoader(false, "");
  }, [seasonUpload]);


  React.useEffect(() => {
    getuploads();
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("https://abp.remsys.com.ng/notify", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    newConnection
      .start()
      .then((result) => {
        console.log("Connected!");
      })
      .catch((e) => console.log("Connection failed: ", e));

    newConnection.on("Progress", (data) => {
      console.log("Progress Report: ", data);
      const d2 = data.replace(/\s/g, "");
      if (d2 == "UploadCompleted") {
        getuploads();
      }
    });
    // getStateDropDown();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <h5>
            Season: {seasonData.seasonType + " " + seasonData.associationName}
          </h5>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              if (files.length < 1) {
                alert.error("You have not selected a file ");
                actions.setSubmitting(false);
              } else {
                const formData = new FormData();
                let file = files[0];
                formData.append("file", file);
                formData.append("upload_preset", "s9n5tgkf");

                const selectedColumns = values.columns.map(column => column.value);
                selectedColumns.forEach(column => {
                  formData.append("columns", column);
                });

                try {
                  const response = await seasonService.updateCluster(values.uniqueId, formData, selectedColumns);
                  if (response.status == 200) {
                    alert.success("Upload started successfully");
                    setFiles([]);
                    actions.setSubmitting(false);
                    history.push({
                      // pathname: `/all-upload/${match.params.id}`,
                      pathname: `/season`,
                      state: {
                        seasonData: seasonData,
                      },
                    });
                  }
                } catch (error: any) {
                  console.log(error.response.data.message);
                  alert.error(error.response.data.message);
                }
              }
            }}
          >
            {({ errors, touched, isSubmitting, handleChange, values, setFieldValue }) => (
              <Form>
                <div className="upload-sec p-4">
                  <div className="bg-green-2 p-5 text-white rounded">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p className="text-center text-xl">
                          Drop your media files here
                        </p>
                      ) : (
                        <h3 className="text-center text-xl">
                          Drag and drop cluster file to update here, <br />{" "}
                          <Button
                            className="bg-white text-dark border-0 shadow mt-5 py-3 px-5"
                            type="button"
                          >
                            Or click here to select files
                          </Button>
                        </h3>
                      )}
                    </div>
                  </div>
                </div>

                {files.length > 0 && (
                  <div className="text-center">
                    {/* <Button
                      className="btn btn-success"
                      onClick={() => {}}
                    >
                      Click here to upload
                    </Button> */}
                  </div>
                )}
                {files.map((f: any) => (
                  <ul key={f.name}>
                    <li>{f.name}</li>
                  </ul>
                ))}

                {fileRejections.map(({ errors }) => (
                  <li key={errors[0].code}>
                    <ul>
                      {errors.map((e) => (
                        <li key={e.code}>{e.message}</li>
                      ))}
                    </ul>
                  </li>
                ))}
                <Row>
                  <Col md={6}>
                    <FormGroup className="">
                      <Label className="font-weight-bold">
                        Select Columns
                        <span className="text-danger fw-bold">*</span>
                      </Label>
                      <Select
                        onChange={(selectedOptions) => setFieldValue("columns", selectedOptions)}
                        options={columns}
                        isMulti
                        name="columns"
                        classNamePrefix="select"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="columns" />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    className="btn bg-green-2 border-0 shadow"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Click here to upload{" "}
                    {isSubmitting ? <FaSpinner className="fa-spin" /> : null}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

          <div className="p-3 text-center">
            <Link
              to={{
                pathname: `/all-upload/${match.params.id}`,
                state: {
                  seasonData: seasonData,
                },
              }}
            >
              <Button
                color="success"
                className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
              >
                Cancel
              </Button>
            </Link>
          </div>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default UpdateCluster;
