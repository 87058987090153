import React, { createContext, useEffect, useState, Dispatch } from "react";
import { userService } from "../Services";
import { ApiClient } from "../Constants";
import { useHistory, useLocation } from "react-router-dom";

import { useAlert } from "react-alert";

export interface Employee {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  employee_number: string;
  phone_number: string;
  date_of_employment: string;
  date_of_birth: string;
  email_verified_at: string;
  role: string;
}

export interface AuthState {
  user: Employee | null;
  loading: boolean;
  logout: () => void;
  fetchData: () => void;
  token: string | null;
  setUser: (user: Employee) => void;
  ready: boolean;
  role: string | null;
}

const initialValues: AuthState = {
  logout: () => {},
  user: null,
  setUser: () => {},
  loading: false,
  fetchData: () => {},
  token: null,
  ready: false,
  role: null,
};

interface IOptions {
  children?: React.ReactNode;
}

export const AuthContext = React.createContext<AuthState>(initialValues);
export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = ({ children }: IOptions) => {
  const history = useHistory();
  const location = useLocation();

  const alert = useAlert();
  const [user, setUser] = useState<Employee | null>(null);
  const [loading, Setloading] = useState(false);
  const [ready, setReady] = useState(false);
  const [role, setRole] = useState<null>(null);

  const token = localStorage.getItem("token");

  

  const logout = async () => {
    await ApiClient.unsetToken();
    setUser(null);
    alert.success("Successfully Logout");
    history.push("/");
  };

  const fetchData = async (redirectIfValid?: string) => {
    Setloading(true);

    try {
      const response: any = await userService.authenticatedUser();

      setReady(true);
      if (response.status == 200) {
        setUser(response.data);
        setRole(response.data.data.role);
        console.log(response.data.data.role);
      }else {
        history.push("/");
      }
      // if (redirectIfValid) {
      //   console.log("hi")
      //   history.push(redirectIfValid)
      // }
    } catch (error: any) {
      setUser(null);
      if (redirectIfValid && user !== null) {
        history.push(redirectIfValid);
      } else if (
        redirectIfValid == "/forget-password" ||
        redirectIfValid?.includes("/reset-password/") ||
        redirectIfValid?.includes("/verify-email/")
      ) {
        history.push(redirectIfValid);
      } else {
        history.push("/");
      }
    }
    Setloading(false);
    setReady(true);
  };

  useEffect(() => {
    // if(!user || token == undefined){
    fetchData(location.pathname);
  // }else{
  //   return
  // }
  }, []);
  
  return (
    // <>
    <AuthContext.Provider
      value={{ user, logout, setUser, loading, fetchData, ready, token, role }}
    >
      {children}
    </AuthContext.Provider>
  );
};
