import React from "react";
import { Button, Container, FormGroup, Label, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { seasonThunk } from "../../../../../redux/action/season/season.thunk";
import { ResponseEop } from "../../../../../models/season.model";
import { RootState } from "../../../../../redux/store/rootReducer";
import {
  useHistory,
  RouteComponentProps,
  Link,
  useLocation,
} from "react-router-dom";
import { PageBody } from "../../../../../components/Loader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FaSpinner } from "react-icons/fa";
import { seasonService } from "../../../../../Services";
import Layout from "../../../../../components/Layout";

interface FormValues {
  bags: number;
  costPerBag: number;
}

const Create_yield = ({ match }: RouteComponentProps<{ id: string }>) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const { status, seasonData } = location.state;

  const initialValues: FormValues = { bags: 0, costPerBag: 0 };

  return (
    <Layout active="Seasons">
      <Container>
        <Link
          to={{
            pathname: `/eop/${match.params.id}`,
            state: {
              seasonData: seasonData,
              status: status,
            },
          }}
        >
          <Button className=" px-4 py-2 shadow btn-download  ml-4 mb-4" inline>
            Back
          </Button>
        </Link>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            bags: Yup.string().required("Bag is required"),
            costPerBag: Yup.string().required("Cost is required"),
          })}
          onSubmit={async (values, actions) => {
            console.log({ values, actions });

            actions.setSubmitting(true);
            try {
              const response = await seasonService.createYield(
                match.params.id,
                values
              );
              console.log(response);
              if (response.data.data) {
                alert.success("successfully created");
                history.push({
                  pathname: `/eop/${match.params.id}`,
                  state: {
                    seasonData: seasonData,
                    status: status,
                  },
                });
              }
            } catch (error) {
              alert.error("invalid details");
            }
          }}
        >
          {({ errors, touched, isSubmitting, handleChange, values }) => (
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup className="">
                    <Label className="font-weight-bold">
                      Expected number of bags{" "}
                      <span className="text-danger fw-bold">*</span>{" "}
                    </Label>
                    <Field
                      type="text"
                      placeholder="enter name"
                      name="bags"
                      className="form-control form-control-lg"
                    />
                    {touched.bags && errors.bags ? (
                      <div className="text-danger">{errors.bags}</div>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="">
                    <Label className="font-weight-bold">
                      Unit cost <span className="text-danger fw-bold">*</span>{" "}
                    </Label>
                    <Field
                      type="text"
                      placeholder="enter name"
                      name="costPerBag"
                      className="form-control form-control-lg"
                    />
                    {touched.costPerBag && errors.costPerBag ? (
                      <div className="text-danger">{errors.costPerBag}</div>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <div className="p-3 text-center">
                <Link
                  to={{
                    pathname: `/eop/${match.params.id}`,
                    state: {
                      seasonData: seasonData,
                      status: status,
                    },
                  }}
                >
                  <Button
                    color="success"
                    className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  color="success"
                  className="btn-create px-4 py-2 shadow font-weight-bold"
                  disabled={isSubmitting}
                >
                  Create
                  {isSubmitting ? <FaSpinner className="fa-spin" /> : null}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default Create_yield;
