import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Input,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import AppBreadcrumb from "../../../../../components/layout/breadcrumb/Breadcrumb";
import { useHistory, RouteComponentProps, useLocation } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmTemplate } from "../../../../../components/confirm";
import { useAlert } from "react-alert";
import { PageBody } from "../../../../../components/Loader";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { RootState } from "../../../../../redux/store/rootReducer";
import { seasonThunk } from "../../../../../redux/action/season/season.thunk";
import { seasonService } from "../../../../../Services";
import Layout from "../../../../../components/Layout";

const All_states = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const { seasonState } = useSelector((state: RootState) => state.seasonState);
  const alert = useAlert();
  const location = useLocation();
  const { seasonData } = location.state;

  const getstates = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(seasonThunk.getAllSeasonsState(match.params.id));
    updateLoader(false, "");
  }, [seasonState]);

  React.useEffect(() => {
    getstates();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container className="mb-4">
          <Row>
            <Col md={5}>
              {/* <Link to={`/create-states/${match.params.id}`}>
              <Button className="btn-create px-4 py-2 shadow" inline>
                Create State
              </Button>
            </Link> */}
              <Link
                to={{
                  pathname: `/manage-season/${match.params.id}`,
                  state: {
                    seasonData: seasonData,
                  },
                }}
              >
                <Button
                  className=" px-4 py-2 shadow btn-download  ml-4 mb-4"
                  inline
                >
                  Back
                </Button>
              </Link>
            </Col>
            <Col className="text-right">
              {" "}
              <h5>
                Season:{" "}
                {seasonData.seasonType + " " + seasonData.associationName}
              </h5>
            </Col>
          </Row>

          <Row className="mt-4">
            {seasonState && seasonState.length < 1 ? (
              <div className="text-center">
                <h5>No record found</h5>
              </div>
            ) : (
              seasonState.map((cl: any, i: number) => (
                <Col md="3">
                  <Card className="border-0 shadow  py-3 mb-4">
                    <CardBody>
                      <CardText className="font-weight-bold h3 mb-4">
                        {cl.state}
                      </CardText>{" "}
                      <Link
                        to={{
                          pathname: `/view-more/${match.params.id}/${cl.id}`,
                          state: {
                            seasonData: seasonData,
                          },
                        }}
                      >
                        <Button
                          className="bg-green-2 rounded-pill border-0 shadow px-4 font-weight-bold"
                          size="sm"
                        >
                          View More
                        </Button>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default All_states;
