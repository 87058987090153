import { ApiClient, constant } from "../Constants";
import { SeasonType, Season, Eop } from "../models/season.model";

function createSeason(body: Season) {
  return ApiClient.post("/season/create-season", body);
}
function getAllSeason() {
  return ApiClient.get("/season/get-all-seasons", "");
}
function getAllSeasonQuery(query: string) {
  return ApiClient.get(`/season/get-all-seasons${query}`, "");
}
function getOneSeason(id: string) {
  return ApiClient.get(`/season/get-season/${id}`, "");
}
function updateSeason(id: string, body: Season) {
  return ApiClient.put(`/season/edit-season/${id}`, body);
}
function enableSeason(id: string) {
  return ApiClient.patch(`/season/enable-season/${id}`, "");
}
function disableSeason(id: string) {
  return ApiClient.patch(`/season/disable-season/${id}`, "");
}

// download cluster template

function downloadCluster() {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "get",
    responseType: "blob",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
    },
  };

  return fetch(
    `${constant.API}/clusters/download-template`,
    requestOptions
  ).then((response) => {
    return response.blob();
  });
  // return ApiClient.get(`/clusters/download-clusters/${id}`, "");
}

function downloadWehehouse() {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "get",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
    },
  };

  return fetch(
    `${constant.API}/warehouses/download-template`,
    requestOptions
  ).then((response) => {
    return response.blob();
  });
}

function downloadErrorUpload(uniqueId: any) {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "get",
    responseType: "blob",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
    },
  };

  return fetch(
    `${constant.API}/clusters/get-upload-log/${uniqueId}`,
    requestOptions
  ).then((response) => {
    return response.blob();
  });
  // return ApiClient.get(`/clusters/download-clusters/${id}`, "");
}

function downloadFailedUpload(uniqueId: any) {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "get",
    responseType: "blob",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
    },
  };

  return fetch(
    `${constant.API}/clusters/get-failed-upload/${uniqueId}`,
    requestOptions
  ).then(async (response) => {
    return response.blob();
  });
  // return ApiClient.get(`/clusters/download-clusters/${id}`, "");
}

function downloadFailedUpload2(uniqueId: any) {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "get",
    responseType: "blob",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
    },
  };

  return fetch(
    `${constant.API}/warehouses/get-failed-upload/${uniqueId}`,
    requestOptions
  ).then(async (response) => {
    return response.blob();
  });
}

function downloadErrorUpload2(uniqueId: any) {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "get",
    responseType: "blob",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
    },
  };

  return fetch(
    `${constant.API}/warehouses/get-upload-log/${uniqueId}`,
    requestOptions
  ).then((response) => {
    return response.blob();
  });
  // return ApiClient.get(`/clusters/download-clusters/${id}`, "");
}

function downloadSeasonState(seasonId: any, stateId: any) {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "get",
    responseType: "blob",

    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/octet-stream",
    },
  };

  return fetch(
    `${constant.API}/clusters/download-season-state-cluster/${stateId}/${seasonId}`,
    requestOptions
  ).then((response) => {
    return response.blob();
  });
  // return ApiClient.get(`/clusters/download-clusters/${id}`, "");
}

//seasonstate

function createSeasonState(id: any, body: any) {
  return ApiClient.post(`/season/create-season/${id}`, body);
}
function getOneSeasonState(id: any, stateId: any) {
  return ApiClient.get(`/season/create-season/${id}/${stateId}`, "");
}
function editSeasonState(id: any, stateId: any, body: any) {
  return ApiClient.put(`/season/create-season/${id}/${stateId}`, body);
}
function enableSeasonUpload(uniqueId: any) {
  return ApiClient.patch(`/clusters/activate-upload/${uniqueId}`, "");
}
function disableSeasonUpload(uniqueId: any) {
  return ApiClient.patch(`/clusters/deactivate-upload/${uniqueId}`, "");
}
function getAllSeasonState(id: string) {
  return ApiClient.get(`/clusters/get-cluster-states/${id}`, "");
}
function getAllSeasonUpload(id: string) {
  return ApiClient.get(`/clusters/get-cluster-uploads/${id}`, "");
}

function getAllWerehouseUpload() {
  return ApiClient.get(`/warehouses/get-warehouse-uploads`, null);
}

function enableWerehouseUpload(uniqueId: any) {
  return ApiClient.patch(`/warehouses/activate-upload/${uniqueId}`, "");
}

function disableWerehouseUpload(uniqueId: any) {
  return ApiClient.patch(`/warehouses/deactivate-upload/${uniqueId}`, "");
}

function createEop(id: any, body: any) {
  return ApiClient.post(`/season/create-eop/${id}`, body);
}
function getAllEop(id: any) {
  return ApiClient.get(`/season/get-all-eop/${id}`, "");
}

function getOneEop(seasonId: string, eopNumber: string) {
  return ApiClient.get(`/season/get-eop/${seasonId}/${eopNumber}`, "");
}
function updateEop(id: string, body: any) {
  return ApiClient.put(`/season/edit-eop/${id}`, body);
}
function enableEop(id: string) {
  return ApiClient.patch(`/season/enable-eop/${id}`, "");
}
function disableEop(id: string) {
  return ApiClient.patch(`/season/disable-eop/${id}`, "");
}

function getAllField(id: any) {
  return ApiClient.get(`/clusters/get-cluster-fields/${id}`, "");
}

function getAllSeasonAnswer(id: any, seasonId: any) {
  return ApiClient.get(
    `/clusters/get-submitted-data?seasonId=${seasonId}&stateId=${id}&pageSize=20`,
    ""
  );
}
function getAllSeasonAnswerQuery(id: any, seasonId: any, query: any) {
  return ApiClient.get(
    `/clusters/get-submitted-data?seasonId=${seasonId}&stateId=${id}&pageNumber=${query}&pageSize=20`,
    ""
  );
}

function getAllSubmission(id: any, seasonId: any, query: any) {
  return ApiClient.get(
    `/clusters/get-subumission-status?SeasonId=${seasonId}&StateId=${id}&PageNumber=${query}&PageSize=20`,
    ""
  );
}

function getSeasonFormField(id: any) {
  return ApiClient.get(`/clusters/form-field-names/${id}`, "");
}

function getAllCluster(body: any) {
  return ApiClient.post(`/clusters/get-clusters-season`, body);
}

function getAllWerehouse() {
  return ApiClient.get(`/warehouses/get-warehouses`, null);
}

function getAllStateCluster(body: any) {
  return ApiClient.post(`/clusters/get-clusters-states`, body);
}

function createYield(id: any, body: any) {
  return ApiClient.post(`/season/create-expected-yield/${id}`, body);
}
function getAllYield(id: any) {
  return ApiClient.get(`/season/get-all-expected-yield/${id}`, "");
}
function updateYield(id: string, body: any) {
  return ApiClient.put(`/season/edit-expected-yield/${id}`, body);
}
function createSeasonForm(body: any) {
  return ApiClient.post(`/clusters/form-config`, body);
}

function createSeasonFarmStage(body: any) {
  return ApiClient.post(`/clusters/create-season-farmstages-config`, body);
}
function updateSeasonForm(body: any) {
  return ApiClient.put(`/clusters/form-config`, body);
}
function getSeasonForm(seasonId: any, farmstageId: any) {
  return ApiClient.get(`/clusters/form-config/${seasonId}/${farmstageId}`, "");
}
function getAllSeasonForm(seasonId: any) {
  return ApiClient.get(`/clusters/season-farmstages/${seasonId}`, "");
}

function getAllWarehouseWithState(stateId: any, pageNumber: number = 1, pageSize: number = 10) {
  return ApiClient.get(`/warehouses/get-warehouses/${stateId}`, {
    params: {
      PageNumber: pageNumber,
      PageSize: pageSize
    }
  });
}


function uploadCluster(stateId: any, seasonId: any, body: any) {
   console.log("Update Cluster - body:", body);
  return ApiClient.post(
    `/clusters/upload-cluster/${stateId}/${seasonId}`,
    body
  );
}

function uploadWerehouse(stateId: any, body: any) {
  return ApiClient.post(`/warehouses/upload-warehouse/${stateId}`, body);
}

function updateCluster(uniqueId: any, body: any, columns: string[]) {
  return ApiClient.post(`/clusters/update-cluster/${uniqueId}`, body, columns);
}


function linkWarehouse(warehouseDataUniqueId: any, clustersId: string[]){
  return ApiClient.post(`/clusters/link-to-warehouse/${warehouseDataUniqueId}`, clustersId);
}



function assignWarehouseManager(warehouseuniqueId: string, managerId: string) {
  const params = {
    warehouseuniqueId,
    managerId,
  };

  // Pass an empty object `{}` as the body if there's no body content.
  return ApiClient.post(`/warehouses/assign-manager`, {}, [], params);
}




function uploadTemplate(body: any) {
  return ApiClient.post(`/clusters/upload-template`, body);
}
// /clusters/upload-cluster/${values.stateId}/${match.params.id}
export const seasonService = {
  createSeason,
  getAllSeason,
  getOneSeason,
  updateSeason,
  enableSeason,
  disableSeason,
  createSeasonState,
  getOneSeasonState,
  editSeasonState,
  getAllSeasonState,
  getAllSeasonUpload,
  disableSeasonUpload,
  enableSeasonUpload,
  createEop,
  getAllEop,
  getOneEop,
  updateEop,
  enableEop,
  disableEop,
  downloadCluster,
  getAllCluster,
  getAllStateCluster,
  getAllField,
  createYield,
  getAllYield,
  updateYield,
  downloadSeasonState,
  downloadFailedUpload,
  getAllSeasonQuery,
  downloadErrorUpload,
  createSeasonForm,
  getSeasonForm,
  getAllSeasonForm,
  updateSeasonForm,
  createSeasonFarmStage,
  getSeasonFormField,
  getAllSeasonAnswer,
  uploadCluster,
  getAllSeasonAnswerQuery,
  uploadTemplate,
  getAllSubmission,
  downloadWehehouse,
  uploadWerehouse,
  getAllWerehouse,
  getAllWerehouseUpload,
  enableWerehouseUpload,
  disableWerehouseUpload,
  downloadFailedUpload2,
  downloadErrorUpload2,
  updateCluster,
  linkWarehouse,
  getAllWarehouseWithState,
  assignWarehouseManager,
};
