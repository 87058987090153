import React from "react";
import { FaEye } from "react-icons/fa";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import chart from "../../assets/img/charts.png";
import CountTo from "react-count-to";
import Layout from "../../components/Layout";
import { AuthContext } from "../../context/authContext";
import { userService } from "../../Services";
import { usersThunk } from "../../redux/action/users/users.thunk";
import { RootState } from "../../redux/store/rootReducer";
import { useLoadingMessage } from "../../components/useLoadingMessage";
import { useDispatch, useSelector } from "react-redux";
import { PageBody } from "../../components/Loader";
import moment from "moment";

const Dashboard = () => {
  const { user }: any = React.useContext(AuthContext);
  const { activity }: any = useSelector((state: RootState) => state.act);
  const { isLoading, message, updateLoader, sideBar } = useLoadingMessage();
  const dispatch = useDispatch();

  const getActivity = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(usersThunk.getAllUsersActivity());
    updateLoader(false, "");
  }, [activity]);
  React.useEffect(() => {
    getActivity();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Dashboard">
        <div className=" mb-4">
          {" "}
          <h3 className="text-black">
            Welcome,{" "}
            <small>
              {" "}
              {user?.data?.firstName + " " + user?.data?.lastName}{" "}
            </small>{" "}
          </h3>{" "}
        </div>
        <Row>
          <Col>
            <Card className=" border-0 shadow mt-5">
              <CardBody>
                <div className="clearfix">
                  <div className="float-left">
                    <h6 className="font-weight-bold my-3">Activity Log</h6>
                  </div>
                  <div className="float-right">
                    {/* <small className="font-weight-bold">View All</small> */}
                  </div>
                </div>

                <Table hover responsive>
                  <tbody>
                    {activity?.data?.length < 1 ? (
                      <div className="text-center m-auto">
                        <h1>No record found</h1>
                      </div>
                    ) : (
                      activity?.data?.map((act: any, i: any) => (
                        <tr className="mt-3">
                          <td scope="row">{i + 1}</td>
                          <td className="font-weight-bold">
                            {act.description}
                          </td>
                          <td>{moment(act.dateCreated).format("LLLL")}</td>
                          <td>
                            {" "}
                            {moment(act.dateCreated).startOf("hour").fromNow()}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Layout>
    </PageBody>
  );
};

export default Dashboard;
