import React from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";
import { useAlert } from "react-alert";
import {
  Link,
  useHistory,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import ErrorMessage from "../../../../../components/form/error-message";
import Layout from "../../../../../components/Layout";
import { PageBody } from "../../../../../components/Loader";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/rootReducer";
import { configThunk } from "../../../../../redux/action/config/config.thunk";
import { controlType, validations } from "../../../../../Constants";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { configService, seasonService } from "../../../../../Services";

const Create_crop_calender = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const { seasonData } = location.state;

  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [farmingStage, setfarmstage] = React.useState([]);
  const [season, setseason] = React.useState<any>([]);
  const [date, setDate] = React.useState<any>([]);

  const dispatch = useDispatch();

  const getSeasonDropDown = async () => {
    updateLoader(true, "Fetching Data");
    const respons = await seasonService.getOneSeason(match.params.id);
    const responses: any = await seasonService.getAllSeasonForm(
      match.params.id
    );
    setDate(respons.data.data);

    let Conf: any = [];
    responses.data.response.map((ft: any) => {
      Conf.push({
        farmStageId: ft.id,
        startDate: new Date(ft.startDate),
        endDate: new Date(ft.endDate),
      });
    });
    setseason(Conf);
    setfarmstage(responses.data.response);
    updateLoader(false, "");
  };

  React.useEffect(() => {
    getSeasonDropDown();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <Formik
            initialValues={{
              seasonId: match.params.id,
              configs: season,
            }}
            validationSchema={Yup.object().shape({
              // eopCategory: Yup.string().required("Eop category is required"),
              configs: Yup.array().of(
                Yup.object().shape({
                  // farmStageId: Yup.string().required("name is  required"),
                  // quantity: Yup.string().required("Quantity is required"),
                  // unitPrice: Yup.string().required("Unit price  is required"),
                  // quantityType: Yup.string().required(
                  //   "Quantity type is required"
                  // ),
                })
              ),
            })}
            onSubmit={async (values, actions) => {
              console.log({ values });
              actions.setSubmitting(true);
              values.seasonId = match.params.id;

              try {
                const response = await seasonService.createSeasonFarmStage(
                  values
                );
                alert.success(response.data.message);
                history.push({
                  pathname: `/season-form/${match.params.id}`,
                  state: {
                    seasonData: seasonData,
                  },
                });
              } catch (error: any) {
                console.log(error.response.data);
                alert.error("error");
              }
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              handleChange,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <FieldArray
                  name="config"
                  render={(arrayHelpers) => (
                    <div>
                      {values.configs.map((friend: any, index: number) => (
                        <React.Fragment>
                          <Row className="my-2 bg-white p-3">
                            <Col>
                              <FormGroup>
                                <Label className="font-weight-bold">
                                  Farm Stage
                                </Label>
                                <Field
                                  name={`configs[${index}].farmStageId`}
                                  as="select"
                                  className="form-control form-control-lg"
                                  disabled
                                >
                                  <option> Select</option>
                                  {farmingStage.length > 1 &&
                                    farmingStage.map((farm: any, i: any) => (
                                      <option value={farm.id}>
                                        {farm.name.toLowerCase()}
                                      </option>
                                    ))}
                                </Field>

                                <div className="text-danger">
                                  <ErrorMessage name="configs[0].farmStageId" />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className="">
                                <Label className="font-weight-bold">
                                  Start Date
                                </Label>
                                <DatePicker
                                  selected={
                                    new Date(values.configs[index].startDate)
                                  }
                                  dateFormat="MMMM d, yyyy"
                                  className="form-control form-control-lg"
                                  autoComplete="off"
                                  minDate={new Date(date.startDate)}
                                  maxDate={new Date(date.endDate)}
                                  name={`configs[${index}].startDate`}
                                  onChange={(date) =>
                                    setFieldValue(
                                      `configs[${index}].startDate`,
                                      date
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  <ErrorMessage name="configs[0].validations" />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className="">
                                <Label className="font-weight-bold">
                                  End Date
                                </Label>
                                <DatePicker
                                  selected={
                                    new Date(values.configs[index].endDate)
                                  }
                                  dateFormat="MMMM d, yyyy"
                                  className="form-control form-control-lg"
                                  autoComplete="off"
                                  minDate={
                                    new Date(values.configs[index].startDate)
                                  }
                                  maxDate={new Date(date.endDate)}
                                  name={`configs[${index}].endDate`}
                                  onChange={(date) =>
                                    setFieldValue(
                                      `configs[${index}].endDate`,
                                      date
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  <ErrorMessage name="configs[0].endDate" />
                                </div>
                              </FormGroup>
                            </Col>
                            {/* <Button
                              close
                              className="text-danger"
                              onClick={() => arrayHelpers.remove(index)}
                            /> */}
                          </Row>
                        </React.Fragment>
                      ))}
                      {/* <Button
                        color="success"
                        type="button"
                        className="btn-create px-4 py-2 shadow font-weight-bold bg-or"
                        onClick={() =>
                          arrayHelpers.push({
                            farmStageId: "",
                            startDate: new Date(),
                            endDate: new Date(),
                          
                          })
                        }
                      >
                        Add
                      </Button> */}
                    </div>
                  )}
                />

                <div className="p-3 text-center">
                  <Link
                    to={{
                      pathname: `/season-form/${match.params.id}`,
                      state: {
                        seasonData: seasonData,
                      },
                    }}
                  >
                    <Button
                      color="success"
                      className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    color="success"
                    type="submit"
                    className="btn-create px-4 py-2 shadow font-weight-bold"
                    disabled={!dirty || isSubmitting}
                  >
                    Update
                    {isSubmitting ? <FaSpinner className="fa-spin" /> : null}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Create_crop_calender;
