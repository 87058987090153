import { ApiClient } from "../Constants";
import {
  farmingStageType,
  farmingStageTypeResponse,
  incidenceType,
  associationType,
  cropTypeType,
  SupplierType,
  PurchaseOrder,
  PurchaseOrderRequest,
  InventoryResponse,
  InputDeliveryRequest,
  InputDistributionRequest
} from "../models/config.model";

export const configService = {
  getAllSeasonTypes,
  getAllRoles,
  getAllFarmingStage,
  getAllincidence,
  getAllCroptype,
  getAllAssociation,
  createAssociation,
  editAssociation,
  enableAssociation,
  disableAssociation,
  getOneAssociation,
  createcropType,
  editcropType,
  enablecropType,
  disablecropType,
  getOnecropType,
  createfarmingStages,
  editfarmingStages,
  enablefarmingStages,
  disablefarmingStages,
  getOnefarmingStages,
  createincidence,
  editincidence,
  enableincidence,
  disableincidence,
  getOneincidence,
  getAllState,
  getSeasonFarmingStage,
  editfarmingStagesDuplicate,
  createSupplier,
  getAllSuppliers,
  getOneSupplier,
  editSupplier,
  createPurchaseOrder,
  getAllPurchaseOrder,
  editPurchaseOrder,
  getOnePurchaseOrder,
  inputDistribution,
  inputDelivery,
  getAllInputDeliveries,
  getInventoriesByWarehouse,
  getPurcahseOrderByState,
  getInputDeliveryByState,
  getInputDistributionByState,
  
};

function getAllSeasonTypes() {
  return ApiClient.get("/farms/get-all-season-type", "");
}
function getAllRoles() {
  return ApiClient.get("/users/roles", "");
}
function getAllState() {
  return ApiClient.get("/users/states", "");
}
function getAllFarmingStage() {
  return ApiClient.get("/farms/get-all-farmstage", "");
}
function getSeasonFarmingStage(id:any) {
  return ApiClient.get(`/clusters/season-farmstages/${id}`, "");
}

function getAllincidence() {
  return ApiClient.get("/farms/get-all-incidents", "");
}
function getAllCroptype() {
  return ApiClient.get("/farms/get-all-crops", "");
}
function getAllAssociation() {
  return ApiClient.get("/users/get-all-association", "");
}

// association
function createAssociation(body: associationType) {
  return ApiClient.post("/users/create-association", body);
}
function editAssociation(id: string, body: any) {
  return ApiClient.patch(`/users/edit-association/${id}`, body);
}
function enableAssociation(id: string, body: any) {
  return ApiClient.get("/users/roles", body);
}
function disableAssociation(id: string, body: any) {
  return ApiClient.get("/users/roles", body);
}
function getOneAssociation(id: string) {
  return ApiClient.get(`/users/get-association/${id}`, "");
}

// cropType
function createcropType(body: cropTypeType) {
  return ApiClient.post("/farms/create-crop", body);
}
function editcropType(id: string, body: cropTypeType) {
  return ApiClient.put(`/farms/edit-crop/${id}`, body);
}
function enablecropType(id: number) {
  return ApiClient.patch(`/farms/enable-crop/${id}`, "");
}
function disablecropType(id: number) {
  return ApiClient.patch(`/farms/disable-crop/${id}`, "");
}
function getOnecropType(id: string) {
  return ApiClient.get(`/farms/get-crop/${id}`, "");
}

//farmingStages
function createfarmingStages(body: farmingStageType) {
  return ApiClient.post("/farms/create-farm-stage", body);
}
function editfarmingStages(id: string, body: farmingStageTypeResponse) {
  return ApiClient.put(`/farms/edit-farm-stage/${id}`, body);
}
function editfarmingStagesDuplicate(id: string, body: farmingStageTypeResponse) {
  return ApiClient.post(`/farms/edit-farm-stage/${id}`, body);
}
function enablefarmingStages(id: number) {
  return ApiClient.patch(`/farms/enable-farmstage/${id}`, "");
}
function disablefarmingStages(id: number) {
  return ApiClient.patch(`/farms/disable-farmstage/${id}`, "");
}
function getOnefarmingStages(id: string) {
  return ApiClient.get(`/farms/get-farmstage/${id}`, "");
}

//incidence

function createincidence(body: incidenceType) {
  return ApiClient.post("/farms/create-incident", body);
}
function editincidence(id: string, body: incidenceType) {
  return ApiClient.put(`/farms/edit-incident/${id}`, body);
}
function enableincidence(id: number) {
  return ApiClient.patch(`/farms/enable-incident/${id}`, "");
}
function disableincidence(id: number) {
  return ApiClient.patch(`/farms/disable-incident/${id}`, "");
}
function getOneincidence(id: string) {
  return ApiClient.get(`/farms/get-incident/${id}`, "");
}


function createPurchaseOrder(body: PurchaseOrder) {
  return ApiClient.post("/purchase-orders", body);
}

function getAllPurchaseOrder() {
  return ApiClient.get("/purchase-orders", "");
}

function editPurchaseOrder(id: string, body: any) {
  return ApiClient.put(`/purchase-orders/${id}`, body)
}

function getOnePurchaseOrder(id: number) {
  return ApiClient.get(`/purchase-orders/get-purchaseOrder/${id}`, "");
}
function createSupplier(body: SupplierType) {
  return ApiClient.post("/suppliers", body);
}

function getAllSuppliers() {
  return ApiClient.get("/suppliers", "");
}


function getOneSupplier(id: string) {
  return ApiClient.get(`/suppliers/get-supplier/${id}`, "");
}



function editSupplier(id: string, body: any) {
  return ApiClient.put(`/suppliers/${id}`, body)
}

function inputDistribution(body: InputDistributionRequest) {
  return ApiClient.post("/purchase-orders/distribute-inputs", body);
}


function inputDelivery(body: InputDeliveryRequest) {
  return ApiClient.post("/purchase-orders/create-input-delivery", body);
}


function getAllInputDeliveries() {
  return ApiClient.get("/purchase-orders/get-input-deliveries", "");
}

function getInventoriesByWarehouse(warehouseUniqueId: string) {
  return ApiClient.get(`/purchase-orders/inventories-by-warehouse/${warehouseUniqueId}`, "");
}


function getPurcahseOrderByState(stateId: number) {
  return ApiClient.get(`/purchase-orders/purchase-orders-by-state/${stateId}`, "");
}


function getInputDeliveryByState(stateId: any, pageNumber: number = 1, pageSize: number = 10) {
  return ApiClient.get(`/purchase-orders/get-input-deliveries-by-state/${stateId}`, {
    params: {
      PageNumber: pageNumber,
      PageSize: pageSize
    }
  });
}

function getInputDistributionByState(stateId: any, pageNumber: number = 1, pageSize: number = 10) {
  return ApiClient.get(`/purchase-orders/get-input-distributions-by-state/${stateId}`, {
    params: {
      PageNumber: pageNumber,
      PageSize: pageSize
    }
  });
}
