import React from "react";
import { Link, useLocation, RouteComponentProps } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import AppBreadcrumb from "../../../../../components/layout/breadcrumb/Breadcrumb";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmTemplate } from "../../../../../components/confirm";
import { useAlert } from "react-alert";
import { PageBody } from "../../../../../components/Loader";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { RootState } from "../../../../../redux/store/rootReducer";
import { seasonThunk } from "../../../../../redux/action/season/season.thunk";
import { seasonService } from "../../../../../Services";
import { seasonUpload } from "../../../../../redux/actionTypes";
import moment from "moment";
import { saveAs } from "file-saver";
import * as signalR from "@microsoft/signalr";
import Layout from "../../../../../components/Layout";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { roless } from "../../../../../Constants";
import { AuthContext } from "../../../../../context/authContext";

const localizer = momentLocalizer(moment);

const Season_form_answer = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { role } = React.useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const location = useLocation();
  const { seasonData } = location.state;
  const [answers, setAnswers] = React.useState<any>([]);
  

  const getanswers = async (query: number) => {
    updateLoader(true, "Fetching Data");
    const fields = await seasonService.getSeasonFormField(match.params.id);

    const bd = {
      pageNumber: query,
      pageSize: 20,
      seasonId: match.params.id,
      clusterColumns: [...fields.data.response],
    };
    const response = await seasonService.getAllCluster(bd);
    console.log(response.data.data);
    setAnswers(response.data.data);
    updateLoader(false, "");
  };

  React.useEffect(() => {
    getanswers(1);
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container className="mb-4">
          <Row>
            <Col md={9}>
              <Link
                // to="/"
                to={{
                  pathname: `/manage-season/${match.params.id}`,
                  state: {
                    seasonData: seasonData,
                  },
                }}
              >
                <Button className=" px-4 py-2 shadow btn-download  ml-4" inline>
                  Back
                </Button>
              </Link>

              <span className="ml-5">
                <Input
                  placeholder="search"
                  type="search"
                  className="form-search"
                />
              </span>
            </Col>
            <Col md={3} className="text-right">
              {" "}
            </Col>
          </Row>
          {/* <Col className="my-5">
            <Calendar
              localizer={localizer}
              events={dummyEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
            />
          </Col> */}
          <Row className="mt-4">
            <Table hover responsive>
              <thead>
                <h5>All Form Question and Answer</h5>
                <tr className="shadow">
                  <th>S/N</th>
                  <th>Farm Stage</th>
                  <th>Enumerator</th>

                  <th> Question</th>
                  <th>Answer</th>
                  <th> Date</th>
                </tr>
              </thead>
              <tbody>
                {answers?.clusterData?.data.length < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  answers?.clusterData?.data.map((h: any, i: any) =>
                    Object.entries(h).map(([qa, ans]: any, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{qa}</td>
                        <td>{ans}</td>
                      </tr>
                    ))
                  )
                )}
              </tbody>
            </Table>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Season_form_answer;
