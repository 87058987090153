import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";
// import { AuthContext } from "../Context/AuthContext";
import { FullPageLoader } from "./Loader";
import { useAlert } from "react-alert";
import { FaWindows } from "react-icons/fa";

export interface Dt extends React.ComponentProps<any> {
  isPrivate: boolean;
  roles?:string[]
}

export const SpecialRoute = ({ isPrivate, roles, ...props }: Dt) => {
  const { loading, ready, user, role } = React.useContext(AuthContext);
  const location = useLocation();
  const alert = useAlert();

  // fetchData()
  if (loading || !ready) {
    return <FullPageLoader isLoading={loading} message="Loading" />;
  }
  
  if (role !== null) {

    const roletype = roles?.includes(role);
    if (roletype === false) {
      alert.error("You are not allowed access to that page");
      return (
        <Redirect
          to={{
            pathname:"/dashboard",
            state: { ...location.state },
          }}
        />
      );
    }
  }

  // if(!user && isPrivate || token == undefined && isPrivate ){
  //   console.log("hello")
  //   // window.location.reload()
  //   // return (
  //   //   <Redirect
  //   //     to={{
  //   //       pathname:"/",
  //   //       // state: { ...location.state },
  //   //     }}
  //   //   />
  //   // );
  // }

  if (user && !isPrivate) {
    return (
      <Redirect
        to={{
          pathname:
            location.pathname !== "/" ? location.pathname : "/dashboard",
          state: { ...location.state },
        }}
      />
    );
  } else {
    return <Route {...props} />;
  }
};

const AuthedRoute =
  (isPrivate: boolean) => (props: React.ComponentProps<any>) =>
    <SpecialRoute {...{ isPrivate, ...props }} />;

export const InternalRoute = AuthedRoute(true);
export const ExternalRoute = AuthedRoute(false);
