import React from "react";
import { Button, Col, Container, FormGroup, Row, Label } from "reactstrap";
import FormTextInput from "../../../../components/form/text-input";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/rootReducer";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { PageBody } from "../../../../components/Loader";
import { ResponsecropTypeType } from "../../../../models/config.model";
import { configService } from "../../../../Services";
import { configThunk } from "../../../../redux/action/config/config.thunk";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import Layout from "../../../../components/Layout";

interface FormValues {
  associationName: string;
  cropId: string;
  presidentName: string;
  phoneNumber: string;
}
const initialValues: FormValues = {
  associationName: "",
  cropId: "",
  presidentName: "",
  phoneNumber: "",
};

const Edit_association = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const { cropType } = useSelector((state: RootState) => state.cropType);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const phoneValidate =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [assocaite, setassocaite] = React.useState<FormValues>(initialValues);

  const getcrop = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(configThunk.getAllCropType(cropType));
  }, [cropType]);

  const getAssociation = async () => {
    const response = await configService.getOneAssociation(match.params.id);
    console.log(response);
    setassocaite(response.data.data);
    updateLoader(false, "");
  };

  const initialValuess: FormValues = {
    associationName: assocaite.associationName,
    cropId: assocaite.cropId,
    presidentName: assocaite.presidentName,
    phoneNumber: assocaite.phoneNumber,
  };
  React.useEffect(() => {
    getcrop();
    getAssociation();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Config">
        <Container>
          <Row>
            <Col>
              <Formik
                initialValues={initialValuess}
                validationSchema={Yup.object({
                  associationName: Yup.string().required("Name is required"),
                  cropId: Yup.string().required(" Crop is required"),
                  presidentName: Yup.string().required(
                    " President name required"
                  ),
                  phoneNumber: Yup.string()
                    .required("phone number is a required field")
                    .max(11, "phone number must be 14 digits maximum")
                    .min(10, "phone number must be 11 digits minimum")
                    .matches(phoneValidate, "Phone number is not valid"),
                })}
                onSubmit={async (values, actions) => {
                  console.log({ values, actions });

                  actions.setSubmitting(true);
                  try {
                    const response = await configService.editAssociation(
                      match.params.id,
                      values
                    );
                    alert.success("successfully updated");
                    history.push("/config");
                    actions.setSubmitting(false);
                  } catch (error: any) {
                    alert.error(error.response.data.message);
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  values,
                  dirty,
                }) => (
                  <Form>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Association Name{" "}
                            <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            label="Name"
                            placeholder="enter name"
                            name="associationName"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.associationName && errors.associationName ? (
                            <div className="text-danger">
                              {errors.associationName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Crop <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            name="cropId"
                            as="select"
                            className="form-control form-control-lg"
                          >
                            <option> Select</option>
                            {cropType.map((crp: ResponsecropTypeType, i) => (
                              <option value={crp.id}>{crp.name}</option>
                            ))}
                          </Field>
                          {touched.cropId && errors.cropId ? (
                            <div className="text-danger">{errors.cropId}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            President Name{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            type="text"
                            label="President"
                            placeholder="enter president's name"
                            name="presidentName"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.presidentName && errors.presidentName ? (
                            <div className="text-danger">
                              {errors.presidentName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            President Number{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            type="text"
                            label="President's Phone"
                            placeholder="enter phone number"
                            name="phoneNumber"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.phoneNumber && errors.phoneNumber ? (
                            <div className="text-danger">
                              {errors.phoneNumber}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="p-3 text-center">
                      <Link to="/config">
                        <Button
                          color="success"
                          className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        color="success"
                        className="btn-create px-4 py-2 shadow font-weight-bold"
                        disabled={!dirty}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Edit_association;
