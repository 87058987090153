import * as React from "react";
import { Col, Container, Row } from "reactstrap";
import Sidebar from "./layout/sidebar/Sidebar";
import * as signalR from "@microsoft/signalr";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { FaUserFriends } from "react-icons/fa";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import logo from "../assets/img/Needle_logo.png";
import { Button } from "reactstrap";
import { AuthContext } from "../context/authContext";
import { useLoadingMessage } from "./useLoadingMessage";
import { useState } from "react";
import { usersThunk } from "../redux/action/users/users.thunk";

type Props = {
  active: string;
  children?: JSX.Element | JSX.Element[];
};

const Layout: React.FC<Props> = ({ active, children }) => {
  // const { users }: any = useSelector((state: RootState) => state.users);
  const { role } = React.useContext(AuthContext);

  const [report, setReport] = React.useState("");
  const alert = useAlert();
  const { activeBar, isLoading } = useLoadingMessage();
  const { logout } = React.useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);

  const toggleM = () => {
    setShowMenu(showMenu ? false : true);
  };

  // React.useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("https://abp.remsys.com.ng/notify", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    newConnection
      .start()
      .then((result) => {
      })
      .catch((e) => console.log("Connection failed: ", e));

    newConnection.on("Progress", (data) => {
      setReport(data);
      const d2 = data.replace(/\s/g, "");
      if (d2 == "UploadCompleted") {
        alert.success(data);
        setReport("");
      }
    });
  // }, []);

  return (
    <React.Fragment>
      <div className="real">
        <div
          className="sidebar-toggle shadow"
          id="m-tog"
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="py-1 px-2 text-white h3">
            <GiHamburgerMenu />
          </div>
        </div>
        <div className="cont-div">
          <aside
            className="sidebar"
            style={{ left: showMenu ? "-0px" : "-280px" }}
          >
            {" "}
            <div className="logo-section bg-white text-center pt-4">
              <img src={logo} width="60%" alt="logo" />
            </div>
            <Link
              // className={
              //   !isLoading && activeBar == "Dashboard"
              //     ? `active-m text-white`
              //     : "text-white"
              // }
              className={active === "Dashboard" ? "active-m" : ""}
              to="/dashboard"
            >
              <span className="">
                {" "}
                <IoHome className="dash-icon" />
              </span>
              Dashboard
            </Link>
            <Link
              to="/season"
              className={active === "Seasons" ? "active-m" : ""}
            >
              <span className="">
                {" "}
                <TiWeatherPartlySunny className="dash-icon" />
              </span>{" "}
              Season
            </Link>
            <Link to="/users" className={active === "Users" ? "active-m" : ""}>
              <span className="">
                {" "}
                <FaUserFriends className="dash-icon" />
              </span>{" "}
              User
            </Link>
            <Link
              to="/config"
              className={active === "Config" ? "active-m" : ""}
            >
              <span className="">
                {" "}
                <MdOutlineSettingsSuggest className="dash-icon" />
              </span>{" "}
              Config
            </Link>
            <div className="logout-div   mb-5 ">
              <span className="admin-span text-white font-weight-bold">
                <FaUser className="admin-icon" />
                <small> {role}</small>
              </span>
              <Button onClick={logout} className="logout-btn shadow border-0 ">
                Logout
              </Button>
            </div>
          </aside>
        </div>

        <main className="content">
          <h6>{report}</h6>
          {children}
        </main>
      </div>
    </React.Fragment>
  );
};

export default Layout;
