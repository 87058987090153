import { configService } from "../../../../../Services/configService";
import { match } from "react-router";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { seasonService } from "../../../../../Services/seasonService";
import { AxiosResponse } from "axios";
import {
  PurchaseOrderData,
  PurchaseOrderResponseModel,
  PaginationMeta,
  InputDelivery,
InputDeliveryResponse,
Distribution,
PaginationSecondMeta
} from "../../../../../models/config.model";


// const { isLoading, message, updateLoader } = useLoadingMessage();

export const getAllInputDeliveryForState = async (
  stateId: string,
  pageNumber: number = 1,
  pageSize: number = 20,
  
) => {
  // updateLoader(true, "Fetching Input Delivery");
  try {
    const response: AxiosResponse<InputDeliveryResponse> = await configService.getInputDeliveryByState(
      stateId,
      pageNumber,
      pageSize
    );
    if (response.data) {
      const { meta, data } = response.data;
      return { data, meta };
    }
  } catch (error) {
    console.error("Error fetching input delivery data:", error);
  } finally {
    // updateLoader(false, "");
  }
  return null;
};

export const getInputDistributionByState = async (
  stateId: string,
  pageNumber: number = 1,
  pageSize: number = 20
) => {
  // updateLoader(true, "Fetching Input Distributions");
  try {
    const response = await configService.getInputDistributionByState(
      stateId,
      pageNumber,
      pageSize
    );
    if (response.data) {
      const { meta, data } = response.data;
      return { data, meta };
    }
  } catch (error) {
    console.error("Error fetching input distributions:", error);
  } finally {
    // updateLoader(false, "");
  }
  return null;
};

export const getclustersWithState = async (id: string, stateId: string, query: number) => {
 
  try {
    const fields = await seasonService.getAllField(id);
    const requestData = {
      pageNumber: query,
      pageSize: 20,
      seasonId: id,
      stateId: stateId,
      clusterColumns: [...fields.data.data],
    };
    const response = await seasonService.getAllStateCluster(requestData);
    if (Array.isArray(response.data.data.clusterData.data)) {
      return response.data.data.clusterData.data.map((cluster: any) => ({
        value: cluster.uniqueId,
        label: cluster.clustername,
      }));
    }
  } catch (error) {
    console.error("Error fetching clusters:", error);
  } finally {
  
  }
  return [];
};


export const getWarehousesWithState = async (
  stateId: string,
  pageNumber: number = 1,
  pageSize: number = 20,

) => {
 
  try {
    const response = await seasonService.getAllWarehouseWithState(
      stateId,
      pageNumber,
      pageSize
    );
    if (Array.isArray(response?.data?.data?.warehouseData?.data)) {
      return response.data.data.warehouseData.data.map((warehouse: any) => ({
        label: warehouse["werehouse name"],
        value: warehouse["uniqueId"],
        managerId: warehouse["managerId"],
      }));
    }
  } catch (error) {
    console.error("Error fetching warehouses:", error);
  } finally {
    
  }
  return [];
};