import { userService } from "../../../Services/userService";
import { usersType } from "../../actionTypes";
import { userType } from "../../../models/user.model";
import { Dispatch } from "redux";

export class usersThunk {
  static getAllUsers() {
    return async (dispatch: Dispatch) => {
      try {
        const response = await userService.getAll();

        if (response.data.data) {
          dispatch({
            type: usersType.GET_ALL_USERS,
            payload: response.data,
          });
          return;
        }
        throw new Error("user creation failed");
      } catch (error) {}
    };
  }

  static getAllUsersActivity() {
    return async (dispatch: Dispatch) => {
      try {
        const response = await userService.getActivity();
        console.log("hh", response);

        if (response.data.data) {
          dispatch({
            type: usersType.GET_ALL_ACTIVITY,
            payload: response.data,
          });
          return;
        }
        throw new Error("user creation failed");
      } catch (error) {}
    };
  }

  static getAllUsersQuery(query: string) {
    return async (dispatch: Dispatch) => {
      try {
        const response = await userService.getAllQuery(query);

        if (response.data.data) {
          dispatch({
            type: usersType.GET_ALL_USERS,
            payload: response.data,
          });
          return;
        }

        throw new Error("user creation failed");
      } catch (error) {}
    };
  }
}
