import React from "react";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import FormTextInput from "../../../../components/form/text-input";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { configService } from "../../../../Services";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import Layout from "../../../../components/Layout";

interface FormValues {
  name: string;
  isActive: boolean;
}

const Create_crop = () => {
  const alert = useAlert();
  const history = useHistory();
  const initialValues: FormValues = { name: "", isActive: true };

  return (
    <Layout active="Config">
      <Container>
        <Row>
          <Col>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                name: Yup.string().required("Required"),
              })}
              onSubmit={async (values, actions) => {
                console.log({ values, actions });

                actions.setSubmitting(true);
                try {
                  const response = await configService.createcropType(values);
                  console.log(response);
                  if (response.data.data) {
                    alert.success("successfully created");
                    history.push("/config");
                  }
                  actions.setSubmitting(false);
                } catch (error: any) {
                  alert.error(error.response.data.message);
                  actions.setSubmitting(false);
                }
              }}
            >
              {({ errors, touched, isSubmitting, handleChange, values }) => (
                <Form>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="">
                        <Label className="font-weight-bold">
                          Crop Name{" "}
                          <span className="text-danger fw-bold">*</span>{" "}
                        </Label>
                        <Field
                          type="text"
                          placeholder="enter name"
                          name="name"
                          className="form-control form-control-lg"
                        />
                        {touched.name && errors.name ? (
                          <div className="text-danger">{errors.name}</div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="p-3 text-center">
                    <Link to="/config">
                      <Button
                        color="success"
                        className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      type="submit"
                      color="success"
                      className="btn-create px-4 py-2 shadow font-weight-bold"
                      disabled={isSubmitting}
                    >
                      Create
                      {isSubmitting ? <FaSpinner className="fa-spin" /> : null}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Create_crop;
