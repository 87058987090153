import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { configThunk } from "../../../redux/action/config/config.thunk";
import { RootState } from "../../../redux/store/rootReducer";
import AppBreadcrumb from "../../../components/layout/breadcrumb/Breadcrumb";
import { PageBody } from "../../../components/Loader";
import { useLoadingMessage } from "../../../components/useLoadingMessage";
import moment from "moment";
import {
  ResponsefarmingStageType,
  ResponseincidenceType,
  ResponsecropTypeType,
  ResponseAssociation,
  ResponseSupplier,
} from "../../../models/config.model";
import { configService } from "../../../Services";
import { ConfirmTemplate } from "../../../components/confirm";
import { useAlert } from "react-alert";
import Layout from "../../../components/Layout";

import { AuthContext } from "../../../context/authContext";
import { roless } from "../../../Constants";

const Config = () => {
  const { role } = useContext(AuthContext);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  // const actTab = window.localStorage.getItem("tabs");
  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  {
    window.localStorage.getItem("config") === null &&
      window.localStorage.setItem("config", JSON.stringify("1"));
  }
  const alert = useAlert();

  const { allSeasonType } = useSelector(
    (state: RootState) => state.seasonsType
  );
  const { roles } = useSelector((state: RootState) => state.roles);
  const { cropType } = useSelector((state: RootState) => state.cropType);
  const { incidence } = useSelector((state: RootState) => state.incidence);
  const { association } = useSelector((state: RootState) => state.association);
  const { suppliers } = useSelector((state: RootState) => state.suppliers);
  const { farmingStage } = useSelector(
    (state: RootState) => state.farmingStage
  );
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const storeSelection = (selection: any) =>
    localStorage.setItem("tabSelection", selection);

  const dispatch = useDispatch();

  const getroles = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(configThunk.getAllSeasonType(allSeasonType));
    await dispatch(configThunk.getAllRoles(roles));
    await dispatch(configThunk.getAllFarmingStage(farmingStage));
    await dispatch(configThunk.getAllCropType(cropType));
    await dispatch(configThunk.getAllAssociation(association));
    await dispatch(configThunk.getAllIncidence(incidence));
    await dispatch(configThunk.getAllSuppliers(suppliers));
    updateLoader(false, "");
  }, [allSeasonType, roles, farmingStage, cropType, association, incidence, suppliers]);

  const onDisabledFarmStage = (id: number, status: boolean) => {
    const request = async () => {
      try {
        updateLoader(true, "Status Changing");

        if (!status) {
          const response = await configService.enablefarmingStages(id);
          if (response.status === 200) {
            alert.success(" Farm stage successfully activated");
          } else {
            alert.error("error");
          }
        } else {
          const response = await configService.disablefarmingStages(id);
          if (response.status === 200) {
            alert.success("Farm stage successfully deactivated");
          } else {
            alert.error("error");
          }
        }
        getroles();
        updateLoader(false, "");
      } catch (e: any) {
        alert.error(e.response.data.message);
        getroles();
        updateLoader(false, "");
      }
    };

    ConfirmTemplate(
      {
        title: "Changing Status ",
        message: "Are You Sure You Want To Change the Status",
      },
      request
    );
  };

  // Disable association
  // const onDisabledAssociation = (id: number, status: boolean) => {
  //   const request = async () => {
  //     updateLoader(true, "Status Changing");
  //     const data = {
  //       status: !status,
  //     };
  //     if (!status) {
  //       const response = await configService.enableAssociation(id);
  //       if (response.status === 200) {
  //         alert.success("association successfully enabled");
  //       } else {
  //         alert.error("error");
  //       }
  //     } else {
  //       const response = await configService.disableAssociation(id);
  //       if (response.status === 200) {
  //         alert.success(" Association successfully disabled");
  //       } else {
  //         alert.error("error");
  //       }
  //     }
  //     getroles();
  //     updateLoader(false, "");
  //   };

  //   ConfirmTemplate(
  //     {
  //       title: "Changing Status ",
  //       message: "Are You Sure You Want To Change the Status",
  //     },
  //     request
  //   );
  // };

  //diasbled incidence
  const onDisabledIncidence = (id: number, status: boolean) => {
    const request = async () => {
      updateLoader(true, "Status Changing");
      const data = {
        status: !status,
      };
      if (!status) {
        const response = await configService.enableincidence(id);
        if (response.status === 200) {
          alert.success("incidence successfully activated");
        } else {
          alert.error("error");
        }
      } else {
        const response = await configService.disableincidence(id);
        if (response.status === 200) {
          alert.success("incidence successfully deactivated");
        } else {
          alert.error("error");
        }
      }
      getroles();
      updateLoader(false, "");
    };

    ConfirmTemplate(
      {
        title: "Changing Status ",
        message: "Are You Sure You Want To Change the Status",
      },
      request
    );
  };

  const [search, setSearch] = React.useState("");

  const farmingStages =
    farmingStage &&
    farmingStage.filter((farmingS: any) => {
      return (
        farmingS.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        farmingS.farmCategory.name
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      );
    });

  //disabled crop
  const onDisabledCrop = (id: number, status: boolean) => {
    const request = async () => {
      try {
        updateLoader(true, "Status Changing");

        if (!status) {
          const response = await configService.enablecropType(id);
          if (response.status === 200) {
            alert.success(" crop successfully activated");
          } else {
            alert.error("error");
          }
        } else {
          const response = await configService.disablecropType(id);
          if (response.status === 200) {
            alert.success("crop successfully deactivated");
          } else {
            alert.error("error");
          }
        }
        getroles();
        updateLoader(false, "");
      } catch (e: any) {
        alert.error(e.response.data.message);
        getroles();
        updateLoader(false, "");
      }
    };

    ConfirmTemplate(
      {
        title: "Changing Status ",
        message: "Are You Sure You Want To Change the Status",
      },
      request
    );
  };

  React.useEffect(() => {
    getroles();
    const actTab: any = window.localStorage.getItem("config");
    setCurrentActiveTab(JSON.parse(actTab));
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem("config", JSON.stringify(currentActiveTab));
  });

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Config">
        <Row>
          <Container className="mb-4">
            <Row>
              <Col md={9}>
                {/* <span className="ml-5">
                <Input
                  placeholder="search"
                  type="search"
                  className="form-search"
                />
              </span> */}
              </Col>
              <Col md={3} className="text-right">
                {" "}
                {/* <AppBreadcrumb /> */}
              </Col>
            </Row>
          </Container>
          <Col sm={12} lg={12}>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                    // onSelect={{this.storeSelection("1")}}
                    onSelect={(k: any) => setCurrentActiveTab(k)}
                  >
                    Season Type
                  </NavLink>
                </NavItem>
                {roless.BusinessAd == role || roless.ITAd == role ? (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Role
                    </NavLink>
                  </NavItem>
                ) : null}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Farming Stage
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "4",
                    })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Incidence
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "5",
                    })}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Crop Type
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "6",
                    })}
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    Association
                  </NavLink>
                </NavItem>
                 <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "7",
                    })}
                    onClick={() => {
                      toggle("7");
                    }}
                  >
                    Supplier
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12" className="mt-5">
                      <Table hover responsive>
                        <thead>
                          <tr className="shadow">
                            <th>S/N</th>
                            <th> SEASON TYPE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allSeasonType.length < 1 ? (
                            <div className="text-center m-auto">
                              <h1>No record found</h1>
                            </div>
                          ) : (
                            allSeasonType.map((season, i) => (
                              <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{season}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12" className="mt-5">
                      <Table hover responsive>
                        <thead>
                          <tr className="shadow">
                            <th>S/N</th>
                            <th> ROLE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {roles.length < 1 ? (
                            <div className="text-center m-auto">
                              <h1>No record found</h1>
                            </div>
                          ) : (
                            roles.map((role, i) => (
                              <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{role}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12" className="mt-4">
                      <Row className="my-5">
                        <Col md={9}>
                          {roless.BusinessAd == role || roless.ITAd == role ? (
                            <Link to="/create-farm-stage">
                              <Button
                                className="btn-create px-4 py-2 shadow"
                                inline
                              >
                                Create Farming Stage
                              </Button>
                            </Link>
                          ) : null}

                          <span className="ml-5">
                            <Input
                              placeholder="search"
                              type="search"
                              className="form-search"
                              name="search"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </span>
                        </Col>
                        <Col md={3} className="text-right"></Col>
                      </Row>

                      <Table hover responsive>
                        <thead>
                          <tr className="shadow">
                            <th>S/N</th>
                            <th> NAME</th>
                            <th> CATEGORY</th>
                            <th>STATUS</th>
                            <th>DATE</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {farmingStages.length < 1 ? (
                            <div className="text-center m-auto">
                              <h1>No record found</h1>
                            </div>
                          ) : (
                            farmingStages.map((farmingS: any, i) => (
                              <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{farmingS.name}</td>
                                <td>{farmingS.farmCategory.name}</td>
                                <td>
                                  {" "}
                                  {farmingS.status === true ? (
                                    <label
                                      style={{
                                        color: "green",
                                      }}
                                    >
                                      Active
                                    </label>
                                  ) : null}{" "}
                                  {farmingS.status === false ? (
                                    <label
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      Inactive
                                    </label>
                                  ) : null}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {moment(farmingS.createdAt).format("ll")}{" "}
                                </td>
                                <td>
                                  {roless.BusinessAd == role ||
                                  roless.ITAd == role ? (
                                    <div className=" justify-content-center">
                                      <UncontrolledDropdown className="m-drop">
                                        <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                          <HiDotsVertical />
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                          <Link
                                            to={`/edit-farm-stage/${farmingS.id}`}
                                          >
                                            <DropdownItem>Edit</DropdownItem>
                                          </Link>
                                          <Link
                                            to={`/view-farm-stage-question/${farmingS.id}`}
                                          >
                                            <DropdownItem>
                                              View Question
                                            </DropdownItem>
                                          </Link>
                                          <Link
                                            to={`/duplicate-farm-stage/${farmingS.id}`}
                                          >
                                            <DropdownItem>
                                              Duplicate
                                            </DropdownItem>
                                          </Link>
                                          <DropdownItem
                                            onClick={() =>
                                              onDisabledFarmStage(
                                                farmingS.id,
                                                farmingS.status
                                              )
                                            }
                                          >
                                            {farmingS.status
                                              ? "Deactivate"
                                              : "Activate"}
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12" className="mt-4">
                      <Row className="my-5">
                        <Col md={9}>
                          {roless.BusinessAd == role || roless.ITAd == role ? (
                            <Link to="/create-incidence">
                              <Button
                                className="btn-create px-4 py-2 shadow"
                                inline
                              >
                                Create Incidence
                              </Button>
                            </Link>
                          ) : null}
                        </Col>
                        <Col md={3} className="text-right"></Col>
                      </Row>
                      <Table hover responsive>
                        <thead>
                          <tr className="shadow">
                            <th>S/N</th>
                            <th> NAME</th>
                            <th>STATUS</th>
                            <th>DATE</th>

                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {incidence.length < 1 ? (
                            <div className="text-center m-auto">
                              <h1>No record found</h1>
                            </div>
                          ) : (
                            incidence.map((incid: ResponseincidenceType, i) => (
                              <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{incid.name}</td>
                                <td>
                                  {" "}
                                  {incid.isActive === true ? (
                                    <label
                                      style={{
                                        color: "green",
                                      }}
                                    >
                                      Active
                                    </label>
                                  ) : (
                                    "Inactive"
                                  )}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {moment(incid.createdAt).format("ll")}{" "}
                                </td>
                                <td>
                                  {roless.BusinessAd == role ||
                                  roless.ITAd == role ? (
                                    <div className=" justify-content-center">
                                      <UncontrolledDropdown className="m-drop">
                                        <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                          <HiDotsVertical />
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                          <Link
                                            to={`/edit-incidence/${incid.id}`}
                                          >
                                            <DropdownItem>Edit</DropdownItem>
                                          </Link>
                                          <DropdownItem
                                            onClick={() =>
                                              onDisabledIncidence(
                                                incid.id,
                                                incid.isActive
                                              )
                                            }
                                          >
                                            {incid.isActive
                                              ? "Deactivate"
                                              : "Activate"}
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row>
                    <Col sm="12" className="mt-4">
                      <Row className="my-5">
                        <Col md={9}>
                          {roless.BusinessAd == role || roless.ITAd == role ? (
                            <Link to="/create-crop">
                              <Button
                                className="btn-create px-4 py-2 shadow"
                                inline
                              >
                                Create Crop
                              </Button>
                            </Link>
                          ) : null}
                        </Col>
                        <Col md={3} className="text-right"></Col>
                      </Row>
                      <Table hover responsive>
                        <thead>
                          <tr className="shadow">
                            <th>S/N</th>
                            <th> NAME</th>
                            <th>STATUS</th>
                            <th>DATE</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {cropType.length < 1 ? (
                            <div className="text-center m-auto">
                              <h1>No record found</h1>
                            </div>
                          ) : (
                            cropType.map((crop: ResponsecropTypeType, i) => (
                              <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{crop.name}</td>
                                <td>
                                  {" "}
                                  {crop.isActive === true ? (
                                    <label
                                      style={{
                                        color: "green",
                                      }}
                                    >
                                      Active
                                    </label>
                                  ) : (
                                    "Inactive"
                                  )}{" "}
                                </td>

                                <td>{moment(crop.createdAt).format("ll")}</td>
                                <td>
                                  {roless.BusinessAd == role ||
                                  roless.ITAd == role ? (
                                    <div className=" justify-content-center">
                                      <UncontrolledDropdown className="m-drop">
                                        <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                          <HiDotsVertical />
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                          <Link to={`/edit-crop/${crop.id}`}>
                                            <DropdownItem>Edit</DropdownItem>
                                          </Link>
                                          <DropdownItem
                                            onClick={() =>
                                              onDisabledCrop(
                                                crop.id,
                                                crop.isActive
                                              )
                                            }
                                          >
                                            {crop.isActive
                                              ? "Deactivate"
                                              : "Activate"}
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="6">
                  <Row>
                    <Col sm="12" className="mt-4">
                      <Row className="my-5">
                        <Col md={9}>
                          {roless.BusinessAd == role || roless.ITAd == role ? (
                            <Link to="/create-association">
                              <Button
                                className="btn-create px-4 py-2 shadow"
                                inline
                              >
                                Create Association
                              </Button>
                            </Link>
                          ) : null}
                        </Col>
                        <Col md={3} className="text-right"></Col>
                      </Row>
                      <Table hover responsive>
                        <thead>
                          <tr className="shadow">
                            <th>S/N</th>
                            <th> NAME</th>
                            <th>CROP</th>
                            <th> PRESIDENT</th>
                            <th>PRESIDENT NUMBER</th>
                            <th>DATE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {association.length < 1 ? (
                            <div className="text-center m-auto">
                              <h1>No record found</h1>
                            </div>
                          ) : (
                            association.map((asso: ResponseAssociation, i) => (
                              <tr>
                                <th scope="row"> {i + 1} </th>
                                <td> {asso.associationName} </td>
                                <td> {asso.crop.name} </td>
                                <td> {asso.presidentName} </td>
                                <td> {asso.phoneNumber} </td>

                                <td>
                                  {" "}
                                  {moment(asso.dateCreated).format("ll")}{" "}
                                </td>
                                <td>
                                  {roless.BusinessAd == role ||
                                  roless.ITAd == role ? (
                                    <div className=" justify-content-center">
                                      <UncontrolledDropdown className="m-drop">
                                        <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                          <HiDotsVertical />
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                          <Link
                                            to={`/edit-association/${asso.associationId}`}
                                          >
                                            <DropdownItem>Edit</DropdownItem>
                                          </Link>
                                          {/* <DropdownItem
                                            onClick={() =>
                                              onDisabledFarmStage(
                                                asso.associationId,
                                                asso.status
                                              )
                                            }
                                          >
                                            {asso.status
                                              ? "Deactivate"
                                              : "Activate"}
                                          </DropdownItem> */}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
                 <TabPane tabId="7">
                  <Row>
                    <Col sm="12" className="mt-4">
                      <Row className="my-5">
                        <Col md={9}>
                          {roless.BusinessAd == role || roless.ITAd == role ? (
                        
                            <Link to="/create-supplier">
                              <Button
                                className="btn-create px-4 py-2 shadow"
                                inline
                              >
                                Create Spplier
                              </Button>
                            </Link>
                          ) : null}
                        </Col>
                        <Col md={3} className="text-right"></Col>
                      </Row>
                      <Table hover responsive>
                        <thead>
                          <tr className="shadow">
                            <th>S/N</th>
                            <th> NAME</th>
                            <th>EMAIL</th>
                            <th>ADDRESS</th> 
                            <th>PHONE NUMBER</th> 
                             <th>DATE</th> 
                           
                          </tr>
                        </thead>
                        <tbody>
                          {suppliers.length < 1 ? (
                            <div className="text-center m-auto">
                              <h1>No record found</h1>
                            </div>
                          ) : (
                            suppliers.map((supp: ResponseSupplier, i) => (
                              <tr>
                                <th scope="row"> {i + 1} </th>
                                <td> {supp.name} </td>
                                <td> {supp.email} </td>
                                <td> {supp.address} </td>
                                <td> {supp.phoneNumber} </td>

                                <td>
                                  {" "}
                                  {moment(supp.createdAt).format("ll")}{" "}
                                </td>
                                <td>
                                  {roless.BusinessAd == role ||
                                  roless.ITAd == role ? (
                                    <div className=" justify-content-center">
                                      <UncontrolledDropdown className="m-drop">
                                        <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                          <HiDotsVertical />
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                          <Link
                                          //  to={`/edit-association/${asso.associationId}`}
                                            to={`/edit-supplier/${supp.id}`}
                                          >
                                            <DropdownItem>Edit</DropdownItem>
                                          </Link>
                                          {/* <DropdownItem
                                            onClick={() =>
                                              onDisabledFarmStage(
                                                asso.associationId,
                                                asso.status
                                              )
                                            }
                                          >
                                            {asso.status
                                              ? "Deactivate"
                                              : "Activate"}
                                          </DropdownItem> */}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Layout>
    </PageBody>
  );
};

export default Config;
