import React from "react";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";

import { useDropzone } from "react-dropzone";
import {
  Link,
  RouteComponentProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import { constant } from "../../../../../Constants";
import { useAlert } from "react-alert";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/rootReducer";
import { configThunk } from "../../../../../redux/action/config/config.thunk";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Responsestatetype } from "../../../../../models/config.model";
import { seasonService } from "../../../../../Services";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { PageBody } from "../../../../../components/Loader";
import { FaSpinner } from "react-icons/fa";
import { saveAs } from "file-saver";
import Layout from "../../../../../components/Layout";

interface FormValues {
  stateId: string;
}

const Upload_werehouse = ({
  match,
}: RouteComponentProps<{ id: string; uniqueId: any }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();

  const alert = useAlert();
  const history = useHistory();

  const location = useLocation();
  const { seasonData } = location.state;
  console.log("seasonData in werehouse", seasonData);

  const initialValues: FormValues = {
    stateId: "",
  };

  const [files, setFiles] = React.useState([]);

  function fileSizeValidator(file: any) {
    if (file.size > 1024 ** 2 * 2) {
      return {
        code: "size-too-large",
        message: `File is larger than 2mb`,
      };
    }

    return null;
  }

  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      //   accept: "image/jpeg, application/pdf",
      accept: ".csv, application/vnd.ms-excel, text/csv",
      maxFiles: 2,
      validator: fileSizeValidator,
    });

  const { states } = useSelector((state: RootState) => state.states);

  const getStateDropDown = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");

    await dispatch(configThunk.getAllStates(states));
    updateLoader(false, "");
  }, [states]);

  React.useEffect(() => {
    getStateDropDown();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <h5>
            Season: {seasonData.seasonType + " " + seasonData.associationName}
          </h5>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              stateId: Yup.number().required("State is required"),
            })}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              console.log("Selected stateId:", values.stateId); 
              if (files.length < 1) {
                alert.error("You have not selected a file ");
                actions.setSubmitting(false);
              } else {
                const formData = new FormData();
                let file = files[0];
                formData.append("file", file);
                formData.append("upload_preset", "s9n5tgkf");

                try {
                  const response = await seasonService.uploadWerehouse(
                    values.stateId,
                    formData
                  );
                  if (response.status == 200) {
                    alert.success("Upload started successfully");
                    setFiles([]);
                    actions.setSubmitting(false);
                    history.push({
                      pathname: `/all-upload/${match.params.id}`,
                      state: {
                        seasonData: seasonData,
                      },
                    });
                  }
                } catch (error: any) {
                  console.log(error.response.data.message);
                  alert.error(error.response.data.message);
                }
              }
            }}
          >
            {({ errors, touched, isSubmitting, handleChange, values }) => (
              <Form>
                <div className="upload-sec p-4">
                  <div className="bg-green-2 p-5 text-white   rounded ">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p className="text-center text-xl">
                          Drop your media files here
                        </p>
                      ) : (
                        <h3 className="text-center text-xl">
                          Drag and drop file here, <br />{" "}
                          <Button
                            className="bg-white text-dark border-0 shadow mt-5 py-3 px-5"
                            type="button"
                          >
                            Or click here to select files
                          </Button>
                        </h3>
                      )}
                    </div>
                  </div>
                </div>

                {files.length > 0 && (
                  <div className="text-center">
                    {/* <Button
              className="btn btn-success"
              onClick={() => {
                
              }}
            >
              Click here to upload
            </Button> */}
                  </div>
                )}
                {files.map((f: any) => {
                  return (
                    <ul>
                      <li>{f.name}</li>
                    </ul>
                  );
                })}

                {fileRejections.map(({ errors }) => {
                  return (
                    <li>
                      <ul>
                        {errors.map((e) => (
                          <li key={e.code}>{e.message}</li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
                <Row>
                  <Col md={6}>
                    <FormGroup className="">
                      <Field
                        name="stateId"
                        as="select"
                        className="form-control form-control-lg ml-5"
                      >
                        <option> Select</option>
                        {states.map((state: Responsestatetype, i) => (
                          <option value={state.id}>{state.state}</option>
                        ))}
                      </Field>
                      {touched.stateId && errors.stateId ? (
                        <div className="text-danger">{errors.stateId}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    className="btn bg-green-2 border-0 shadow"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Click here to upload{" "}
                    {isSubmitting ? <FaSpinner className="fa-spin" /> : null}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

          <div className="p-3 text-center">
            <Link
              to={{
                pathname: `/all-upload/${match.params.id}`,
                state: {
                  seasonData: seasonData,
                },
              }}
            >
              <Button
                color="success"
                className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
              >
                Cancel
              </Button>
            </Link>
            {/* <Button
            color="success"
            className="btn-create px-4 py-2 shadow font-weight-bold"
            type="submit"
          >
            Upload
          </Button> */}
          </div>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Upload_werehouse;
