import * as React from "react";
import styled from "styled-components";
import { FaCheck} from "react-icons/fa";
import { ImCancelCircle,ImInfo} from "react-icons/im";
import { AlertTemplateProps } from 'react-alert'

// import { Toast } from './Toast'

export const Contain = styled.div`
  width: 300px;
  padding: 20px;
  font-size: 80%;
  background: #ffffffdd;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fffff;
  box-shadow: 0px 18px 32px 0px rgba(76, 147, 255, 0.38);

  > svg {
    flex-shrink: 0;
  }

  * + * {
    margin-left: 10px;
  }

  &.success {
    background: #569d58;
    color: white;
  }

  &.error {
    background: #d9350f;
    color: white;
  }
`;



export  interface METY{
  options:{
    type:string
  },
message:string
}


export const AlertTemplate = ({options,message}:AlertTemplateProps) => {
  return (
    <Contain className={options.type} style={{ marginBottom: 20 }}>
      {options.type === "success" ? (
        <FaCheck />
      ) : options.type === "error" ? (
        <ImCancelCircle />
      ) : (
        <ImInfo />
      )}
      <span>{message}</span>
    </Contain>
  );
};
