import React from "react";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import FormTextInput from "../../../../components/form/text-input";
import { Link } from "react-router-dom";
import { PageBody } from "../../../../components/Loader";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { configService } from "../../../../Services";
import { useAlert } from "react-alert";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { FaSpinner } from "react-icons/fa";
import Layout from "../../../../components/Layout";
import ErrorMessage from "../../../../components/form/error-message";
import { controlType, validations } from "../../../../Constants";
import Select from "react-select";
import DatePicker from "react-datepicker";

interface FormValues {
  name: string;
  farmCategoryId: number;
  // startDate: Date;
  // endDate: Date;
  config: [
    {
      fieldName: string;
      controlType: string;
      validations: [];
      values: string;
      // id: 0,
    }
  ];
}

interface newDatas {
  name: string;
  farmCategory: {
    name: string;
    id: number;
  };
  // startDate: Date;
  // endDate: Date;
  config: [
    {
      fieldName: string;
      controlType: string;
      validations: [];
      values: string;
      // id: 0,
    }
  ];
}

const Duplicate_farm_stage = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const farmstageValues: newDatas = {
    name: "",
    farmCategory: { name: "", id: 0 },
    // startDate: new Date(),
    // endDate: new Date(),
    config: [
      {
        fieldName: "",
        controlType: "",
        validations: [],
        values: "",
        // id: 0,
      },
    ],
  };

  const [farmstag, setfarmstag] = React.useState<newDatas>(farmstageValues);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const alert = useAlert();

  const getIncident = async () => {
    updateLoader(true, "Fetching Farm Stages");
    const response = await configService.getOnefarmingStages(match.params.id);
    response.data.data.config.map((stg: any, i: any) => {
      for (let i = 0; i < stg.validations.length; i++) {
        stg.validations[i].label = stg.validations[i].validation;
        stg.validations[i].value = stg.validations[i].validation;
      }
    });

    console.log(response.data.data);
    setfarmstag(response.data.data);
    updateLoader(false, "");
  };

  const initialValues: FormValues = {
    name: "",
    farmCategoryId: 0,
    // startDate: farmstag.startDate ? farmstag.startDate : new Date(),
    // endDate: farmstag.endDate ? farmstag.endDate : new Date(),
    config: farmstag.config && farmstag.config,
  };

  React.useEffect(() => {
    getIncident();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Config">
        <Container>
          <Row>
            <Col>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  name: Yup.string().required("Name is required"),
                  farmCategoryId: Yup.number()
                    .min(1, "please select from the options")
                    .required("Farm category is required"),
                  config: Yup.array().of(
                    Yup.object().shape({
                      fieldName: Yup.string().required("name is  required"),
                      validations: Yup.array()
                        .min(1, "Validations is Required")
                        .required("Validations is Required"),
                      controlType: Yup.string().required(
                        "Control type is required"
                      ),
                      // quantity: Yup.string().required("Quantity is required"),
                      // unitPrice: Yup.string().required("Unit price  is required"),
                      // quantityType: Yup.string().required(
                      //   "Quantity type is required"
                      // ),
                    })
                  ),
                })}
                // onSubmit={async (values, actions) => {
                //   console.log({ values, actions });
                //   const fa = values.farmCategoryId.toString();

                //   values.farmCategoryId = parseInt(fa);

                //   values.config.map((re: any) => {
                //     for (let i = 0; i < re.validations.length; i++) {
                //       re.validations[i].validation = re.validations[i].value;
                //       re.validations[i].validationValue = "";

                //       // delete re.validations[i].label
                //       // delete re.validations[i].value
                //     }
                //   });
                //   actions.setSubmitting(false);
                //   try {
                //     const response = await configService.editfarmingStagesDuplicate(
                //       match.params.id,
                //       values
                //     );
                //     if (response.data.data) {
                //       alert.success("successfully Updated");
                //       history.push("/config");
                //     }
                //   } catch (error: any) {
                //     alert.error(error.response.data.message);
                //   }
                // }}
                onSubmit={async (values, actions) => {
                  console.log({ values, actions });
                  const fa = values.farmCategoryId.toString();

                  values.farmCategoryId = parseInt(fa);
                  let newArr: any = [];
                  values.config.map((re: any) => {
                    newArr.push(re.fieldName);
                    for (let i = 0; i < re.validations.length; i++) {
                      re.validations[i].validation = re.validations[i].value;
                      re.validations[i].validationValue = "";
                    }
                    if (re.controlType == "ColumnNameDropDown") {
                      const newField: any = [];
                      re.values.length > 0 &&
                        re.values.map((fr: { label: any; value: any }) => {
                          newField.push(fr.value);
                        });

                      re.values = String(newField);
                    }
                  });
                  const toFindDuplicates = (newArr: any) =>
                    newArr.filter(
                      (item: any, index: any) => newArr.indexOf(item) !== index
                    );
                  const duplicateElementa = toFindDuplicates(newArr);

                  if (duplicateElementa.length > 0) {
                    alert.error("Duplicate Entry on Question Name");
                  } else {
                    try {
                      const response = await configService.createfarmingStages(
                        values
                      );
                      if (response.data.data) {
                        alert.success("successfully create");
                        history.push("/config");
                        actions.setSubmitting(false);
                      }
                    } catch (error: any) {
                      alert.error(error.response.data.message);
                    }
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  dirty,
                  isSubmitting,
                  values,
                  setFieldValue,
                }) => (
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Name <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            type="text"
                            placeholder="enter name"
                            name="name"
                            className="form-control form-control-lg"
                          />
                          {touched.name && errors.name ? (
                            <div className="text-danger">{errors.name}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        {/* <FormGroup className=""> */}
                        <Label className="font-weight-bold">
                          Category{" "}
                          <span className="text-danger fw-bold">*</span>{" "}
                        </Label>
                        <Field
                          name="farmCategoryId"
                          as="select"
                          className="form-control form-control-lg"
                        >
                          <option value=""> Select</option>
                          <option value={1}>Pre-planting</option>
                          <option value={2}>Planting</option>
                          <option value={3}>Post-planting</option>
                        </Field>
                        {touched.farmCategoryId && errors.farmCategoryId ? (
                          <div className="text-danger">
                            {errors.farmCategoryId}
                          </div>
                        ) : null}
                        {/* </FormGroup> */}
                      </Col>
                    </Row>

                    <FieldArray
                      name="config"
                      render={(arrayHelpers) => (
                        <div>
                          {values.config.map((friend: any, index: number) => (
                            <React.Fragment>
                              <Row className="my-2 bg-green-3 p-3">
                                <Col>
                                  <FormGroup>
                                    <Label className="font-weight-bold">
                                      Enter Question
                                    </Label>
                                    <Field
                                      type="text"
                                      placeholder="field name"
                                      name={`config[${index}].fieldName`}
                                      className="form-control form-control-lg"
                                    />
                                    <div className="text-danger">
                                      <ErrorMessage name="config[0].fieldName" />
                                    </div>
                                  </FormGroup>
                                </Col>

                                <Col>
                                  <FormGroup className="">
                                    <Label className="font-weight-bold">
                                      Type of Answer Expected
                                    </Label>
                                    {/* <Field
                                type="text"
                                placeholder="control type"
                                name={`config[${index}].controlType`}
                                className="form-control form-control-lg"
                                valid
                              /> */}
                                    <Field
                                      name={`config[${index}].controlType`}
                                      as="select"
                                      className="form-control form-control-lg"
                                    >
                                      <option> Select</option>
                                      {/* <option value="Input">Input</option>
                                  <option value="Others">Others</option> */}
                                      {controlType.map((ass: any, i: any) => (
                                        <option value={ass}>{ass}</option>
                                      ))}
                                    </Field>
                                    <div className="text-danger">
                                      <ErrorMessage name="config[0].controlType" />
                                    </div>
                                  </FormGroup>
                                </Col>

                                <Col>
                                  <FormGroup className="">
                                    <Label className="font-weight-bold">
                                      Select Validation Type
                                    </Label>
                                    {/* <Field
                                type="text"
                                placeholder="dropdown value"
                                name={`config[${index}].validations`}
                                className="form-control form-control-lg"
                                valid
                              /> */}

                                    <Select
                                      onChange={(th: any) => {
                                        setFieldValue(
                                          `config[${index}].validations`,
                                          th
                                        );
                                      }}
                                      options={validations}
                                      defaultValue={friend.validations}
                                      isMulti={true}
                                      name={`config[${index}].validations`}
                                    />
                                    <div className="text-danger">
                                      <ErrorMessage name="config[0].validations" />
                                    </div>
                                  </FormGroup>
                                </Col>
                                {values.config[index].controlType ==
                                "DropDown" ? (
                                  <Col>
                                    <FormGroup className="">
                                      <Label className="font-weight-bold">
                                        Dropdown Values
                                      </Label>
                                      <Field
                                        type="text"
                                        placeholder="male,female"
                                        name={`config[${index}].values`}
                                        className="form-control form-control-lg"
                                        valid
                                      />
                                      <div className="text-danger">
                                        <ErrorMessage name="config[0].values" />
                                      </div>
                                    </FormGroup>
                                  </Col>
                                ) : null}
                                <Button
                                  close
                                  className="text-danger"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </Row>
                            </React.Fragment>
                          ))}
                          <Button
                            color="success"
                            type="button"
                            className="btn-create px-4 py-2 shadow font-weight-bold bg-or"
                            onClick={() =>
                              arrayHelpers.push({
                                fieldName: "",
                                controlType: "",
                                validations: [],
                                values: "",
                              })
                            }
                          >
                            Add
                          </Button>
                        </div>
                      )}
                    />

                    <div className="p-3 text-center">
                      <Link to="/config">
                        <Button
                          color="success"
                          className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        color="success"
                        className="btn-create px-4 py-2 shadow font-weight-bold"
                        disabled={isSubmitting}
                      >
                        Create
                        {isSubmitting ? (
                          <FaSpinner className="fa-spin" />
                        ) : null}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Duplicate_farm_stage;
