import React from "react";
import { Button, Container, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { seasonThunk } from "../../../../redux/action/season/season.thunk";
import { ResponseEop } from "../../../../models/season.model";
import { RootState } from "../../../../redux/store/rootReducer";
import {
  useHistory,
  RouteComponentProps,
  Link,
  useLocation,
} from "react-router-dom";
import { PageBody } from "../../../../components/Loader";
import Layout from "../../../../components/Layout";

const View_eop = ({
  match,
}: RouteComponentProps<{ id: string; in: string }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const location = useLocation();
  const { status, seasonData } = location.state;
  console.log(location.state);
  const alert = useAlert();
  const { eop }: any = useSelector((state: RootState) => state.eop);

  const geteop = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(seasonThunk.getAllEop(match.params.id));
    updateLoader(false, "");
  }, [eop]);

  React.useEffect(() => {
    geteop();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <Link
            to={{
              pathname: `/eop/${match.params.id}`,
              state: {
                seasonData: seasonData,
                status: status,
              },
            }}
          >
            <Button
              className=" px-4 py-2 shadow btn-download  ml-4 mb-3"
              inline
            >
              Back
            </Button>
          </Link>
          <Table hover responsive>
            <thead>
              <tr className="shadow">
                <th>ITEM</th>
                <th> QUANTITY</th>
                <th> QUANTITY TYPE</th>
                <th> UNIT PRICE </th>
                <th> TOTAL </th>
              </tr>
            </thead>

            <tbody>
              {eop[match.params.in].eopResponseDetails.length < 1 ? (
                <div className="text-center m-auto">
                  <h1>No record found</h1>
                </div>
              ) : (
                eop[match.params.in].eopResponseDetails.map(
                  (eo: any, i: any) => (
                    <tr>
                      <td>{eo.name}</td>
                      {/* <td>eo.eopResponseDetails.map </td> */}
                      <td>{eo.quantity}</td>
                      <td>{eo.quantityType}</td>
                      <td>{eo.unitPrice}</td>
                      <td>{eo.itemTotal}</td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </Table>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default View_eop;
