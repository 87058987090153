import React from "react";

const Currency = (currency: any) => {
  return (
    currency &&
    Number(currency)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  );
};

export default Currency;
