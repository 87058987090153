import React, { useState } from "react";
import {
  Button,
  Container,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { seasonService } from "../../../../Services";
import { RouteComponentProps, Link, useLocation } from "react-router-dom";
import { PageBody } from "../../../../components/Loader";
import Currency from "../../../../components/Currency";
import Layout from "../../../../components/Layout";
import GeneratePOForm from "./GeneratePOForm";
import { configService } from "../../../../Services/configService";
import { HiDotsVertical } from "react-icons/hi";
import EditPOForm from "./EditGeneratedPO";

interface PurchaseOrderEOP {
  eopId: number;
  eopName: string;
  quantityRequested: number;
}

interface PurchaseOrder {
  id: number;
  warehouseUniqueId: string;
  supplierId: number;
  supplierName: string;
  createdAt: string;
  updatedAt: string;
  purchaseOrderEOPs: PurchaseOrderEOP[];
}

const All_eop = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const [clusters, setClusters] = React.useState<any>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [eop, setEop] = React.useState<any>([]);
  const [yields, setYields] = React.useState<any>([]);
  const [activeTab, setActiveTab] = useState<string>("1");
  const [purchaseOrderData, setPurchaseOrderData] = useState<PurchaseOrder[]>(
    []
  );
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<
    string | null
  >(null);

  const alert = useAlert();
  const location = useLocation();
  const { status = "", seasonData = {} as any } = location.state || {};

  // const { status, seasonData } = location.state;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleEditOrder = (purchaseOrderId: string) => {
    setSelectedPurchaseOrderId(purchaseOrderId);
    setIsEditModalOpen(!isEditModalOpen);
  };

  const getAllPurchaseOrders = async () => {
    updateLoader(true, "Fetching Data");
    const response = await configService.getAllPurchaseOrder();
    updateLoader(false, "");
    console.log("Orders Purchase", response?.data?.data);
    setPurchaseOrderData(response?.data?.data);
  };

  const getclusters = async () => {
    updateLoader(true, "Fetching Data");
    const fields = await seasonService.getAllField(match.params.id);
    const bd = {
      pageNumber: 1,
      pageSize: 20,
      seasonId: match.params.id,
      clusterColumns: [...fields.data.data],
    };
    const response = await seasonService.getAllCluster(bd);
    setClusters(response.data.data);
    const responseYield = await seasonService.getAllYield(match.params.id);
    setYields(responseYield.data.data);
    const responseEop = await seasonService.getAllEop(match.params.id);
    setEop(responseEop.data.data);
    updateLoader(false, "");
  };

  React.useEffect(() => {
    getclusters();
    getAllPurchaseOrders();
  }, []);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let costOfEop = 0;
  let subTot = 0;
  eop.map((er: any) => {
    subTot += er.eopSubTotal;
  });
  costOfEop = clusters.hectares * subTot;

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <h5>
            Season: {seasonData.seasonType + " " + seasonData.associationName}
          </h5>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Link
              to={{
                pathname: `/eop/${match.params.id}`,
                state: {
                  seasonData: seasonData,
                  status: status,
                },
              }}
            >
              <Button className="px-4 py-2 shadow btn-download ml-4 mb-4">
                Back
              </Button>
            </Link>
            <Button
              className="px-4 py-2 shadow btn-create ml-4 mb-4"
              style={{ backgroundColor: "#556B2F", border: "none" }}
              onClick={toggleModal}
            >
              Generate PO
            </Button>
            {modalOpen && (
              <GeneratePOForm
                modal={modalOpen}
                toggle={toggleModal}
                eopResponseDetails={eop[0]?.eopResponseDetails || []}
                refreshData={getAllPurchaseOrders}
              />
            )}
          </div>

          {/* Nav tabs */}
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => toggleTab("1")}
              >
                EOP
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => toggleTab("2")}
              >
                Purchase Order
              </NavLink>
            </NavItem>
          </Nav>

          {/* Tab content */}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {eop.map((eo: any, i: number) => (
                <Table hover responsive key={i}>
                  <thead>
                    <tr className="shadow">
                      <th>ITEM</th>
                      <th>QUANTITY</th>
                      <th>UNIT TYPE</th>
                      <th>UNIT PRICE</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eo.eopResponseDetails.map((ep: any, i: number) => (
                      <tr key={i}>
                        <td>{ep.name}</td>
                        <td>{ep.quantity}</td>
                        <td>{ep.quantityType}</td>
                        <td>₦ {Currency(ep.unitPrice)}</td>
                        <th>₦ {Currency(ep.itemTotal)}</th>
                      </tr>
                    ))}
                    <tr>
                      <th colSpan={4}>
                        Subtotal: ₦ {Currency(eo.eopSubTotal)}
                      </th>
                    </tr>
                  </tbody>
                </Table>
              ))}
              <h5 className="font-bold">Total EOP: ₦ {Currency(subTot)}</h5>

              {/* Expected Yield Table */}
              <h5 className="mt-4">Expected Yield</h5>
              <Table hover responsive>
                <thead>
                  <tr className="shadow">
                    <th>No of bags</th>
                    <th>Cost of bag</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {yields[0] == null ? (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No record found
                      </td>
                    </tr>
                  ) : (
                    yields[0].eyResponseDetails.map((eo: any, i: number) => (
                      <tr key={i}>
                        <td>{eo.bags}</td>
                        <td>₦ {Currency(eo.costPerBag)}</td>
                        <td>₦ {Currency(eo.itemTotal)}</td>
                      </tr>
                    ))
                  )}
                  <tr>
                    <th colSpan={3}>
                      Subtotal: ₦{" "}
                      {yields[0] == null ? 0 : Currency(yields[0].eySubTotal)}
                    </th>
                  </tr>
                </tbody>
              </Table>

              <Table className="mt-5">
                <thead>
                  {" "}
                  <tr>
                    <th>PFi provided hectares</th>

                    <th>Cost of EOP</th>
                    <th> Expected Yield </th>
                    <th>Profit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{clusters.hectares}</td>

                    <th> ₦ {Currency(costOfEop)}</th>
                    <th>
                      {" "}
                      ₦{" "}
                      {Currency(
                        clusters.hectares *
                          (yields[0] == null ? 0 : yields[0].eySubTotal)
                      )}
                    </th>
                    <th>
                      ₦{" "}
                      {Currency(
                        clusters.hectares *
                          (yields[0] == null ? 0 : yields[0].eySubTotal) -
                          costOfEop
                      )}
                    </th>
                  </tr>
                </tbody>
              </Table>
            </TabPane>

            <TabPane tabId="2">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Supplier</th>
                    <th>Order Date</th>
                    <th>Items</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseOrderData.map((order) => (
                    <tr key={order.id}>
                      <td>{order.id}</td>
                      <td>{order.supplierName}</td>
                      <td>{new Date(order.createdAt).toLocaleDateString()}</td>

                      <td>
                        {order.purchaseOrderEOPs
                          .slice(0, 2)
                          .map((item, index) => (
                            <div key={index}>
                              {item.eopName} (Qty: {item.quantityRequested})
                            </div>
                          ))}
                        {order.purchaseOrderEOPs.length > 2 && (
                          <div>+ {order.purchaseOrderEOPs.length - 2}more</div>
                        )}
                      </td>
                      <td>
                        <div className="justify-content-center">
                          <UncontrolledDropdown className="m-drop">
                            <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                              <HiDotsVertical />
                            </DropdownToggle>
                            <DropdownMenu container="body">
                              <DropdownItem
                                onClick={() =>
                                  handleEditOrder(order.id.toString())
                                }
                              >
                                Edit Order
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {isEditModalOpen && selectedPurchaseOrderId && (
                <EditPOForm
                  open={isEditModalOpen}
                  toggle={() => setIsEditModalOpen(!isEditModalOpen)}
                  // purchaseOrderId={selectedPurchaseOrderId}
                  purchaseOrderId={Number(selectedPurchaseOrderId)}
                  eopResponseDetails={eop[0]?.eopResponseDetails || []}
                  refreshData={getAllPurchaseOrders}
                />
              )}
            </TabPane>
          </TabContent>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default All_eop;
