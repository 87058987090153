import React from "react";
import { Row, Col, Button, FormGroup, Container, Label } from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { useAlert } from "react-alert";
import { Link, useHistory, RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store/rootReducer";
import { seasonService, userService } from "../../../Services";
import { configThunk } from "../../../redux/action/config/config.thunk";
import {
  ResponsefarmingStageType,
  Responsestatetype,
} from "../../../models/config.model";
import Select from "react-select";
import { useLoadingMessage } from "../../../components/useLoadingMessage";
import { PageBody } from "../../../components/Loader";
import DatePicker from "react-datepicker";
import { FaSpinner } from "react-icons/fa";
import Layout from "../../../components/Layout";
import { usersThunk } from "../../../redux/action/users/users.thunk";

interface FormValues {
  projectName: string;
  seasonType: string;
  startDate: Date;
  endDate: Date;
  year: Date;
  associationId: string;
  farmStagesId: [];
  users: [];
  UserIds: [];
  stateIds: [];
}
const initialValues: FormValues = {
  projectName: "",
  seasonType: "",
  startDate: new Date(),
  endDate: new Date(),
  year: new Date(),
  associationId: "",
  farmStagesId: [],
  UserIds: [],
  users: [],
  stateIds: [],
};

const Edit_season = ({ match }: RouteComponentProps<{ id: string }>) => {
  const alert = useAlert();
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [season, setSeason] = React.useState<FormValues>(initialValues);
  const [userss, setUserss] = React.useState<any>([]);
  const [seasonFarm, setSeasonFarm] = React.useState<[]>([]);
  const [seasonState, setSeasonState] = React.useState<[]>([]);
  const [seasonUser, setSeasonUser] = React.useState<[]>([]);

  const { users }: any = useSelector((state: RootState) => state.users);

  const { association } = useSelector((state: RootState) => state.association);
  const { allSeasonType } = useSelector(
    (state: RootState) => state.seasonsType
  );
  const { farmingStage } = useSelector(
    (state: RootState) => state.farmingStage
  );
  const { states } = useSelector((state: RootState) => state.states);
  const dispatch = useDispatch();

  const getSeason = async () => {
    updateLoader(true, "Fetching Data");
    const response = await seasonService.getOneSeason(match.params.id);
    console.log(response);
    const fstage = response.data.data.farmStage;
    const fSt: any = [];
    fstage.map((stg: any, i: any) => {
      fSt.push({ label: stg.name, value: stg.id });
    });

    const fUsers = response.data.data.users;
    const gUsers: any = [];
    fUsers.map((stg: any, i: any) => {
      gUsers.push({ label: stg.firstName, value: stg.id });
    });

    const sState = response.data.data.state;
    const sS: any = [];
    sState.map((stg: any, i: any) => {
      sS.push({ label: stg.state, value: stg.id });
    });
    setSeasonState(sS);
    setSeasonFarm(fSt);
    setSeasonUser(gUsers);
    setSeason(response.data.data);
    updateLoader(false, "");
  };

  const initialValuess: FormValues = {
    projectName: season.projectName,
    seasonType: season.seasonType,
    startDate: season.startDate,
    endDate: season.endDate,
    year: season.year,
    associationId: season.associationId,
    farmStagesId: [...seasonFarm],
    UserIds: [],
    users: [...seasonUser],
    stateIds: [],
  };

  const getSeasonDropDown = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(configThunk.getAllAssociation(association));
    await dispatch(configThunk.getAllSeasonType(allSeasonType));
    await dispatch(configThunk.getAllFarmingStage(farmingStage));
    await dispatch(configThunk.getAllStates(states));
    await dispatch(usersThunk.getAllUsers());
    let usess: any = [];

    if (users?.meta?.totalPages > 1) {
      let uu: any = [];
      for (let i = 0; i < users?.meta?.totalPages; i++) {
        const response: any = await userService.getAllQuery(
          `?PageNumber=${i + 1}`
        );
        // users.data = [...users.data,...response.data.data]
        uu = [...uu, ...response?.data?.data];
      }
      // users.data = uu
      uu.filter(
        (accountStatus: any) => accountStatus.accountStatus == true
      ).map((far: any, key: any) =>
        usess.push({ label: far.firstName + " " + far.lastName, value: far.id })
      );
    } else {
      users.data
        .filter((accountStatus: any) => accountStatus.accountStatus == true)
        .map((far: any, key: any) =>
          usess.push({
            label: far.firstName + " " + far.lastName,
            value: far.id,
          })
        );
    }

    setUserss(usess);
    updateLoader(false, "");
  }, [association, allSeasonType, farmingStage, updateLoader]);

  React.useEffect(() => {
    getSeason();
    getSeasonDropDown();
  }, []);

  const obj = Object.assign({}, farmingStage);
  const seasonTypes: any = [];
  Object.keys(obj).forEach((key: any) =>
    seasonTypes.push({
      label: Number(key),
      value: obj[key],
    })
  );

  const farmStages: any = [];
  farmingStage.map((far: ResponsefarmingStageType, key: any) =>
    farmStages.push({ label: far.name, value: far.id })
  );

  // const userss: any = [];
  // users.data
  //   .filter((accountStatus: any) => accountStatus.accountStatus == true)
  //   .map((far: any, key: any) =>
  //     userss.push({ label: far.firstName + " " + far.lastName, value: far.id })
  //   );

  const statesValue: any = [];
  states.map((stat: Responsestatetype, key: any) =>
    statesValue.push({ label: stat.state, value: stat.id })
  );

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <Row>
            <Col sm={12} lg={12}>
              <Formik
                initialValues={initialValuess}
                validationSchema={Yup.object({
                  // projectName: Yup.string().required("Project name is  required"),
                  seasonType: Yup.string().required("Season type is required"),
                  associationId: Yup.number().required(
                    "Association is required"
                  ),
                  // year: Yup.string().required("Year is required"),
                  startDate: Yup.date().required("Start date is required"),
                  endDate: Yup.date().required(" End date is required"),
                  farmStagesId: Yup.array()
                    .min(1, "Farm stage is Required")
                    .required("Farm stage is Required"),
                  users: Yup.array()
                    .min(1, "User is Required")
                    .required("User is Required"),
                })}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true);
                  console.log(values);
                  const newFarm: any = [];
                  values.farmStagesId.map(
                    (frr: { label: string; value: number }) => {
                      newFarm.push(frr.value);
                    }
                  );
                  const stats: any = [];
                  values.stateIds.map(
                    (fr: { label: string; value: number }) => {
                      stats.push(fr.value);
                    }
                  );
                  const newUsers: any = [];
                  values.users.map((fr: { label: string; value: number }) => {
                    newUsers.push(fr.value);
                  });
                  values.users = newUsers;
                  values.UserIds = newUsers;
                  values.stateIds = stats;
                  values.farmStagesId = newFarm;
                  console.log({ values, actions });

                  actions.setSubmitting(false);
                  try {
                    const response = await seasonService.updateSeason(
                      match.params.id,
                      values
                    );
                    console.log(response);
                    if (response.data.data) {
                      alert.success("successfully updated");
                      history.push("/season");
                    }
                  } catch (error) {
                    alert.error("error");
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  values,
                  setFieldValue,
                  dirty,
                }) => (
                  <Form>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Season Type{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            name="seasonType"
                            as="select"
                            className="form-control form-control-lg"
                            required
                          >
                            <option> Select</option>
                            {allSeasonType.map((type: any, i: any) => (
                              <option value={type}>{type}</option>
                            ))}
                          </Field>
                          {touched.seasonType && errors.seasonType ? (
                            <div className="text-danger">
                              {errors.seasonType}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Project Name
                          </Label>
                          <Field
                            type="text"
                            placeholder="project name"
                            name="projectName"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.projectName && errors.projectName ? (
                            <div className="text-danger">
                              {errors.projectName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Association{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            name="associationId"
                            as="select"
                            className="form-control form-control-lg"
                          >
                            <option> Select</option>
                            {association.map((ass: any, i: any) => (
                              <option value={ass.associationId}>
                                {ass.associationName}
                              </option>
                            ))}
                          </Field>
                          {touched.associationId && errors.associationId ? (
                            <div className="text-danger">
                              {errors.associationId}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Year <span className="text-danger fw-bold">*</span>
                          </Label>

                          <DatePicker
                            selected={new Date(values.year)}
                            dateFormat="yyyy"
                            showYearPicker
                            className="form-control form-control-lg"
                            autoComplete="off"
                            name="year"
                            onChange={(date) => setFieldValue("year", date)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Start Date{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>

                          <DatePicker
                            selected={new Date(values.startDate)}
                            dateFormat="MMMM d, yyyy"
                            className="form-control form-control-lg"
                            autoComplete="off"
                            name="startDate"
                            minDate={new Date()}
                            onChange={(date) =>
                              setFieldValue("startDate", date)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            End Date{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>

                          <DatePicker
                            selected={new Date(values.endDate)}
                            dateFormat="MMMM d, yyyy"
                            className="form-control form-control-lg"
                            autoComplete="off"
                            name="endDate"
                            minDate={new Date(values.startDate)}
                            onChange={(date) => setFieldValue("endDate", date)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Farming Stage
                            <span className="text-danger fw-bold">*</span>
                          </Label>

                          <Select
                            onChange={(th) => {
                              setFieldValue("farmStagesId", th);
                            }}
                            options={farmStages}
                            defaultValue={seasonFarm}
                            isMulti={true}
                            name="farmStagesId"
                          />
                          {touched.farmStagesId && errors.farmStagesId ? (
                            <div className="text-danger">
                              {errors.farmStagesId}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <Col>
                          <FormGroup className="">
                            <Label className="font-weight-bold">
                              Users
                              <span className="text-danger fw-bold">*</span>
                            </Label>

                            <Select
                              onChange={(th) => {
                                setFieldValue("users", th);
                              }}
                              options={userss}
                              defaultValue={seasonUser}
                              isMulti={true}
                              name="users"
                            />
                            {touched.users && errors.users ? (
                              <div className="text-danger">{errors.users}</div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        {/* <Col> */}
                        {/* <FormGroup className="">
                        <Label className="font-weight-bold">State</Label>

                        <Select
                          onChange={(th) => {
                            setFieldValue("stateIds", th);
                          }}
                          options={statesValue}
                          defaultValue={seasonState}
                          isMulti={true}
                          name="stateIds"
                        />
                        {touched.stateIds && errors.stateIds ? (
                          <div className="text-danger">{errors.stateIds}</div>
                        ) : null}
                      </FormGroup> */}
                      </Col>
                    </Row>
                    <div className="p-3 text-center">
                      <Link to="/season">
                        <Button
                          color="success"
                          className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        color="success"
                        className="btn-create px-4 py-2 shadow font-weight-bold"
                        type="submit"
                        disabled={!dirty || isSubmitting}
                      >
                        Update{" "}
                        {isSubmitting ? (
                          <FaSpinner className="fa-spin" />
                        ) : null}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Edit_season;
