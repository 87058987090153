import { usersReducers, activityReducers } from "./users/users.reducer";
import {
  ClusterReducer,
  FieldReducer,
  ClusterStateReducer,
  eopReducer,
  seasonReducers,
  seasonStateReducers,
  YieldReducer,
  seasonUploadReducers,
  werehouseUploadReducers,
  WarehouseReducer,
} from "./season/season.reducer";
import {
  rolesReducers,
  seasonTypeReducers,
  associationReducers,
  incidenceReducers,
  cropTypeReducers,
  farmStageReducers,
  statesReducers,
  supplierReducers,
} from "./config/config.reducer";
import { werehouseUpload } from "../actionTypes";

const AllReducers = {
  act: activityReducers,
  users: usersReducers,
  seasonsType: seasonTypeReducers,
  roles: rolesReducers,
  states: statesReducers,
  cropType: cropTypeReducers,
  incidence: incidenceReducers,
  association: associationReducers,
  farmingStage: farmStageReducers,
  seasons: seasonReducers,
  seasonState: seasonStateReducers,
  seasonUpload: seasonUploadReducers,
  werehouseUpload: werehouseUploadReducers,
  eop: eopReducer,
  clusters: ClusterReducer,
  stateClusters: ClusterStateReducer,
  fields: FieldReducer,
  yields: YieldReducer,
  werehouses:  WarehouseReducer,
  suppliers: supplierReducers,
};
export default AllReducers;
