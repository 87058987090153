import { useState } from 'react'

export const useLoadingMessage = () => {
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [activeBar, setactiveBar] = useState("Dashboard")

  const updateLoader = (newIsLoading:boolean, newMessage:string) => {
    setIsLoading(newIsLoading)
    setMessage(newMessage)
  }
  const sideBar = (newIsLoading:boolean, active:string)=>{
    setIsLoading(newIsLoading)
    setactiveBar(active)
  }

  return { message, isLoading, updateLoader,activeBar,sideBar }
}
