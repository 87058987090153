import React from "react";
import { Link, useLocation, RouteComponentProps } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import AppBreadcrumb from "../../../../../components/layout/breadcrumb/Breadcrumb";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmTemplate } from "../../../../../components/confirm";
import { useAlert } from "react-alert";
import { PageBody } from "../../../../../components/Loader";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { RootState } from "../../../../../redux/store/rootReducer";
import { seasonThunk } from "../../../../../redux/action/season/season.thunk";
import { seasonService } from "../../../../../Services";
import { seasonUpload } from "../../../../../redux/actionTypes";
import moment from "moment";
import { saveAs } from "file-saver";
import * as signalR from "@microsoft/signalr";
import Layout from "../../../../../components/Layout";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { roless } from "../../../../../Constants";
import { AuthContext } from "../../../../../context/authContext";

const localizer = momentLocalizer(moment);

const Season_form = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { role } = React.useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const location = useLocation();
  const { seasonData } = location.state;

  // const dispatch = useDispatch();
  // const { seasonUpload } = useSelector(
  //   (state: RootState) => state.seasonUpload
  // );
  // const alert = useAlert();
  // const location = useLocation();
  // const { seasonData } = location.state;
  const [forms, setAllforms] = React.useState([]);

  const getforms = async () => {
    updateLoader(true, "Fetching Data");
    try {
      const response: any = await seasonService.getAllSeasonForm(
        match.params.id
      );
      setAllforms(response.data.response);
      updateLoader(false, "");
    } catch (err) {
      setAllforms([]);
      updateLoader(false, "");
    }
  };

  const [search, setSearch] = React.useState("");

  const formss =
    forms &&
    forms.filter((farmingS: any) => {
      return farmingS.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

  React.useEffect(() => {
    getforms();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container className="mb-4">
          <Row>
            <Col md={9}>
              <Link
                // to="/"
                to={{
                  pathname: `/manage-season/${match.params.id}`,
                  state: {
                    seasonData: seasonData,
                  },
                }}
              >
                <Button className=" px-4 py-2 shadow btn-download  ml-4" inline>
                  Back
                </Button>
              </Link>
              {roless.BusinessAd == role || roless.ITAd == role ? (
                seasonData.isActive ? (
                  <Link
                    to={{
                      pathname: `/create-crop-calender/${match.params.id}`,
                      state: {
                        seasonData: seasonData,
                      },
                    }}
                  >
                    <Button className="btn-create px-4 py-2 shadow ml-5" inline>
                      Cropping Calendar
                    </Button>
                  </Link>
                ) : null
              ) : null}

              <span className="ml-5">
                <Input
                  placeholder="search"
                  type="search"
                  className="form-search"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </span>
            </Col>
            <Col md={3} className="text-right">
              {" "}
            </Col>
          </Row>
          {/* <Col className="my-5">
            <Calendar
              localizer={localizer}
              events={dummyEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
            />
          </Col> */}
          <Row className="mt-4">
            <Table hover responsive>
              <thead>
                <h5>All Form</h5>
                <tr className="shadow">
                  <th>S/N</th>
                  <th> Farm Stage</th>

                  <th> Start Date</th>
                  <th> End Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formss.length < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  formss.map((fr: any, i: any) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{fr.name}</td>
                      <td>{moment(fr.startDate).format("ll")}</td>
                      <td>{moment(fr.endDate).format("ll")}</td>
                      {roless.BusinessAd == role || roless.ITAd == role ? (
                        seasonData.isActive ? (
                          <td>
                            {" "}
                            <UncontrolledDropdown className="m-drop">
                              <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                <HiDotsVertical />
                              </DropdownToggle>
                              <DropdownMenu container="body">
                                <Link
                                  to={{
                                    pathname: `/edit-season-form/${match.params.id}/${fr.id}`,
                                    state: {
                                      seasonData: seasonData,
                                    },
                                  }}
                                >
                                  <DropdownItem>Edit</DropdownItem>
                                </Link>
                                {/* <Link
                                

                                to={{
                                  pathname: `/season-form-answer/${match.params.id}/${fr.id}`,
                                  state: {
                                    seasonData: seasonData,
                                  },
                                }}
                              >
                                <DropdownItem>View Answer</DropdownItem>
                              </Link> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        ) : null
                      ) : null}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Season_form;
