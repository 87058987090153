import { Field, getIn } from 'formik';

const ErrorMessage = ({ name }:any) => (
    <Field
      name={name}
    >
      {({ form }:any) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? error : null;
    }}
    </Field>
  );

export default ErrorMessage