import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";
import FormTextInput from "../../../../../components/form/text-input";
import { configThunk } from "../../../../../redux/action/config/config.thunk";
import { useHistory, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../../redux/store/rootReducer";
import { seasonService } from "../../../../../Services";
import { Responsestatetype } from "../../../../../models/config.model";
import Layout from "../../../../../components/Layout";

interface FormValues {
  stateId: string;
}
const Create_state = ({ match }: RouteComponentProps<{ id: string }>) => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    stateId: "",
  };
  const { states } = useSelector((state: RootState) => state.states);
  const getStateDropDown = React.useCallback(async () => {
    await dispatch(configThunk.getAllStates(states));
  }, [states]);

  React.useEffect(() => {
    getStateDropDown();
  }, []);
  return (
    <Layout active="Seasons">
      <Container>
        <Row>
          <Col>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                // stateId: Yup.number().required("Required"),
              })}
              onSubmit={async (values, actions) => {
                console.log({ values, actions });

                actions.setSubmitting(false);
                try {
                  const response = await seasonService.createSeasonState(
                    match.params.id,
                    values
                  );
                  console.log(response);
                  if (response.data.data) {
                    alert.success("successfully created");
                    history.push("/config");
                  }
                } catch (error) {
                  alert.error("error");
                }
              }}
            >
              {({ errors, touched, isSubmitting, handleChange, values }) => (
                <Form>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="">
                        <Field
                          name="stateId"
                          as="select"
                          className="form-control form-control-lg"
                        >
                          <option> Select</option>
                          {states.map((state: Responsestatetype, i) => (
                            <option value={state.id}>{state.state}</option>
                          ))}
                        </Field>
                        {touched.stateId && errors.stateId ? (
                          <div className="text-danger">{errors.stateId}</div>
                        ) : null}
                      </FormGroup>
                    </Col>

                    {/* <Col md={6}>
                  <FormGroup className="">
                    <FormTextInput type="select" label="L.G.A" valid />
                  </FormGroup>
                </Col> */}
                  </Row>

                  <div className="p-3 text-center">
                    <Button
                      color="success"
                      className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="success"
                      type="submit"
                      className="btn-create px-4 py-2 shadow font-weight-bold"
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Create_state;
