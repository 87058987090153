import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import LOGO from '../assets/img/loader.gif'

const FixedBodyStyle = createGlobalStyle`
  body {
    height: 100vh !important;
    width: 100vw !important;
  }
`;

const PageLoaderContainer = styled.div`
  background: #f6f6f6f6;
  backdrop-filter: blur(10px);
  font-weight: bold;
  font-size: 160%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;

  

  img {
    height: 160px;
  }

  * + * {
    margin-top: 10px;
  }
`;


interface Pload{
isLoading:boolean,
message:string
}


export const FullPageLoader = ({ isLoading, message }:Pload) =>
  isLoading ? (
    <PageLoaderContainer>
      <FixedBodyStyle />
      <img src={LOGO} alt="Loader" />
      {message ? <span>{message}</span> : null}
    </PageLoaderContainer>
  ) : null;


  export const PageLoader:React.FC<Pload> = ({ isLoading, message,children }) =>
  isLoading ? (
    <PageLoaderContainer>
      <FixedBodyStyle />
      <img src={LOGO} alt="Loader" />
      {message ? <span>{message}</span> : null}
    </PageLoaderContainer>
  ) : (  <>{children}</>);

  export const PageBody:React.FC<Pload> = ({isLoading,message,children})=>{
      return(
        <PageLoader isLoading={isLoading} message={message}>
        <main>
          {children}
        </main>
      </PageLoader>
      
      )
  }
