import React from "react";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import FormTextInput from "../../../../components/form/text-input";
import { Link } from "react-router-dom";
import { PageBody } from "../../../../components/Loader";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { configService } from "../../../../Services";
import { useAlert } from "react-alert";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { incidenceType } from "../../../../models/config.model";
import Layout from "../../../../components/Layout";

interface FormValues {
  name: string;
  isActive: boolean;
}

const Edit_incidence = ({ match }: RouteComponentProps<{ id: string }>) => {
  const incidentValues: incidenceType = { name: "", isActive: false };

  const [incident, setIncident] = React.useState<incidenceType>(incidentValues);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const alert = useAlert();

  const getIncident = async () => {
    updateLoader(true, "Fetching User");
    const response = await configService.getOneincidence(match.params.id);
    console.log(response);
    setIncident(response.data.data);
    updateLoader(false, "");
  };

  React.useEffect(() => {
    getIncident();
  }, []);

  const initialValues: FormValues = {
    name: incident.name,
    isActive: incident.isActive,
  };
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Config">
        <Container>
          <Row>
            <Col>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  name: Yup.string().required("Name is required"),
                })}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(false);
                  try {
                    const response = await configService.editincidence(
                      match.params.id,
                      values
                    );

                    if (response.data.data) {
                      alert.success("successfully Updated");
                      history.push("/config");
                    }
                  } catch (error) {
                    alert.error("Error");
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  dirty,
                  // isSubmitting,
                }) => (
                  <Form>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label>
                            Name <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            placeholder="enter name"
                            name="name"
                            className="form-control form-control-lg"
                          />
                          {touched.name && errors.name ? (
                            <div className="text-danger">{errors.name}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="p-3 text-center">
                      <Link to="/config">
                        <Button
                          color="success"
                          className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        color="success"
                        className="btn-create px-4 py-2 shadow font-weight-bold"
                        disabled={!dirty}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Edit_incidence;
