import { constant, ApiClient } from "../Constants";

export const userService = {
  login,
  authenticatedUser,
  logout,
  createUser,
  getAll,
  getById,
  enableUser,
  disableUser,
  updateUser,
  sendResetEmail,
  ResetPassword,
  VerifyEmail,
  getAllQuery,
  getActivity,
};

function login(email: string, password: string) {
  const requestOptions = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${constant.API}/users/auth`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

async function authenticatedUser() {
  return ApiClient.get("/users/get-auth-user", "");
}
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
}
function sendResetEmail(body: any) {
  return ApiClient.post("/users/forgot-password", body);
}
function ResetPassword(body: any) {
  return ApiClient.patch("/users/password-reset", body);
}
function VerifyEmail(code: any) {
  return ApiClient.get(`/users/email-verification/${code}`, "");
}

function getAll() {
  return ApiClient.get("/users/get-all-users", "");
}

function getAllQuery(query: string) {
  return ApiClient.get(`/users/get-all-users${query}`, "");
}

function getById(id: string) {
  return ApiClient.get(`/users/get-user/${id}`, "");
}
function enableUser(id: number) {
  return ApiClient.patch(`/users/enable-user/${id}`, "");
}
function disableUser(id: number) {
  return ApiClient.patch(`/users/disable-user/${id}`, "");
}

function createUser(user: any) {
  return ApiClient.post(`/users/create-user`, user);
}

function updateUser(id: string, user: any) {
  return ApiClient.patch(`/users/edit-user/${id}/`, user);
}

function getActivity() {
  return ApiClient.get("/users/activity-log", "");
}

function handleResponse(response: any) {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
