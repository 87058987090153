import { seasonService } from "../../../Services/seasonService";
import {
  eopType,
  season,
  seasonState,
  seasonUpload,
  clusterType,
  clusterStateType,
  fieldType,
  yieldType,
  werehouseUpload,
  werehouseType,
} from "../../actionTypes";
import { Dispatch } from "redux";

export class seasonThunk {
  static getAllSeasons() {
    return async (dispatch: Dispatch) => {
      try {
        const response = await seasonService.getAllSeason();
        if (response.data.data) {
          dispatch({
            type: season.GET_ALL_SEASON,
            payload: response.data,
          });
          return;
        }
        throw new Error("Season creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllSeasonsQuery(query: string) {
    return async (dispatch: Dispatch) => {
      try {
        const response = await seasonService.getAllSeasonQuery(query);
        if (response.data.data) {
          dispatch({
            type: season.GET_ALL_SEASON,
            payload: response.data,
          });
          return;
        }
        throw new Error("Season creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllSeasonsState(id: string) {
    return async (dispatch: Dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        // if (reduxUsers.length === 0) {
        const response = await seasonService.getAllSeasonState(id);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: seasonState.GET_ALL_SEASONSTATE,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: userType.GET_ALL_USERS,
        //     payload: reduxUsers,
        //   });
        //   return;
        // }
        throw new Error("Season get failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllSeasonsUpload(id: string) {
    return async (dispatch: Dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        // if (reduxUsers.length === 0) {
        const response = await seasonService.getAllSeasonUpload(id);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: seasonUpload.GET_ALL_SEASONUPLOAD,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: userType.GET_ALL_USERS,
        //     payload: reduxUsers,
        //   });
        //   return;
        // }
        throw new Error("Season get failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllWerehouseUpload() {
    return async (dispatch: Dispatch) => {
      try {
        const response = await seasonService.getAllWerehouseUpload();

        if (response.data.data) {
          dispatch({
            type: werehouseUpload.GET_ALL_WEREHOUSEUPLOAD,
            payload: response.data.data,
          });
          return;
        }

        throw new Error("Season get failed");
      } catch (error) {}
    };
  }

  static getAllEop(id: any) {
    return async (dispatch: Dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        // if (reduxUsers.length === 0) {
        const response = await seasonService.getAllEop(id);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: eopType.GET_ALL_EOP,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: userType.GET_ALL_USERS,
        //     payload: reduxUsers,
        //   });
        //   return;
        // }
        throw new Error("EOP get failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllCluster(body: any) {
    return async (dispatch: Dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        // if (reduxUsers.length === 0) {
        const response = await seasonService.getAllCluster(body);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: clusterType.GET_ALL_CLUSTER,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: userType.GET_ALL_USERS,
        //     payload: reduxUsers,
        //   });
        //   return;
        // }
        throw new Error("EOP get failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

   static getAllWarehouse() {
    return async (dispatch: Dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        // if (reduxUsers.length === 0) {
        const response = await seasonService.getAllWerehouse();
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: werehouseType.GET_ALL_WAREHOUSE,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: userType.GET_ALL_USERS,
        //     payload: reduxUsers,
        //   });
        //   return;
        // }
        throw new Error("EOP get failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllStateCluster(body: any) {
    return async (dispatch: Dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        // if (reduxUsers.length === 0) {
        const response = await seasonService.getAllStateCluster(body);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: clusterStateType.GET_ALL_STATE_CLUSTER,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: userType.GET_ALL_USERS,
        //     payload: reduxUsers,
        //   });
        //   return;
        // }
        throw new Error("Cluster get failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }
  static getAllField(id: string) {
    return async (dispatch: Dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        // if (reduxUsers.length === 0) {
        const response = await seasonService.getAllField(id);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: fieldType.GET_ALL_FIELD,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: userType.GET_ALL_USERS,
        //     payload: reduxUsers,
        //   });
        //   return;
        // }
        throw new Error("Field get failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllYield(id: string) {
    return async (dispatch: Dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        // if (reduxUsers.length === 0) {
        const response = await seasonService.getAllYield(id);
        // const response = await UserService.getclientusers(id);
        console.log(response);
        if (response.data.data) {
          dispatch({
            type: yieldType.GET_ALL_YIELD,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: userType.GET_ALL_USERS,
        //     payload: reduxUsers,
        //   });
        //   return;
        // }
        throw new Error("Field get failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }
}
