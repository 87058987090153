import React from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Container,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { useAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../redux/store/rootReducer";
import FormTextInput from "../../../components/form/text-input";
import { seasonService, userService } from "../../../Services";
import { configThunk } from "../../../redux/action/config/config.thunk";
import DatePicker from "react-datepicker";
import InfiniteScroll from "react-infinite-scroller";
import {
  ResponsefarmingStageType,
  Responsestatetype,
} from "../../../models/config.model";
import Select from "react-select";
import { FaSpinner } from "react-icons/fa";
import Layout from "../../../components/Layout";
import { usersThunk } from "../../../redux/action/users/users.thunk";
import { useLoadingMessage } from "../../../components/useLoadingMessage";
import { PageBody } from "../../../components/Loader";

interface FormValues {
  projectName: string;
  seasonType: string;
  startDate: Date;
  endDate: Date;
  year: Date;
  associationId: string;
  farmStagesId: [];
  users: [];
  stateIds: [];
  UserIds: [];
  config: [
    {
      fieldName: string;
      controlType: string;
      validations: [];
      values: string;
      // id: 0,
    }
  ];
}

const Create_season = () => {
  const alert = useAlert();
  const history = useHistory();

  const { association } = useSelector((state: RootState) => state.association);
  const { users }: any = useSelector((state: RootState) => state.users);
  const [userss, setUserss] = React.useState<any>([]);
  const { allSeasonType } = useSelector(
    (state: RootState) => state.seasonsType
  );
  const { farmingStage } = useSelector(
    (state: RootState) => state.farmingStage
  );
  const { states } = useSelector((state: RootState) => state.states);
  const dispatch = useDispatch();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const initialValues: FormValues = {
    projectName: "",
    seasonType: "",
    startDate: new Date(),
    endDate: new Date(),
    year: new Date(),
    associationId: "",
    farmStagesId: [],
    users: [],
    UserIds: [],
    stateIds: [],
    config: [
      {
        fieldName: "",
        controlType: "",
        validations: [],
        values: "",
        // id: 0,
      },
    ],
  };

  const getSeasonDropDown = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(configThunk.getAllAssociation(association));
    await dispatch(configThunk.getAllSeasonType(allSeasonType));
    await dispatch(configThunk.getAllFarmingStage(farmingStage));
    await dispatch(configThunk.getAllStates(states));
    await dispatch(usersThunk.getAllUsers());
    let usess: any = [];

    if (users?.meta?.totalPages > 1) {
      let uu: any = [];
      for (let i = 0; i < users?.meta?.totalPages; i++) {
        const response: any = await userService.getAllQuery(
          `?PageNumber=${i + 1}`
        );
        // users.data = [...users.data,...response.data.data]
        uu = [...uu, ...response?.data?.data];
      }
      // users.data = uu
      uu.filter(
        (accountStatus: any) => accountStatus.accountStatus == true
      ).map((far: any, key: any) =>
        usess.push({ label: far.firstName + " " + far.lastName, value: far.id })
      );
    } else {
      users.data
        .filter((accountStatus: any) => accountStatus.accountStatus == true)
        .map((far: any, key: any) =>
          usess.push({
            label: far.firstName + " " + far.lastName,
            value: far.id,
          })
        );
    }

    setUserss(usess);
    updateLoader(false, "");
  }, [association, allSeasonType, farmingStage, updateLoader]);

  React.useEffect(() => {
    getSeasonDropDown();
  }, []);

  const obj = Object.assign({}, farmingStage);
  const seasonTypes: any = [];
  Object.keys(obj).forEach((key: any) =>
    seasonTypes.push({
      label: Number(key),
      value: obj[key],
    })
  );

  const farmStages: any = [];
  farmingStage.map((far: ResponsefarmingStageType, key: any) =>
    farmStages.push({ label: far.name, value: far.id })
  );

  const statesValue: any = [];
  states.map((stat: Responsestatetype, key: any) =>
    statesValue.push({ label: stat.state, value: stat.id })
  );

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <Row>
            <Col sm={12} lg={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  // projectName: Yup.string().required("Project name is  required"),
                  seasonType: Yup.string().required("Season type is required"),
                  associationId: Yup.number().required(
                    "Association is required"
                  ),
                  year: Yup.date().required("Year is required"),
                  startDate: Yup.date().required("Start date is required"),
                  endDate: Yup.date().required(" End date is required"),
                  farmStagesId: Yup.array()
                    .min(1, "Farm stage is Required")
                    .required("Farm stage is Required"),
                  users: Yup.array()
                    .min(1, "User is Required")
                    .required("User is Required"),
                  // stateIds:  Yup.array().required(" End date is required"),
                })}
                onSubmit={async (values, actions) => {
                  var date1 = new Date(values.startDate);
                  var date2 = new Date(values.endDate);
                  var Difference_In_Time = date2.getTime() - date1.getTime();
                  var Difference_In_Days =
                    Difference_In_Time / (1000 * 3600 * 24);

                  if (Difference_In_Days < 1) {
                    alert.error("Start and end date cannot be the same");
                  } else {
                    actions.setSubmitting(true);
                    const newFarm: any = [];
                    values.farmStagesId.map(
                      (fr: { label: string; value: number }) => {
                        newFarm.push(fr.value);
                      }
                    );
                    const stats: any = [];
                    values.stateIds.map(
                      (fr: { label: string; value: number }) => {
                        stats.push(fr.value);
                      }
                    );

                    const newUsers: any = [];
                    values.users.map((fr: { label: string; value: number }) => {
                      newUsers.push(fr.value);
                    });
                    values.users = newUsers;
                    values.UserIds = newUsers;
                    values.farmStagesId = newFarm;
                    values.stateIds = stats;

                    actions.setSubmitting(false);
                    try {
                      const response = await seasonService.createSeason(values);

                      if (response.data.data) {
                        alert.success("successfully created");
                        history.push("/season");
                      }
                    } catch (error: any) {
                      alert.error(error.response.data.message);
                      actions.setSubmitting(false);
                    }
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  values,
                  setFieldValue,
                }) => (
                  <Form>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Season Type{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            name="seasonType"
                            as="select"
                            className="form-control form-control-lg"
                            required
                          >
                            <option> Select</option>
                            {allSeasonType.map((type: any, i: any) => (
                              <option value={type}>{type}</option>
                            ))}
                          </Field>
                          {touched.seasonType && errors.seasonType ? (
                            <div className="text-danger">
                              {errors.seasonType}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Project Name
                          </Label>
                          <Field
                            type="text"
                            placeholder="project name"
                            name="projectName"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.projectName && errors.projectName ? (
                            <div className="text-danger">
                              {errors.projectName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Association{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            name="associationId"
                            as="select"
                            className="form-control form-control-lg"
                          >
                            <option> Select</option>
                            {association.map((ass: any, i: any) => (
                              <option value={ass.associationId}>
                                {ass.associationName}
                              </option>
                            ))}
                          </Field>
                          {touched.associationId && errors.associationId ? (
                            <div className="text-danger">
                              {errors.associationId}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Year <span className="text-danger fw-bold">*</span>
                          </Label>

                          <DatePicker
                            selected={new Date(values.year)}
                            dateFormat="yyyy"
                            showYearPicker
                            className="form-control form-control-lg"
                            name="year"
                            onChange={(date) => setFieldValue("year", date)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Season Start Date{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>

                          <DatePicker
                            selected={new Date(values.startDate)}
                            dateFormat="MMMM d, yyyy"
                            className="form-control form-control-lg"
                            autoComplete="off"
                            minDate={new Date()}
                            name="startDate"
                            onChange={(date) =>
                              setFieldValue("startDate", date)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Season End Date{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>

                          <DatePicker
                            selected={new Date(values.endDate)}
                            dateFormat="MMMM d, yyyy"
                            className="form-control form-control-lg"
                            autoComplete="off"
                            minDate={new Date(values.startDate)}
                            name="endDate"
                            onChange={(date) => setFieldValue("endDate", date)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Farming Stage
                            <span className="text-danger fw-bold">*</span>
                          </Label>

                          <Select
                            onChange={(th) => {
                              setFieldValue("farmStagesId", th);
                            }}
                            options={farmStages}
                            isMulti={true}
                            name="farmStagesId"
                          />
                          {touched.farmStagesId && errors.farmStagesId ? (
                            <div className="text-danger">
                              {errors.farmStagesId}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        {/* <InfiniteScroll //new
                            pageStart={0}
                            loadMore={getMoreUser}
                            hasMore={true || false}
                            useWindow={false}
                            loader={<div className="loader" key={0}>Loading ...</div>}
                          > */}
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Users
                            <span className="text-danger fw-bold">*</span>
                          </Label>

                          <Select
                            onChange={(th) => {
                              setFieldValue("users", th);
                            }}
                            options={userss}
                            isMulti={true}
                            name="users"
                          />

                          {touched.users && errors.users ? (
                            <div className="text-danger">{errors.users}</div>
                          ) : null}
                        </FormGroup>

                        {/* </InfiniteScroll> */}
                      </Col>
                    </Row>

                    <div className="p-3 text-center">
                      <Link to="/season">
                        <Button
                          color="success"
                          className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        color="success"
                        className="btn-create px-4 py-2 shadow font-weight-bold"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create
                        {isSubmitting ? (
                          <FaSpinner className="fa-spin" />
                        ) : null}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Create_season;
function startDate(date: Date | null): void {
  throw new Error("Function not implemented.");
}
