import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, RouteComponentProps } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Dropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
} from "reactstrap";
import AppBreadcrumb from "../../../../../components/layout/breadcrumb/Breadcrumb";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmTemplate } from "../../../../../components/confirm";
import { useAlert } from "react-alert";
import { PageBody } from "../../../../../components/Loader";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { RootState } from "../../../../../redux/store/rootReducer";
import { seasonThunk } from "../../../../../redux/action/season/season.thunk";
import { seasonService } from "../../../../../Services";
import { seasonUpload } from "../../../../../redux/actionTypes";
import moment from "moment";
import { saveAs } from "file-saver";
import * as signalR from "@microsoft/signalr";
import Layout from "../../../../../components/Layout";
import { roless } from "../../../../../Constants";
import { AuthContext } from "../../../../../context/authContext";
import classnames from "classnames";

const All_upload = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { role } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Cluster");
  const [activeTab, setActiveTab] = useState("1");
  const [search, setSearch] = useState("");

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const { seasonUpload } = useSelector(
    (state: RootState) => state.seasonUpload
  );
  const { werehouseUpload } = useSelector(
    (state: RootState) => state.werehouseUpload
  );
  const alert = useAlert();
  const location = useLocation();
  const { seasonData } = location.state;

  const toggleTab = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const checkuploads = useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(seasonThunk.getAllWerehouseUpload());
    console.log("werehouseUpload", werehouseUpload);
    updateLoader(false, "");
  }, [werehouseUpload]);

  const getuploads = useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(seasonThunk.getAllSeasonsUpload(match.params.id));

    updateLoader(false, "");
  }, [seasonUpload]);

  const onDisabled = (uniqueId: string, status: boolean) => {
    const request = async () => {
      updateLoader(true, "Status Changing");
      const data = {
        status: !status,
      };
      if (!status) {
        const response = await seasonService.enableSeasonUpload(uniqueId);
        if (response.status === 200) {
          alert.success(response.data.message);
        } else {
          alert.error("error");
        }
      } else {
        const response = await seasonService.disableSeasonUpload(uniqueId);
        if (response.status === 200) {
          alert.success(response.data.message);
        } else {
          alert.error("error");
        }
      }
      getuploads();
      updateLoader(false, "");
    };

    ConfirmTemplate(
      {
        title: "Changing Status ",
        message: "Are You Sure You Want To Change the Status",
      },
      request
    );
  };

  const onDisabledChecked = (uniqueId: string, status: boolean) => {
    const request = async () => {
      updateLoader(true, "Status Changing");
      const data = {
        status: !status,
      };
      if (!status) {
        const response = await seasonService.enableWerehouseUpload(uniqueId);
        if (response.status === 200) {
          alert.success(response.data.message);
        } else {
          alert.error("error");
        }
      } else {
        const response = await seasonService.disableWerehouseUpload(uniqueId);
        if (response.status === 200) {
          alert.success(response.data.message);
        } else {
          alert.error("error");
        }
      }
      checkuploads();
      updateLoader(false, "");
    };

    ConfirmTemplate(
      {
        title: "Changing Status ",
        message: "Are You Sure You Want To Change the Status",
      },
      request
    );
  };

  const werehouseUploads =
    werehouseUpload &&
    werehouseUpload.filter((wereH: any) => {
      return (
        wereH.state.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        wereH.uploadBy.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    });

  const seasonUploads =
    seasonUpload &&
    seasonUpload.filter((farmingS: any) => {
      return (
        farmingS.state.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        farmingS.uploadedBy.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    });

  useEffect(() => {
    getuploads();
    checkuploads();
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("https://abp.remsys.com.ng/notify", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    newConnection
      .start()
      .then((result) => {
        console.log("Connected!");
      })
      .catch((e) => console.log("Connection failed: ", e));

    newConnection.on("Progress", (data) => {
      console.log("Progress Report: ", data);
      const d2 = data.replace(/\s/g, "");
      if (d2 == "UploadCompleted") {
        getuploads();
        checkuploads();
      }
    });
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container className="mb-4">
          <h5>
            Season: {seasonData.seasonType + " " + seasonData.associationName}
          </h5>
          <Row className="align-items-center mb-3">
            <Col xs="auto">
              <Link
                to={{
                  pathname: `/manage-season/${match.params.id}`,
                  state: {
                    seasonData: seasonData,
                  },
                }}
              >
                <Button className="px-4 py-2 shadow btn-download mr-3">
                  Back
                </Button>
              </Link>
            </Col>
            <Col xs="auto">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret className="btn-create px-4 py-2 shadow">
                  Upload Template
                </DropdownToggle>
                <DropdownMenu style={{ borderRadius: "10px", padding: "10px" }}>
                  <DropdownItem>
                    {roless.BusinessAd == role || roless.ITAd == role ? (
                      seasonData.isActive ? (
                        <Link
                          to={{
                            pathname: `/upload-cluster/${match.params.id}`,
                            state: {
                              seasonData: seasonData,
                            },
                          }}
                        >
                          <Button
                            className="btn-create w-100 px-4 py-2 shadow"
                            inline
                          >
                            Upload New Cluster
                          </Button>
                        </Link>
                      ) : null
                    ) : null}
                    <DropdownItem tag="div" className="text-center p-0 mt-2">
                      {roless.BusinessAd == role || roless.ITAd == role ? (
                        seasonData.isActive ? (
                          <Link
                            to={{
                              pathname: `/upload-werehouse/${match.params.id}`,
                              state: {
                                seasonData: seasonData,
                              },
                            }}
                          >
                            <Button
                              className="btn-create w-100 px-4 py-2 shadow"
                              inline
                            >
                              Upload New Warehouse
                            </Button>
                          </Link>
                        ) : null
                      ) : null}
                      <DropdownItem tag="div" className="text-center p-0 mt-2">
                        {/* {roless.BusinessAd == role || roless.ITAd == role ? (
                        seasonData.isActive ? (
                          <Link
                            to={{
                              pathname: `/upload-werehouse/${match.params.id}`,
                              state: {
                                seasonData: seasonData,
                              },
                            }}
                          >
                            <Button className="btn-create w-100 px-4 py-2 shadow" inline>
                              Upload Link Warehouse
                            </Button>
                          </Link>
                        ) : null
                      ) : null} */}
                      </DropdownItem>
                    </DropdownItem>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>

          <Nav tabs className="mt-8">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                Cluster
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                Warehouse
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="mt-6">
                <Table hover responsive>
                  <thead>
                    <h5>All Upload</h5>
                    <tr className="shadow">
                      <th>S/N</th>
                      <th>STATE</th>
                      <th>Upload Status</th>
                      <th>Uploaded By</th>
                      <th>Date</th>
                      <th>Batch Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {seasonUploads && seasonUploads.length < 1 ? (
                      <div className="text-center">
                        <h5>No record found</h5>
                      </div>
                    ) : (
                      seasonUploads.map((cl: any, i: number) => (
                        <tr key={cl.uniqueId}>
                          <th scope="row">{i + 1}</th>
                          <td>{cl.state}</td>
                          <td>
                            {cl.status === true ? (
                              <label style={{ color: "green" }}>
                                Successful
                              </label>
                            ) : null}
                            {cl.status === false ? (
                              <label style={{ color: "red" }}>
                                Not 100% Successful
                              </label>
                            ) : null}
                          </td>
                          <td>{cl.uploadedBy}</td>
                          <td>{moment(cl.dateCreated).format("llll")}</td>
                          <td>
                            {cl.isActive === true ? (
                              <label style={{ color: "green" }}>Active</label>
                            ) : null}
                            {cl.isActive === false ? (
                              <label style={{ color: "black" }}>Inactive</label>
                            ) : null}
                          </td>
                          <td>
                            {roless.BusinessAd == role ||
                            roless.ITAd == role ? (
                              <div className="justify-content-center">
                                <UncontrolledDropdown className="m-drop">
                                  <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                    <HiDotsVertical />
                                  </DropdownToggle>
                                  <DropdownMenu container="body">
                                    {seasonData.isActive ? (
                                      <DropdownItem
                                        onClick={() =>
                                          onDisabled(cl.uniqueId, cl.isActive)
                                        }
                                      >
                                        {cl.isActive
                                          ? "Deactivate"
                                          : "Activate"}
                                      </DropdownItem>
                                    ) : null}
                                    <DropdownItem
                                      onClick={async () => {
                                        const response =
                                          await seasonService.downloadFailedUpload(
                                            cl.uniqueId
                                          );
                                        saveAs(response);
                                      }}
                                    >
                                      Download Failed Record
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={async () => {
                                        const response =
                                          await seasonService.downloadErrorUpload(
                                            cl.uniqueId
                                          );
                                        saveAs(response);
                                      }}
                                    >
                                      Download Error Log
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="mt-4">
                <Table hover responsive>
                  <thead>
                    <h5>All Upload</h5>
                    <tr className="shadow">
                      <th>S/N</th>
                      <th>STATE</th>
                      <th>Upload Status</th>
                      <th>Uploaded By</th>
                      <th>Date</th>
                      <th>Batch Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {werehouseUploads && werehouseUploads.length < 1 ? (
                      <div className="text-center">
                        <h5>No record found</h5>
                      </div>
                    ) : (
                      werehouseUploads.map((cl: any, i: number) => (
                        <tr key={cl.uniqueId}>
                          <th scope="row">{i + 1}</th>
                          <td>{cl.state}</td>
                          <td>
                            {cl.status === true ? (
                              <label style={{ color: "green" }}>
                                Successful
                              </label>
                            ) : null}
                            {cl.status === false ? (
                              <label style={{ color: "red" }}>
                                Not 100% Successful
                              </label>
                            ) : null}
                          </td>
                          <td>{cl.uploadedBy}</td>
                          <td>{moment(cl.dateCreated).format("llll")}</td>
                          <td>
                            {cl.isActive === true ? (
                              <label style={{ color: "green" }}>Active</label>
                            ) : null}
                            {cl.isActive === false ? (
                              <label style={{ color: "black" }}>Inactive</label>
                            ) : null}
                          </td>
                          <td>
                            {roless.BusinessAd == role ||
                            roless.ITAd == role ? (
                              <div className="justify-content-center">
                                <UncontrolledDropdown className="m-drop">
                                  <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                    <HiDotsVertical />
                                  </DropdownToggle>
                                  <DropdownMenu container="body">
                                    {seasonData.isActive ? (
                                      <DropdownItem
                                        onClick={() =>
                                          onDisabledChecked(
                                            cl.uniqueId,
                                            cl.isActive
                                          )
                                        }
                                      >
                                        {cl.isActive
                                          ? "Deactivate"
                                          : "Activate"}
                                      </DropdownItem>
                                    ) : null}
                                    <DropdownItem
                                      onClick={async () => {
                                        const response =
                                          await seasonService.downloadFailedUpload2(
                                            cl.uniqueId
                                          );
                                        saveAs(response);
                                      }}
                                    >
                                      Download Failed Record
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={async () => {
                                        const response =
                                          await seasonService.downloadFailedUpload2(
                                            cl.uniqueId
                                          );
                                        saveAs(response);
                                      }}
                                    >
                                      Download Error Log
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default All_upload;
