import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import FormTextInput from "../../../components/form/text-input";
import logo from "../../../assets/img/Needle_logo.png";
import { Link, RouteComponentProps } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { Formik, Form, Field } from "formik";
import { userService } from "../../../Services";
import { useAlert } from "react-alert";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa";
import * as Yup from "yup";
import { PageBody } from "../../../components/Loader";
import { useLoadingMessage } from "../../../components/useLoadingMessage";

const Verify_Email = ({ match }: RouteComponentProps<{ id: string }>) => {
  const alert = useAlert();
  const { isLoading, message, updateLoader, sideBar } = useLoadingMessage();

  const [verify, setVerify] = React.useState(false);

  const very = async () => {
    updateLoader(true, "Verifying Email");
    try {
      const response: any = await userService.VerifyEmail(match.params.id);
      if (response.status == 200) {
        setVerify(true);
      } else {
        setVerify(false);
      }
      updateLoader(false, "");
    } catch (er) {
      updateLoader(false, "");
    }
  };
  React.useEffect(() => {
    very();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Container fluid>
        <Row className="vh-100">
          <Col md="4">
            <Card className=" login-card border-0 shadow p-3 text-center">
              <CardBody>
                <div className="text-center">
                  <img src={logo} width={220} height={50} />
                  <CardText className="mt-3"></CardText>
                </div>
                {verify ? (
                  <h4 className="mt-5 text-success">
                    Email Verified Successfully
                  </h4>
                ) : (
                  <h4 className="mt-5 text-danger">
                    Email verification failed
                  </h4>
                )}

                <div className="mt-5">
                  {" "}
                  <Link to="/" className="text-dark ">
                    <span>
                      {" "}
                      <AiFillHome /> Continue
                    </span>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8" className=" login-side"></Col>
        </Row>
      </Container>
    </PageBody>
  );
};

export default Verify_Email;
