import React from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";
import { useAlert } from "react-alert";
import {
  Link,
  useHistory,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import ErrorMessage from "../../../../../components/form/error-message";
import Layout from "../../../../../components/Layout";
import { PageBody } from "../../../../../components/Loader";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/rootReducer";
import { configThunk } from "../../../../../redux/action/config/config.thunk";
import { controlType, validations } from "../../../../../Constants";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { seasonService } from "../../../../../Services";
import moment from "moment";

const Edit_season_form = ({
  match,
}: RouteComponentProps<{ seasonId: string; farmStageId: string }>) => {
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const { seasonData } = location.state;

  const { isLoading, message, updateLoader } = useLoadingMessage();

  const dispatch = useDispatch();

  const { farmingStage } = useSelector(
    (state: RootState) => state.farmingStage
  );
  const [forms, setAllforms] = React.useState<any>({});
  const [field, setField] = React.useState<[]>([]);
  const [conf, setConf] = React.useState<[]>([]);

  const getSeasonDropDown = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(configThunk.getAllFarmingStage(farmingStage));
  }, [farmingStage]);

  const getform = async () => {
    const response: any = await seasonService.getSeasonForm(
      match.params.seasonId,
      match.params.farmStageId
    );
    const responseF = await seasonService.getAllField(1);

    response.data.response.config.map((stg: any, i: any) => {
      for (let i = 0; i < stg.validations.length; i++) {
        stg.validations[i].label = stg.validations[i].validation;
        stg.validations[i].value = stg.validations[i].validation;
      }
      const newField: any = [];
      if (stg.controlType == "ColumnNameDropDown") {
        const sval = stg.values.split(",");
        stg.values &&
          sval.map((fr: any) => {
            newField.push({
              label: fr,
              value: fr,
            });
          });

        stg.values = newField;
      }
    });

    let fd: any = [];
    responseF.data.data.map((dt: any) => {
      fd.push({
        label: dt,
        value: dt,
      });
    });
    setField(fd);
    setAllforms(response.data.response);
    console.log(response.data.response.config);
    await setConf(response.data.response.config);
    updateLoader(false, "");
  };

  React.useEffect(() => {
    getSeasonDropDown();
    getform();
  }, []);

  return (
    // <React.Fragment>
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <Formik
            initialValues={{
              farmStageName: "",
              seasonId: forms.seasonId && forms.seasonId,
              farmStageId: forms.farmStageId && forms.farmStageId,
              startDate: forms.startDate && forms.startDate,
              endDate: forms.endDate && forms.endDate,
              config: forms.config && forms.config,
            }}
            validationSchema={Yup.object().shape({
              // eopCategory: Yup.string().required("Eop category is required"),
              config: Yup.array().of(
                Yup.object().shape({
                  fieldName: Yup.string().required("FieldName  is required"),
                  validations: Yup.array()
                    .min(1, "Validations is Required")
                    .required("Validations is Required"),
                  controlType: Yup.string().required(
                    "Control type is required"
                  ),
                  // quantity: Yup.string().required("Quantity is required"),
                  // unitPrice: Yup.string().required("Unit price  is required"),
                  // quantityType: Yup.string().required(
                  //   "Quantity type is required"
                  // ),
                })
              ),
            })}
            onSubmit={async (values, actions) => {
              console.log({ values });
              actions.setSubmitting(true);
              console.log(actions);
              values.seasonId = match.params.seasonId;
              let newArr: any = [];
              values.config.map((re: any) => {
                newArr.push(re.fieldName);
                for (let i = 0; i < re.validations.length; i++) {
                  re.validations[i].validation = re.validations[i].value;
                  re.validations[i].validationValue = "";
                }

                const newField: any = [];
                if (re.controlType == "ColumnNameDropDown") {
                  re.values.length > 0 &&
                    re.values.map((fr: { label: any; value: any }) => {
                      newField.push(fr.value);
                    });

                  re.values = String(newField);
                }
              });

              const toFindDuplicates = (newArr: any) =>
                newArr.filter(
                  (item: any, index: any) => newArr.indexOf(item) !== index
                );
              const duplicateElementa = toFindDuplicates(newArr);

              if (duplicateElementa.length > 0) {
                alert.error("Duplicate Entry on Question Name");
              } else {
                try {
                  const response = await seasonService.createSeasonForm(values);
                  alert.success(response.data.message);
                  history.push({
                    pathname: `/season-form/${match.params.seasonId}`,
                    state: {
                      seasonData: seasonData,
                    },
                  });
                } catch (error: any) {
                  console.log(error.response.data);
                  alert.error("error");
                }
              }
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              handleChange,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <Row>
                  <Col md="4">
                    <FormGroup className="">
                      <Label className="font-weight-bold">Farm Stage</Label>
                      <Field
                        name="farmStageId"
                        as="select"
                        className="form-control form-control-lg"
                        disabled
                      >
                        <option> Select</option>
                        {/* <option value="Input">Input</option>
                      <option value="Others">Others</option> */}
                        {farmingStage.map((ass: any, i: any) => (
                          <option value={ass.id}>{ass.name}</option>
                        ))}
                      </Field>
                      {touched.farmStageId && errors.farmStageId ? (
                        <div className="text-danger">{errors.farmStageId}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <FieldArray
                  name="config"
                  render={(arrayHelpers) => (
                    <div>
                      {values.config.map((friend: any, index: number) => (
                        <React.Fragment>
                          <Row className="my-2 bg-green-3 p-3">
                            <Col>
                              <FormGroup>
                                <Label className="font-weight-bold">
                                  Enter Question
                                </Label>
                                <Field
                                  type="text"
                                  placeholder="field name"
                                  name={`config[${index}].fieldName`}
                                  className="form-control form-control-lg"
                                />
                                <div className="text-danger">
                                  <ErrorMessage name="config[0].fieldName" />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className="">
                                <Label className="font-weight-bold">
                                  Type of Answer Expected
                                </Label>
                                {/* <Field
                                type="text"
                                placeholder="control type"
                                name={`config[${index}].controlType`}
                                className="form-control form-control-lg"
                                valid
                              /> */}
                                <Field
                                  name={`config[${index}].controlType`}
                                  as="select"
                                  className="form-control form-control-lg"
                                >
                                  <option> Select</option>
                                  {/* <option value="Input">Input</option>
                                  <option value="Others">Others</option> */}
                                  {controlType.map((ass: any, i: any) => (
                                    <option value={ass}>{ass}</option>
                                  ))}
                                </Field>
                                <div className="text-danger">
                                  <ErrorMessage name="config[0].controlType" />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup className="">
                                <Label className="font-weight-bold">
                                  Select Validation Type
                                </Label>
                                {/* <Field
                                type="text"
                                placeholder="dropdown value"
                                name={`config[${index}].validations`}
                                className="form-control form-control-lg"
                                valid
                              /> */}

                                <Select
                                  onChange={(th: any) => {
                                    setFieldValue(
                                      `config[${index}].validations`,
                                      th
                                    );
                                  }}
                                  options={validations}
                                  defaultValue={friend.validations}
                                  isMulti={true}
                                  name={`config[${index}].validations`}
                                />
                                <div className="text-danger">
                                  <ErrorMessage name="config[0].validations" />
                                </div>
                              </FormGroup>
                            </Col>

                            {values.config[index].controlType == "DropDown" ? (
                              <Col>
                                <FormGroup className="">
                                  <Label className="font-weight-bold">
                                    Dropdown Values
                                  </Label>
                                  <Field
                                    type="text"
                                    placeholder="Male,Female"
                                    name={`config[${index}].values`}
                                    className="form-control form-control-lg"
                                    valid
                                  />
                                  <div className="text-danger">
                                    <ErrorMessage name="config[0].values" />
                                  </div>
                                </FormGroup>
                              </Col>
                            ) : null}
                            {values.config[index].controlType ==
                            "ColumnNameDropDown" ? (
                              <Col>
                                <FormGroup className="">
                                  <Label className="font-weight-bold">
                                    Dropdown Values
                                  </Label>
                                  <Select
                                    onChange={(th: any) => {
                                      setFieldValue(
                                        `config[${index}].values`,
                                        th
                                      );
                                    }}
                                    options={field}
                                    defaultValue={friend.values}
                                    isMulti={true}
                                    name={`config[${index}].values`}
                                  />
                                  <div className="text-danger">
                                    <ErrorMessage name="config[0].values" />
                                  </div>
                                </FormGroup>
                              </Col>
                            ) : null}
                            <Button
                              close
                              className="text-danger"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </Row>
                        </React.Fragment>
                      ))}
                      <Button
                        color="success"
                        type="button"
                        className="btn-create px-4 py-2 shadow font-weight-bold bg-or"
                        onClick={() =>
                          arrayHelpers.push({
                            fieldName: "",
                            controlType: "",
                            validations: [],
                            values: "",
                          })
                        }
                      >
                        Add
                      </Button>
                    </div>
                  )}
                />

                <div className="p-3 text-center">
                  <Link
                    to={{
                      pathname: `/season-form/${match.params.seasonId}`,
                      state: {
                        seasonData: seasonData,
                      },
                    }}
                  >
                    <Button
                      color="success"
                      className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    color="success"
                    type="submit"
                    className="btn-create px-4 py-2 shadow font-weight-bold"
                    disabled={!dirty || isSubmitting}
                  >
                    Update
                    {isSubmitting ? <FaSpinner className="fa-spin" /> : null}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Edit_season_form;
