import React from "react";
import { Button, Col, Container, FormGroup, Row, Label } from "reactstrap";
import FormTextInput from "../../../../components/form/text-input";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/rootReducer";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { PageBody } from "../../../../components/Loader";
import { ResponsecropTypeType } from "../../../../models/config.model";
import { configService } from "../../../../Services";
import { configThunk } from "../../../../redux/action/config/config.thunk";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import Layout from "../../../../components/Layout";

interface FormValues {
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
}
const initialValues: FormValues = {
  name: "",
  address: "",
  email: "",
  phoneNumber: "",
};

const Edit_Supplier = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const { cropType } = useSelector((state: RootState) => state.cropType);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const phoneValidate =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [supplier, setSupplier] = React.useState<FormValues>(initialValues);

  const getcrop = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(configThunk.getAllCropType(cropType));
  }, [cropType]);

  const getSupplier = async () => {
    const response = await configService.getOneSupplier(match.params.id);
    console.log(response);
    setSupplier(response.data.data);
    updateLoader(false, "");
  };

  const initialValuess: FormValues = {
    name: supplier.name,
    address: supplier.address,
    email: supplier.email,
    phoneNumber: supplier.phoneNumber,
  };
  React.useEffect(() => {
    getcrop();
    getSupplier();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Config">
        <Container>
          <Row>
            <Col>
              <Formik
                initialValues={initialValuess}
                validationSchema={Yup.object({
                  name: Yup.string().required("Name is required"),
                  address: Yup.string().required(" Address is required"),
                  email: Yup.string().required(
                    " Email name required"
                  ),
                //   phoneNumber: Yup.string()
                //     .required("phone number is a required field")
                //     .max(11, "phone number must be 14 digits maximum")
                //     .min(10, "phone number must be 11 digits minimum")
                //     .matches(phoneValidate, "Phone number is not valid"),
                })}
                onSubmit={async (values, actions) => {
                  console.log({ values, actions });

                  actions.setSubmitting(true);
                  try {
                    const response = await configService.editSupplier(
                      match.params.id,
                      values
                    );
                    alert.success("successfully updated");
                    history.push("/config");
                    actions.setSubmitting(false);
                  } catch (error: any) {
                    alert.error(error.response.data.message);
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  values,
                  dirty,
                }) => (
                  <Form>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Name <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            label="Name"
                            placeholder="enter name"
                            name="name"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.name && errors.name ? (
                            <div className="text-danger">{errors.name}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Email <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="email"
                            label="Emai;"
                            placeholder="enter email"
                            name="email"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.email && errors.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}
                        </FormGroup>{" "}
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Address{" "}
                            <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            label="Address"
                            placeholder="enter address"
                            name="address"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.address && errors.address ? (
                            <div className="text-danger">{errors.address}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Phone Number{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            type="text"
                            label="President's Phone"
                            placeholder="enter phone number"
                            name="phoneNumber"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.phoneNumber && errors.phoneNumber ? (
                            <div className="text-danger">
                              {errors.phoneNumber}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="p-3 text-center">
                      <Link to="/config">
                        <Button
                          color="success"
                          className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        color="success"
                        className="btn-create px-4 py-2 shadow font-weight-bold"
                        disabled={!dirty}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Edit_Supplier;
