import React from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Container,
  Label,
} from "reactstrap";
import { FaSpinner, FaUserAlt } from "react-icons/fa";

import { Link } from "react-router-dom";
import { Formik, Form, Field,FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { configThunk } from "../../../redux/action/config/config.thunk";
import { RootState } from "../../../redux/store/rootReducer";
import { useLoadingMessage } from "../../../components/useLoadingMessage";
import { PageBody } from "../../../components/Loader";
import { Responsestatetype } from "../../../models/config.model";
import { userService } from "../../../Services";
import Layout from "../../../components/Layout";
import Select from "react-select";
import ErrorMessage from "../../../components/form/error-message";

interface FormValues {
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  jobDescription: string;
  stateIds: [];
  lgaIds: [];
  role: string;
  email: string;
  gender: string;
  config: [
    {
      state: [],
      lga: [],
    },
  ],
}

const Create_user = () => {
  const { roles } = useSelector((state: RootState) => state.roles);
  const { states } = useSelector((state: RootState) => state.states);
  const phoneValidate =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const alert = useAlert();
  const history = useHistory();
  const initialValues: FormValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    phoneNumber: "",
    stateIds: [],
    lgaIds: [],
    role: "",
    email: "",
    jobDescription: "",
    gender: "",
    config: [
      {
        state: [],
        lga: [],
      },
    ],
  };
  const dispatch = useDispatch();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getroles = React.useCallback(async () => {
    updateLoader(true, "Fetching Data");
    await dispatch(configThunk.getAllRoles(roles));
    await dispatch(configThunk.getAllStates(states));
    updateLoader(false, "");
  }, [roles, states]);


  let statess: any = [];
  states.map((dt: any) => {
    statess.push({
      label: dt.state,
      value: dt.id,
    });
  });

const lg = (id:any)=>{
  let lga:any = []
  if(id !== undefined){
      states.map((st:any)=>{
        if(st.id == id.value){
          st.localGovts.map((ll:any)=>{
            lga.push({
              label: ll.localGovt,
              value: ll.id,
            });
          })
        }
      })
      return lga
    }
}
  React.useEffect(() => {
    getroles();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Users">
        <Container>
          <Row>
            <Container className="mb-4">
              <Row>
                {/* <Col md={9}>
                <Link to="/create-user">
                  <Button className="btn-create px-4 py-2 shadow " inline>
                    Upload Users
                  </Button>
                </Link>
                <Link to="/create-user">
                  <Button className="btn-download px-4 py-2 shadow ml-4" inline>
                    Download Template
                  </Button>
                </Link>

                <span className="ml-5">
                  <Input
                    placeholder="search"
                    type="search"
                    className="form-search"
                  />
                </span>
              </Col>
              <Col md={3} className="text-right">
                {" "}
              </Col> */}
              </Row>
            </Container>
            <Col sm={12} lg={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  firstName: Yup.string().required("First name is required"),
                  lastName: Yup.string().required("Last name is required"),
                  // middleName: Yup.string().required("Middle name is required"),
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Email is required"),
                  phoneNumber: Yup.string()
                    .required("phone number is a required field")
                    .max(11, "phone number must be 11 digits maximum")
                    .min(10, "phone number must be 10 digits minimum")
                    .matches(phoneValidate, "Phone number is not valid"),

                  role: Yup.string().required("role is a required field"),

                  jobDescription: Yup.string().required(
                    "Job Description is required"
                  ),
                  // stateId: Yup.number()
                  //   .min(1, "please select from the options")
                  //   .required("role is a required field"),

                  // lgaId: Yup.number()
                  //   .min(1, "please select from the options")
                  //   .required("L.G.A is required"),
                  gender: Yup.string().required("Gender is required"),
                })}
                validateOnChange
                onSubmit={async (values, actions) => {
                  console.log({ values, actions });

                  actions.setSubmitting(true);
                  const sts: any = [];
                  const lgs: any = [];
                 
                  if(values.role == "Field Officer"){

                  values.config.map(
                      (fr:any) => {
                          sts.push(fr.state.value);                      
                      }
                    );

                  values.config.map(
                    (fr:any) => {
                      fr.lga.map((st:any)=>{
                        lgs.push(st.value);
                      })
                      
                    }
                  );
                  }else{
                    values.config.map(
                      (fr:any) => {
                        fr.state.map((st:any)=>{
                          sts.push(st.value);
                          states.map((stt:any)=>{
                            if(stt.id == st.value){
                              stt.localGovts.map((lg:any)=>{
                                lgs.push(lg.id)
                              })
                            }
                          })    
                        })
                                          
                      }
                    );
                  }

                  values.stateIds = sts
                  values.lgaIds = lgs
                  try {
                    const response = await userService.createUser(values);
                    console.log(response);
                    if (response.data.data) {
                      alert.success("successfully created");
                      history.push("/users");
                    }
                  } catch (error: any) {
                    alert.error(error.response.data.message);
                  }
                }}
              >
                {({ errors, touched, isSubmitting, handleChange, values, setFieldValue }) => (
                  <Form>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            First Name{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            type="text"
                            label="First Name"
                            placeholder="enter first name"
                            className="form-control form-control-lg"
                            name="firstName"
                            autocomplete="off"
                            valid
                          />
                          {touched.firstName && errors.firstName ? (
                            <div className="text-danger">
                              {errors.firstName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Middle Name
                          </Label>
                          <Field
                            type="text"
                            label="Middle Name"
                            placeholder="enter middle name"
                            className="form-control form-control-lg"
                            name="middleName"
                          />
                          {touched.middleName && errors.middleName ? (
                            <div className="text-danger">
                              {errors.middleName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Last Name{" "}
                            <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          <Field
                            type="text"
                            label="LastName"
                            placeholder="enter Last Name"
                            className="form-control form-control-lg"
                            name="lastName"
                            valid
                          />
                          {touched.lastName && errors.lastName ? (
                            <div className="text-danger">{errors.lastName}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Email <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            type="email"
                            label="Email"
                            placeholder="enter email"
                            name="email"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.email && errors.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Phone Number{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            type="text"
                            label="Phone"
                            placeholder="enter phone number"
                            name="phoneNumber"
                            autocomplete="off"
                            className="form-control form-control-lg"
                            valid
                          />
                          {touched.phoneNumber && errors.phoneNumber ? (
                            <div className="text-danger">
                              {errors.phoneNumber}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Role <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            name="role"
                            as="select"
                            className="form-control form-control-lg"
                          >
                            <option value=""> Select</option>
                            {roles.map((role, i) => (
                              <option value={role}>{role}</option>
                            ))}
                          </Field>
                          {touched.role && errors.role ? (
                            <div className="text-danger">{errors.role}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <FieldArray
                      name="config"
                      render={(arrayHelpers) => (
                        <div>
                          {values.config.map((friend: any, index: number) => (
                            <React.Fragment>
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            State <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                          {/* <Field
                           name={`config[${index}].state`}
                            as="select"
                            className="form-control form-control-lg"
                          >
                            <option value=""> Select</option>
                            {states.map((state: Responsestatetype, i) => (
                              <option value={state.id}>{state.state}</option>
                            ))}
                        
                          </Field>
                          {touched.stateId && errors.stateId ? (
                            <div className="text-danger">{errors.stateId}</div>
                          ) : null} */}
                          <Select
                                      onChange={(th: any) => {
                                        setFieldValue(
                                          `config[${index}].state`,
                                          th
                                        );
                                      }}
                                      options={statess}
                                      isMulti={values.role == "Field Officer" ? false:true}
                                      name={`config[${index}].state`}
                                    />
                                    <div className="text-danger">
                                      <ErrorMessage
                                        name={`config[${index}].state`}
                                      />
                                    </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                         
                          {/* <Field
                            name={`config[${index}].lga`}
                            as="select"
                            className="form-control form-control-lg"
                          >
                            <option value=""> Select</option>
                            {states.map((state: Responsestatetype, i) =>
                              state.id == values.stateId
                                ? state.localGovts.map((lg, i) => (
                                    <option value={lg.id}>
                                      {lg.localGovt}
                                    </option>
                                  ))
                                : null
                            )}

                            : "IT Admin"
1: "Business Admin"
2: "National Coordinator"
3: "State Cordinator"
4: "Assistant State Coordinator"
5: "Field Officer"
6: "Crop Coordinator"
7: "Report Viewer"
                          </Field>
                          {touched.lgaId && errors.lgaId ? (
                            <div className="text-danger">{errors.lgaId}</div>
                          ) : null} */}
                          {values.role == "Field Officer" ? 
                              <>
                        <Label className="font-weight-bold">
                            L.G.A <span className="text-danger fw-bold">*</span>{" "}
                          </Label>
                              <Select
                                      onChange={(th: any) => {
                                        setFieldValue(
                                          `config[${index}].lga`,
                                          th
                                        );
                                      }}
                                      options={lg(values.config[index].state)}
                                      isMulti={values.role == "Field Officer" ? true:false}
                                      name={`config[${index}].lga`}
                                    />
                                    <div className="text-danger">
                                      <ErrorMessage
                                        name={`config[${index}].lga`}
                                      />
                                    </div>
                                    </>:null}
                        </FormGroup>
                      </Col>
                    {/* </Row> */}
                    {values.config.length > 1 ?
                    <Button
                        close
                        className="text-danger"
                        onClick={() => arrayHelpers.remove(index)}
                        /> : null}
                              </Row>
                            </React.Fragment>
                          ))}

                          {values.role == "Field Officer" ? 
                          <Button
                            color="success"
                            type="button"
                            className="btn-create px-4 py-2 shadow font-weight-bold bg-or"
                            onClick={() =>
                              arrayHelpers.push({
                                state: [],
                                lga: [],
                                
                              })
                            }
                          >
                            Add
                          </Button>
                          :null}
                        </div>
                      )}
                    />
                    <Row className="my-2">
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Gender{" "}
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            name="gender"
                            as="select"
                            className="form-control form-control-lg"
                          >
                            <option value=""> Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </Field>
                          {touched.gender && errors.gender ? (
                            <div className="text-danger">{errors.gender}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="">
                          <Label className="font-weight-bold">
                            Job Description
                            <span className="text-danger fw-bold">*</span>
                          </Label>
                          <Field
                            name="jobDescription"
                            type="text"
                            className="form-control form-control-lg"
                          ></Field>
                          {touched.jobDescription && errors.jobDescription ? (
                            <div className="text-danger">
                              {errors.jobDescription}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row className="my-2">
                <Col>
                  <FormGroup className="">
                    <Label className="font-weight-bold">Address</Label>
                    <Field as="textarea" label="Address" name="address" />
                     {touched.address && errors.address ? (
                          <div className="text-danger">{errors.address}</div>
                        ) : null}
                  </FormGroup>
                </Col>
              </Row> */}

                    <div className="p-3 text-center">
                      <Link to="/users">
                        <Button
                          color="success"
                          className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        color="success"
                        className="btn-create px-4 py-2 shadow font-weight-bold"
                        disabled={isSubmitting}
                      >
                        Create
                        {isSubmitting ? (
                          <FaSpinner className="fa-spin" />
                        ) : null}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default Create_user;
