import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import FormTextInput from "../../../components/form/text-input";
import logo from "../../../assets/img/Needle_logo.png";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { Formik, Form, Field } from "formik";
import { userService } from "../../../Services";
import { useAlert } from "react-alert";
import {  FaSpinner } from "react-icons/fa";
import * as Yup from "yup";


interface FormValues {
  email: string;
}
const Forget_password = () => {
  const alert = useAlert();
  const initialValues: FormValues = { email: ""};

  return (
    <Container fluid>
      <Row className="vh-100">
        <Col md="4">
          <Card className=" login-card border-0 shadow p-3">
            <CardBody>
              <div className="text-center">
                <img src={logo} width={220} height={50} />
                <CardText className="mt-3">
                  Please enter your email to reset password
                </CardText>
              </div> 
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  email: Yup.string().required("Required"),
                })}
                onSubmit={async (values, actions) => {
                  console.log({ values, actions });
                  actions.setSubmitting(true);
                  
                  try {
                    const response:any = await userService.sendResetEmail(
                      values,
                    );
                    alert.success(response.data.data);
                  } catch (error) {
                    alert.error("Error Sending Request");
                  }
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                <FormGroup className="my-5">
                  <Field
                    className="form-control form-control-lg"
                    type="email"
                    label="Email Address"
                    name="email"
                    placeholder="enter email address"
                  />
                  {touched.email && errors.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                </FormGroup>
                <Row className="my-5">
                  <Col>
                    <Link to="/" className="text-dark">
                      <span>
                        {" "}
                        <AiFillHome /> Home
                      </span>
                    </Link>
                  </Col>
                </Row>

                <FormGroup className="mt-5">
                  <Button block color="success" type="submit" className="font-bold p-4" disabled={isSubmitting}>
                    Forget Password
                    {isSubmitting ? (
                          <FaSpinner className="fa-spin" />
                        ) : null}
                  </Button>
                </FormGroup>
              </Form>
              )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
        <Col md="8" className=" login-side"></Col>
      </Row>
    </Container>
  );
};

export default Forget_password;
