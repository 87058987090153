import React from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import FormTextInput from "../../../../components/form/text-input";
import * as Yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";
import { useAlert } from "react-alert";
import {
  Link,
  useHistory,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import { seasonService } from "../../../../Services";
import { FaSpinner } from "react-icons/fa";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { PageBody } from "../../../../components/Loader";
import ErrorMessage from "../../../../components/form/error-message";
import Layout from "../../../../components/Layout";

interface eoDeta {
  name: string;
  quantity: number;
  unitPrice: number;
  quantityType: number;
}

const initialValues: eoDeta = {
  name: "",
  quantity: 0,
  unitPrice: 0,
  quantityType: 0,
};

const Edit_eop = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const location = useLocation();
  const { eopdata, indexeop, status, seasonData } = location.state;
  // const [oneEop, setoneEop] = React.useState<[]>([]);
  const alert = useAlert();
  const history = useHistory();

  // const geteop = async () => {
  //   updateLoader(true, "Fetching Data");
  //   const response = await seasonService.getOneEop(match.params.seasonId,match.params.id);
  //   console.log(response.data.data)
  //   setoneEop(response.data.data)
  //   updateLoader(false, "");
  // };
  //   const initialValuess: FormValues = {
  //     eopCategory:oneEop[0].eopCategory,
  //     eopDetails:[{
  //     name:oneEop[0].eopResponseDetails.name,
  //     quantity: oneEop[0].eopResponseDetails.quantity,
  //     unitPrice:oneEop[0].eopResponseDetails.unitPrice,
  //     quantityType: oneEop[0].eopResponseDetails.quantityType,
  //     }]
  //   };

  return (
    <Layout active="Seasons">
      <Container>
        <Formik
          initialValues={{
            eopNumber: eopdata[indexeop].eopNumber,
            eopCategory: eopdata[indexeop].eopCategory,
            eopDetails: eopdata[indexeop] && [
              ...eopdata[indexeop].eopResponseDetails,
            ],
          }}
          validationSchema={Yup.object().shape({
            eopCategory: Yup.string().required("Eop category is required"),
            eopDetails: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required("name is  required"),
                quantity: Yup.number().required("Quantity is required")
                .min(0, "Quantity must be a whole number"),
                unitPrice: Yup.number().required("Unit price  is required").min(0, "Unit price must be a whole number"),
                quantityType: Yup.string().required(
                  "Quantity type is required"
                ),
              })
            ),
          })}
          onSubmit={async (values, actions) => {
            console.log({ values });
            actions.setSubmitting(true);
            values.eopNumber = eopdata[indexeop].eopNumber;
            try {
              const response = await seasonService.updateEop(
                match.params.id,
                values
              );
              // if (response.status) {
              alert.success("successfully updated");
              history.push({
                pathname: `/eop/${match.params.id}`,
                state: {
                  seasonData: seasonData,
                  status: status,
                },
              });
              // }
            } catch (error) {
              alert.error("error");
            }
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            handleChange,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <Col md="4">
                  <FormGroup className="">
                    <Label className="font-weight-bold">Category</Label>
                    <Field
                      name="eopCategory"
                      as="select"
                      className="form-control form-control-lg"
                    >
                      <option> Select</option>
                      <option value="Input">Input</option>
                      <option value="Others">Others</option>
                    </Field>
                    {touched.eopCategory && errors.eopCategory ? (
                      <div className="text-danger">{errors.eopCategory}</div>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <FieldArray
                name="eopDetails"
                render={(arrayHelpers) => (
                  <div>
                    {values.eopDetails.map((friend: any, index: number) => (
                      <React.Fragment>
                        <Row className="my-2 bg-green-3 p-3">
                          <Col>
                            <FormGroup>
                              <Label className="font-weight-bold">Name</Label>
                              <Field
                                type="text"
                                placeholder="name"
                                name={`eopDetails[${index}].name`}
                                className="form-control form-control-lg"
                              />
                              <div className="text-danger">
                                <ErrorMessage name="eopDetails[0].name" />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="">
                              <Label className="font-weight-bold">
                                Quantity
                              </Label>
                              <Field
                                type="number"
                                placeholder="Quantity"
                                name={`eopDetails[${index}].quantity`}
                                className="form-control form-control-lg"
                                valid
                              />
                              <div className="text-danger">
                                <ErrorMessage name="eopDetails[0].quantity" />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="">
                              <Label className="font-weight-bold">
                                Unit Price
                              </Label>
                              <Field
                                type="number"
                                placeholder="Unit Price"
                                name={`eopDetails[${index}].unitPrice`}
                                className="form-control form-control-lg"
                                valid
                              />
                              <div className="text-danger">
                                <ErrorMessage name="eopDetails[0].unitPrice" />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="">
                              <Label className="font-weight-bold">
                                Unit Type
                              </Label>

                              <Field
                                as="select"
                                name={`eopDetails[${index}].quantityType`}
                                className="form-control form-control-lg"
                                valid
                              >
                                <option> Select</option>
                                <option value="Bags">Bags</option>
                                <option value="Litres">Litres</option>
                                <option value="Kg">Kg</option>
                                <option value="Nil">Nil</option>
                              </Field>
                              <div className="text-danger">
                                <ErrorMessage name="eopDetails[0].quantityType" />
                              </div>
                            </FormGroup>
                          </Col>
                          <Button
                            close
                            className="text-danger"
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </Row>
                      </React.Fragment>
                    ))}
                    <Button
                      color="success"
                      type="button"
                      className="btn-create px-4 py-2 shadow font-weight-bold bg-or"
                      onClick={() =>
                        arrayHelpers.push({
                          name: "",
                          quantity: 0,
                          quantityType: "",
                          unitPrice: 0,
                        })
                      }
                    >
                      Add
                    </Button>
                  </div>
                )}
              />

              <div className="p-3 text-center">
                <Link
                  to={{
                    pathname: `/eop/${match.params.id}`,
                    state: {
                      seasonData: seasonData,
                      status: status,
                    },
                  }}
                >
                  <Button
                    color="success"
                    className="btn-cancel px-4 py-2 shadow font-weight-bold m-5"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  color="success"
                  type="submit"
                  className="btn-create px-4 py-2 shadow font-weight-bold"
                  disabled={isSubmitting}
                >
                  Update
                  {isSubmitting ? <FaSpinner className="fa-spin" /> : null}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default Edit_eop;
