import { seasonTypes,roles,farmingStage,cropType,incidence,association,states, suppliers } from "../../actionTypes";
import {seasontype,rolestype,farmingStageType,incidenceType,associationType} from "../../../models/config.model"


const initialState = {
 allSeasonType:[],
 roles:[],
 states:[],
 farmingStage:[],
 cropType:[],
 incidence:[],
 association:[],
 suppliers:[]
};


type seasonAction={
type:string,
payload:[]
}

type roleAction={
  type:string,
  payload:[]
  }
type farmingStageAction={
    type:string,
    payload:farmingStageType[]
    }
type incidenceAction={
      type:string,
      payload:incidenceType[]
      }

export const seasonTypeReducers = (state = initialState, action:seasonAction) => {
  switch (action.type) {
    case seasonTypes.GET_ALL_SEASONS: {
      return {
        ...state,
        allSeasonType: action.payload
      };
    }

    default: {
      return state;
    }
  }
};


export const rolesReducers = (state = initialState, action:roleAction) => {
  switch (action.type) {

    case roles.GET_ALL_ROLES: {
      return { ...state, roles: action.payload };
    }

    default: {
      return state;
    }
  }
};

export const statesReducers = (state = initialState, action:roleAction) => {
  switch (action.type) {

    case states.GET_ALL_STATES: {
      return { ...state, states: action.payload };
    }

    default: {
      return state;
    }
  }
};

export const farmStageReducers = (state = initialState, action:roleAction) => {
  switch (action.type) {

    case farmingStage.GET_ALL_FARM_STAGE: {
      return { ...state, farmingStage: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const cropTypeReducers = (state = initialState, action:roleAction) => {
  switch (action.type) {

    case cropType.GET_ALL_CROP_TYPE: {
      return { ...state, cropType: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const incidenceReducers = (state = initialState, action:roleAction) => {
  switch (action.type) {

    case incidence.GET_ALL_INCIDENCE: {
      return { ...state, incidence: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const associationReducers = (state = initialState, action:roleAction) => {
  switch (action.type) {

    case association.GET_ALL_ASSOCIATION: {
      return { ...state, association: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const supplierReducers = (state = initialState, action:roleAction) => {
  switch (action.type) {

    case suppliers.GET_ALL_SUPPLIER: {
      return { ...state, suppliers: action.payload };
    }

    default: {
      return state;
    }
  }
}


